import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { signinAdmin } from "../../store/slices/userSlice";
import { ArrowRight } from 'lucide-react';
import { Eye } from 'lucide-react';
import { EyeOff } from 'lucide-react';


const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
     await dispatch(
        signinAdmin({ email, password, role: "admin"})
      ).unwrap();
        navigate('/');
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSecureEntry, setisSecureEntry] = useState(true)

  return (
    <>
    <section class="vh-100 authentication-bg">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div class="card shadow-sm rounded border-0">
          <div class="card-body p-5 text-center">

          <img onClick={()=>navigate("/")} className="mx-auto d-block auth-logo mb-5" width="270" src={"../assets/images/logo.svg"} />
          
            <form onSubmit={handleSubmit}>
            <div class="form-floating wf-input mt-3 mb-3">
              <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" 
              onChange={(e) => setEmail(e.target.value)} autocomplete="email" required />
              <label for="floatingInput">Email address</label>
            </div>
            <div class="form-floating wf-input mb-5 ">
              <input class="form-control" id="floatingPassword" placeholder="Password" onChange={(e) => setPassword(e.target.value)}
                  type={isSecureEntry ? "password" : "text"} autocomplete="password" required />
              <i onClick={() => { setisSecureEntry((prev) => !prev) }} className={`fa-solid ${isSecureEntry ? "fa-eye" : "fa-eye-slash"} eye-password`}></i>
              <label for="floatingPassword">Password</label>
            </div>
            <div class="d-grid gap-2">
              <button class="btn btn-lg wf-purple-btn wf-btn" type="submit">Log in</button>
            </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    </>
  );
};

export default Login;
