import React, { useEffect, useState, useContext } from 'react'
import { context } from '../../context/context';
import {  useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../store/slices/userSlice"
import { AlignJustify, ChevronDown, ChevronLeft, ChevronUp } from 'lucide-react';
import { ChevronRight } from 'lucide-react';
import { ArrowRight } from 'lucide-react';
import { Plus } from 'lucide-react';
import Modal from "react-modal";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "30%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
Modal.setAppElement("#root");



const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Add or remove the "sidebar-open" class to the body tag based on the state.
  if (isSidebarOpen) {
    document.body.classList.add('active');
  } else {
    document.body.classList.remove('active');
  }

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location=useLocation();
    const profile=useSelector(getProfile)  
    const { pathname } = location;
  

    const [modalIsOpen, setIsOpen] = useState(false);
    const [isdata, setisdata] = useState(true);

    const [modalIsOpen1, setIsOpen1] = useState(profile?.firstLogin==true && profile?.event_type=="multiple_wedding"||profile?.event_type=="multiple_event"?true:false);
    // const [state, setstate] = useState(false);


    // const GetEventNames = async () => {
    //   try {
    //     setisdata(true)
    //     const response = await dispatch(getUserEventList()).unwrap();
        
    //     if(response?.data.length<1){
    //       setisdata(false)
    //       setIsOpen(true)
    //     }
    //   } catch (rejectedValueOrSerializedError) {
    //     console.log(rejectedValueOrSerializedError);
    //   }
    // };



    function viewModal() {
      
      setIsOpen(true);
      
    }
  
    function closeModal() {
      setIsOpen(false);
  
    }

  
    function closeModal1() {
      setIsOpen1(false);
      
    }

    function stateChange() {
      setIsOpen(true);
      closeModal1()
    }


  //   useEffect(() => {
      
        
  //   GetEventNames();
       
  // }, []);


    const { toggleButton,SetToggleButton } = useContext(context);

    const [togglemenu, settogglemenu] = useState(false);
    const OpenDropdown = () => {
        return  pathname === "/categories" ||
        pathname === "/attributes" ||
        pathname === "/areas" ||
        pathname === "/cities" ||
        pathname === "/countries" ||
        pathname === "/ethnics" ||
        pathname === "/themes" ||
        pathname === "/questions" ||
        pathname === "/religions";
      };
      useEffect(() => {
        settogglemenu(OpenDropdown());
      }, [pathname]);


    const [togglemenu1, settogglemenu1] = useState(false);
    const OpenDropdown1 = () => {
        return  pathname === "/blogs" ||
        pathname === "/add-blog";
      };
      useEffect(() => {
        settogglemenu1(OpenDropdown1());
      }, [pathname]);

    const [togglemenu2, settogglemenu2] = useState(false);
    const OpenDropdown2 = () => {
        return  pathname === "/tasks" ||
        pathname === "/add-task";
      };
      useEffect(() => {
        settogglemenu2(OpenDropdown2());
      }, [pathname]);
    const [togglemenu3, settogglemenu3] = useState(false);
    const OpenDropdown3 = () => {
        return  pathname === "/admins" ||
        pathname === "/add-admin";
      };
      useEffect(() => {
        settogglemenu3(OpenDropdown3());
      }, [pathname]);


  return (
    <>
    <div className={toggleButton ? "sidebar close" : "sidebar"}>
            {/* <div onClick={() => SetToggleButton((prev) => !prev)}  className="wf-sidebar-toggle-btn">
            {toggleButton ? <ChevronRight /> : <ChevronLeft />}
            </div> */}
                <div class="logo-details">
                    <AlignJustify onClick={() => SetToggleButton((prev) => !prev)} stroke='var(--color4)' strokeWidth={1} />
                </div>
                <ul class="nav-links">
                <li className={`${location?.pathname === "/" ? "active" : ""}`}>
                    <div class="icon-link">
                        <Link to="/">
                            <img src='../assets/images/dashboard-icon.svg' />
                            <span class="link_name">Dashboard</span>
                        </Link>
                    </div>
                </li>

                {profile?.systemconfig_access?.length > 0 && (
                    <li className={`${location?.pathname === "/categories" || location?.pathname === "/attributes" || location?.pathname === "/areas" || location?.pathname === "/cities" 
                        || location?.pathname === "/countries" || location?.pathname === "/ethnics" || location?.pathname === "/themes" || location?.pathname === "/questions"
                        || location?.pathname === "/religions" ? "active" : ""} ${togglemenu ? "showMenu" : ""}`}>
                        <div class="icon-link" onClick={() => settogglemenu(!togglemenu)}>
                            <Link>
                                <img src='../assets/images/system-config-icon.svg' />
                                <span class="link_name">System Config</span>
                            </Link>
                            <span class={`cursor-pointer`}>
                                {togglemenu ? <ChevronUp /> : <ChevronDown />}
                            </span>
                        </div>
                        <ul class={`sub-menu ${togglemenu && "active"}`}>
                            {profile?.systemconfig_access?.length > 0 && (
                                <li>
                                    <Link class={`${location?.pathname === "/categories" ? "active" : ""}`} to="/categories">
                                        Categories
                                    </Link>
                                </li>
                            )}
                            {profile?.systemconfig_access?.length > 0 && (
                                <li>
                                    <Link class={`${location?.pathname === "/attributes" ? "active" : ""}`} to="/attributes">
                                        Attributes
                                    </Link>
                                </li>
                            )}
                            {profile?.systemconfig_access?.length > 0 && (
                                <li>
                                    <Link class={`${location?.pathname === "/areas" ? "active" : ""}`} to="/areas">
                                        Areas
                                    </Link>
                                </li>
                            )}
                            {profile?.systemconfig_access?.length > 0 && (
                                <li>
                                    <Link class={`${location?.pathname === "/cities" ? "active" : ""}`} to="/cities">
                                        Cities
                                    </Link>
                                </li>
                            )}
                            {profile?.systemconfig_access?.length > 0 && (
                                <li>
                                    <Link class={`${location?.pathname === "/countries" ? "active" : ""}`} to="/countries">
                                        Countries
                                    </Link>
                                </li>
                            )}
                            {profile?.systemconfig_access?.length > 0 && (
                                <li>
                                    <Link class={`${location?.pathname === "/ethnics" ? "active" : ""}`} to="/ethnics">
                                        Ethnicity
                                    </Link>
                                </li>
                            )}
                            {profile?.systemconfig_access?.length > 0 && (
                                <li>
                                    <Link class={`${location?.pathname === "/themes" ? "active" : ""}`} to="/themes">
                                        Themes
                                    </Link>
                                </li>
                            )}
                            {profile?.systemconfig_access?.length > 0 && (
                                <li>
                                    <Link class={`${location?.pathname === "/questions" ? "active" : ""}`} to="/questions">
                                        Questions
                                    </Link>
                                </li>
                            )}
                            {profile?.systemconfig_access?.length > 0 && (
                                <li>
                                    <Link class={`${location?.pathname === "/religions" ? "active" : ""}`} to="/religions">
                                        Religions
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </li>
                )}

                {profile?.blogs_access?.length > 0 && (
                    <li className={`${location?.pathname === "/blogs" || location?.pathname === "/add-blog" || location?.pathname === "/blog-categories" ? "active" : ""} ${togglemenu1 ? "showMenu" : ""}`}>
                        <div class="icon-link" onClick={() => settogglemenu1(!togglemenu1)}>
                            <Link>
                            <img src='../assets/images/blogs-icon.svg' />
                                <span class="link_name">Blogs</span>
                            </Link>
                            <span class={`cursor-pointer`}>
                                {togglemenu1 ? <ChevronUp /> : <ChevronDown />}
                            </span>
                        </div>
                        <ul class={`sub-menu ${togglemenu1 && "active"}`}>
                            <li>
                                <Link class={`${location?.pathname === "/blogs" ? "active" : ""}`} to="/blogs">
                                    All Blogs
                                </Link>
                            </li>
                            <li>
                                <Link class={`${location?.pathname === "/add-blog" ? "active" : ""}`} to="/add-blog">
                                    Add Blogs
                                </Link>
                            </li>
                            <li>
                                <Link class={`${location?.pathname === "/blog-categories" ? "active" : ""}`} to="/blog-categories">
                                    Categories
                                </Link>
                            </li>
                        </ul>
                    </li>
                )}

                {profile?.systemconfig_access?.length > 0 && (
                    <li className={`${location?.pathname === "/tasks" || location?.pathname === "/add-task" ? "active" : ""} ${togglemenu2 ? "showMenu" : ""}`}>
                        <div class="icon-link" onClick={() => settogglemenu2(!togglemenu2)}>
                            <Link>
                            <img src='../assets/images/task-icon.svg' />
                                <span class="link_name">Tasks</span>
                            </Link>
                            <span class={`cursor-pointer`}>
                                {togglemenu2 ? <ChevronUp /> : <ChevronDown />}
                            </span>
                        </div>
                        <ul class={`sub-menu ${togglemenu2 && "active"}`}>
                            <li>
                                <Link class={`${location?.pathname === "/tasks" ? "active" : ""}`} to="/tasks">
                                    All Tasks
                                </Link>
                            </li>
                            <li>
                                <Link class={`${location?.pathname === "/add-task" ? "active" : ""}`} to="/add-task">
                                    Add Task
                                </Link>
                            </li>
                        </ul>
                    </li>
                )}

                {profile?.role === "admin" && (
                    <li className={`${location?.pathname === "/admins" || location?.pathname === "/add-admin" ? "active" : ""} ${togglemenu3 ? "showMenu" : ""}`}>
                        <div class="icon-link" onClick={() => settogglemenu3(!togglemenu3)}>
                            <Link>
                            <img src='../assets/images/admin-icon.svg' />
                            <span class="link_name">Admin</span>
                            </Link>
                            <span class={`cursor-pointer`}>
                                {togglemenu3 ? <ChevronUp /> : <ChevronDown />}
                             </span>
                        </div>
                        <ul class={`sub-menu ${togglemenu3 && "active"}`}>
                            <li>
                                <Link class={`${location?.pathname === "/admins" ? "active" : ""}`} to="/admins">
                                    All Admins
                                </Link>
                            </li>
                            <li>
                                <Link class={`${location?.pathname === "/add-admin" ? "active" : ""}`} to="/add-admin">
                                    Add Admins
                                </Link>
                            </li>
                        </ul>
                    </li>
                )}

                {profile?.stores_access?.length > 0 && (
                    <li className={`${location?.pathname === "/vendors" || location?.pathname === "/add-vendor" ? "active" : ""}`}>
                        <div class="icon-link">
                            <Link to="/vendors">
                            <img src='../assets/images/vendor-icon.svg' />
                                <span class="link_name">Vendors</span>
                            </Link>
                        </div>
                    </li>
                )}

                {profile?.users_access?.length > 0 && (
                    <li className={`${location?.pathname === "/users" ? "active" : ""}`}>
                        <div class="icon-link">
                            <Link to="/users">
                            <img src='../assets/images/users-icon.svg' />
                                <span class="link_name">Users</span>
                            </Link>
                        </div>
                    </li>
                )}

                {profile?.systemconfig_access?.length > 0 && (
                    <li className={`${location?.pathname === "/contact-form" ? "active" : ""}`}>
                        <div class="icon-link">
                            <Link to="/contact-form">
                            <img src='../assets/images/contact-icon.svg' />
                                <span class="link_name">Contact Queries</span>
                            </Link>
                        </div>
                    </li>
                )}

                {profile?.systemconfig_access?.length > 0 && (
                    <li className={`${location?.pathname === "/customerinquiries" ? "active" : ""}`}>
                        <div class="icon-link">
                            <Link to="/customerinquiries">
                            <img src='../assets/images/users-icon.svg' />
                                <span class="link_name">Customer Inquiries</span>
                            </Link>
                        </div>
                    </li>
                )}
                {profile?.systemconfig_access?.length > 0 && (
                    <li className={`${location?.pathname === "/claim-listings" ? "active" : ""}`}>
                        <div class="icon-link">
                            <Link to="/claim-listings">
                            <img src='../assets/images/contact-icon.svg' />
                                <span class="link_name">Claim Listing</span>
                            </Link>
                        </div>
                    </li>
                )}
                {profile?.systemconfig_access?.length > 0 && (
                    <li className={`${location?.pathname === "/report-listings" ? "active" : ""}`}>
                        <div class="icon-link">
                            <Link to="/report-listings">
                            <img src='../assets/images/contact-icon.svg' />
                                <span class="link_name">Report Listing</span>
                            </Link>
                        </div>
                    </li>
                )}

                </ul>
            </div>
    {/* <div class="sidebar">
        <div class="sidebar-logo">
            <a href={`${process.env.REACT_APP_WEBURL}`}>
                <img src="../assets/images/logo.svg" alt=""/>
            </a>
            <div onClick={toggleSidebar} class="sidebar-close" id="sidebar-close">
            <i class="bi bi-arrow-left"></i>
            </div>
        </div>
       
        <div class="simlebar-sc" data-simplebar>
            <ul class="sidebar-menu tf">
                <li class="sidebar-submenu">
                    <a href="/" className={location?.pathname=="/"?"active":""}>
                    <i class="bi bi-house-door-fill"></i>
                        <span>Dashboard</span>
                    </a>
                </li>
                {profile?.systemconfig_access?.length>0&&(<li class="sidebar-submenu">
                    <a className={location?.pathname=="/categories"||location?.pathname=="/attributes" ||location?.pathname=="/areas" ||location?.pathname=="/cities" 
                    ||location?.pathname=="/countries" ||location?.pathname=="/ethnics" ||location?.pathname=="/themes" ||location?.pathname=="/questions"
                    ||location?.pathname=="/religions"?"sidebar-menu-dropdown active":"sidebar-menu-dropdown"} >
                    <i class="bi bi-lightning-fill"></i>
                        <span>System Config</span>
                        <div onClick={()=>settogglemenu(!togglemenu)} class={`dropdown-icon ${togglemenu&&"active"} `}><i class='bx bx-chevron-down'></i></div>
                    </a>
                    <ul class={`sidebar-menu sidebar-menu-dropdown-content ${togglemenu&&"active"} `}>
                    {profile?.systemconfig_access?.length>0&&(<li>
                            <a href="/categories" className={location?.pathname=="/categories"?"active":""}>
                               Categories
                            </a>
                        </li>)}
                        {profile?.systemconfig_access?.length>0&&(<li>
                            <a href="/attributes" className={location?.pathname=="/attributes"?"active":""}>
                                Attributes
                            </a>
                        </li>)}
                        {profile?.systemconfig_access?.length>0&&(<li>
                            <a href="/areas" className={location?.pathname=="/areas"?"active":""}>
                                Areas
                            </a>
                        </li>)}
                        {profile?.systemconfig_access?.length>0&&(<li>
                            <a href="/cities" className={location?.pathname=="/cities"?"active":""}>
                                Cities
                            </a>
                        </li>)}
                        {profile?.systemconfig_access?.length>0&&(<li>
                            <a href="/countries" className={location?.pathname=="/countries"?"active":""}>
                                Countries
                            </a>
                        </li>)}
                        {profile?.systemconfig_access?.length>0&&(<li>
                            <a href="/ethnics" className={location?.pathname=="/ethnics"?"active":""}>
                                Ethnicity
                            </a>
                        </li>)}
                        {profile?.systemconfig_access?.length>0&&(<li>
                            <a href="/themes" className={location?.pathname=="/themes"?"active":""}>
                                Themes
                            </a>
                        </li>)}
                        {profile?.systemconfig_access?.length>0&&(<li>
                            <a href="/questions" className={location?.pathname=="/questions"?"active":""}>
                                Questions
                            </a>
                        </li>)}
                        {profile?.systemconfig_access?.length>0&&(<li>
                            <a href="/religions" className={location?.pathname=="/religions"?"active":""}>
                            Religions
                            </a>
                        </li>)}
                    </ul>
                </li>)}

                {profile?.blogs_access?.length>0&&(<li class="sidebar-submenu">
                    <a className={location?.pathname=="/blogs"||location?.pathname=="/add-blog" ||location?.pathname=="/blog-categories"?"sidebar-menu-dropdown active":"sidebar-menu-dropdown"} >
                    <i class="bi bi-pin-angle-fill"></i>
                        <span>Blogs</span>
                        <div onClick={()=>settogglemenu1(!togglemenu1)} class={`dropdown-icon ${togglemenu1&&"active"} `}><i class='bx bx-chevron-down'></i></div>
                    </a>
                    <ul class={`sidebar-menu sidebar-menu-dropdown-content ${togglemenu1&&"active"} `}>
                        <li>
                            <a href="/blogs" className={location?.pathname=="/blogs"?"active":""}>
                               All Blogs
                            </a>
                        </li>
                        <li>
                            <a href="/add-blog" className={location?.pathname=="/add-blog"?"active":""}>
                                Add blogs
                            </a>
                        </li>
                        <li>
                            <a href="/blog-categories" className={location?.pathname=="/blog-categories"?"active":""}>
                                Categories
                            </a>
                        </li>
                    </ul>
                </li>)}
                {profile?.systemconfig_access?.length>0&&(<li class="sidebar-submenu">
                    <a className={location?.pathname=="/tasks"||location?.pathname=="/add-task"?"sidebar-menu-dropdown active":"sidebar-menu-dropdown"} >
                    <i class="bi bi-box2-fill"></i>
                        <span>Tasks</span>
                        <div onClick={()=>settogglemenu2(!togglemenu2)} class={`dropdown-icon ${togglemenu2&&"active"} `}><i class='bx bx-chevron-down'></i></div>
                    </a>
                    <ul class={`sidebar-menu sidebar-menu-dropdown-content ${togglemenu2&&"active"} `}>
                        <li>
                            <a href="/tasks" className={location?.pathname=="/tasks"?"active":""}>
                               All Tasks
                            </a>
                        </li>
                        <li>
                            <a href="/add-task" className={location?.pathname=="/add-task"?"active":""}>
                                Add Task
                            </a>
                        </li>
                    </ul>
                </li>)}
                {profile?.role=="admin"&&(<li class="sidebar-submenu">
                    <a className={location?.pathname=="/admins"||location?.pathname=="/add-admin"?"sidebar-menu-dropdown active":"sidebar-menu-dropdown"} >
                    <i class='bi bi-calendar'></i>
                        <span>Admin</span>
                        <div onClick={()=>settogglemenu3(!togglemenu3)} class={`dropdown-icon ${togglemenu3&&"active"} `}><i class='bx bx-chevron-down'></i></div>
                    </a>
                    <ul class={`sidebar-menu sidebar-menu-dropdown-content ${togglemenu3&&"active"} `}>
                        <li>
                            <a href="/admins" className={location?.pathname=="/admins"?"active":""}>
                               All Admins
                            </a>
                        </li>
                        <li>
                            <a href="/add-admin" className={location?.pathname=="/add-admin"?"active":""}>
                                Add Admins
                            </a>
                        </li>
                    </ul>
                </li>)}
                {profile?.stores_access?.length>0&&(<li class="sidebar-submenu">
                    <a href="/vendors" className={location?.pathname=="/vendors"||location?.pathname=="/add-vendor"?"sidebar-menu-dropdown active":"sidebar-menu-dropdown"} >
                    <i class="bi bi-box2-fill"></i>
                        <span>Vendors</span>   
                    </a>
                </li>)}
                {profile?.users_access?.length>0&&(<li >
                    <a href="/users" className={location?.pathname=="/users"?"active":""}>
                    <i class="bi bi-box2-fill"></i>
                        <span>users</span>
                    </a>
                </li>)}
                {profile?.systemconfig_access?.length>0&&(<li >
                    <a href="/contact-form" className={location?.pathname=="/contact-form"?"active":""}>
                    <i class="bi bi-chat-dots-fill"></i>
                        <span>Contact Queries</span>
                    </a>
                </li>)}
            </ul>
        </div>

        
    </div> */}
  </>
  
  )
}

export default Sidebar