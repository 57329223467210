import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getProfile,
  AllQuestions,
  deleteQuestion,
  editQuestion
} from "../../store/slices/userSlice";

import Modal from "react-modal";
import { Pencil, Search, ThumbsUp, Trash2, X } from "lucide-react";
const customStyles = {
  content: {
    display:"block",
    paddingRight:"0"
  },
};
Modal.setAppElement("#root");



const Question = () => {
  const [questionCount, setquestionCount] = useState(0);

  const profile=useSelector(getProfile)

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [id, setId] = useState("");
  const [questions, setquestions] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();

  const [feature, setfeature] = useState("");
  const [uniqueCreators, setUniqueCreators] = useState([]);
  const [creators, setCreators] = useState({});
  const [toggle, settoggle] = useState(false);
  const [load, setload] = useState(false);

  const Approve= async (id) => {
    try {
      await dispatch(editQuestion({id: id, approved:1})).unwrap();
      
      try {
        Questions();
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  function viewModal(item, type) {
    setIsOpen(true);
    if (type == "userDetail") {
      setUserDetail(item);
      setId(item?._id);
    } else if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const questionDelete = async (id) => {
    try {
      await dispatch(deleteQuestion(id)).unwrap();
      setIsOpen(false);
     
      try {
        Questions();
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  
  const Questions = async () => {
    try {
      setload(true);
      setquestions(null);
      setFilteredData(null);
      const response = await dispatch(AllQuestions()).unwrap();
      console.log('responsessss', response?.data)
      setquestions(response?.data);

      setFilteredData(response?.data);
      setload(false);

      const uniqueCreatorsSet = new Set();
      const creatorsData = [];

      response?.data.forEach((questions) => {
        uniqueCreatorsSet.add(questions.user_id?._id);
        creatorsData.push(questions.user_id);
      });

      const uniqueCreatorsArray = Array.from(uniqueCreatorsSet);
      setUniqueCreators(uniqueCreatorsArray);

      const creatorsMap = {};
      creatorsData.forEach((creator) => {
        creatorsMap[creator._id] = creator;
      });
      setCreators(creatorsMap);
      
     
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    let mount = true;
    if (mount) {
      Questions();
    }
    return () => {
      mount = false;
    };
  }, []);

  



console.log('id', id)

const [parentFilter, setparentFilter] = useState('');
const [selectedUsers, setselectedUsers] = useState([]);
const [filteredData, setFilteredData] = useState([]);

const applyFilter = () => {
  const filteredResult = questions?.filter(item => {
    console.log(item);
    const creatorName = item?.user_id?.name

    console.log(creatorName, "creatorName");

    return (
      (selectedUsers?.length === 0 || selectedUsers?.includes(creatorName))
    );
  });

  setFilteredData(filteredResult);
  settoggle(false);
};
console.log('selectedUsers', selectedUsers)

const handleStatusChange = (status) => {
  if (selectedUsers.includes(status)) {
    setselectedUsers(prevStatuses => prevStatuses.filter(s => s !== status));
  } else {
    setselectedUsers(prevStatuses => [...prevStatuses, status]);
  }
};

const resetFilter = () => {
  setparentFilter('');
  setselectedUsers([]);
  setFilteredData(questions)
  settoggle(false);
};

return (
<>
{profile?.systemconfig_access?.includes("write")&&(
<Modal
closeTimeoutMS={500}
isOpen={modalIsOpen}
onRequestClose={closeModal}
style={customStyles}
contentLabel="Change Password"
className={`modal custom-modal fade ${modalIsOpen&&"show"} `}
>
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        {modalType == "userDetail" ? (
    <>
      </>
      ): modalType == "delete" ? ( 
        <>
        <div class="modal-content">
                <div class="modal-body">
                    <div class="form-header">
                        <h3>Delete Question</h3>
                        <p>Are you sure you want to delete this Question?</p>
                    </div>
                    <div class="modal-btn delete-action">
                        <div class="row">
                        <div class="col-6 mb-0">
                                <a onClick={closeModal} class="btn btn-primary cancel-btn">Cancel</a>
                            </div>
                            <div class="col-6 mb-0">
                                <a onClick={() => questionDelete(id)} class="btn btn-primary continue-btn">Delete</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        ) : (
        <></>
      )}
        </div>
 
</Modal>)}

<div className="wf-attributes-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb wf-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a>System Config</a></li>
            <li class="breadcrumb-item active" aria-current="page">Questions</li>
        </ol>
    </nav>
    <div className="row gy-3 mb-4 align-items-center mb-2">
      <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
        <h2 className="font-size-28 pink-color dm-font mb-0">Questions</h2>
      </div>
      <div className="col-md-6 order-md-2 order-3">
        <div class="dropdown wf-search-dropdown">
          <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
            <span>Filter and Search</span>
            <span>
              <Search />
              <X />
            </span>
          </a>

          <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
            <div class="row mb-3 sp-input-filter">
              <label for="inputname" class="col-sm-2 col-form-label">Created By</label>
              <div class="col-sm-10 d-flex gap-2 flex-wrap">
                {uniqueCreators.map((creatorId, i) => (
                  <>
                    <input type="checkbox" class="btn-check" checked={selectedUsers.includes(creators[creatorId]?.name)} onChange={() => handleStatusChange(creators[creatorId]?.name)} id={`created-by-${i + 1}`} value={creators[creatorId]?.name} />
                    <label class="btn wf-btn wf-purple-btn" for={`created-by-${i + 1}`}>{creators[creatorId]?.name}</label>
                  </>
                ))}

              </div>
            </div>
            <div className="d-flex gap-2 justify-content-end">
              <button class="btn wf-btn wf-purple-btn px-3" onClick={applyFilter}>Search</button>
              <button class="btn wf-btn wf-purple-btn px-3" onClick={resetFilter}>Reset</button>
            </div>
          </ul>
        </div>
      </div>
      <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
        <button class="btn wf-btn wf-purple-btn px-3" onClick={()=>navigate("/add-question")}>Add Question</button>
      </div>
    </div>
    <div className="wf-radius-20 white-bg wf-shadow p-3">
    <p className="font-size-20 pink-color">Questions : {questions?.length}</p>
    <div class="table-responsive">
    <table class="table wf-table table-borderless table-striped">
  <thead>
    <tr>
      <th scope="col">S.No</th>
      <th scope="col">Question Customer</th>
      <th scope="col">Question Vendor</th>
      <th scope="col">Category</th>
      <th scope="col">Role</th>
      <th scope="col">Type</th>
      <th scope="col">Option</th>
      <th scope="col">Created By</th>
      <th style={{width:'100px'}} scope="col">Action</th>
  </tr>
  </thead>
  <tbody>
  {load ? new Array(8).fill(null).map((_, index) => (<tr><td colSpan={9}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : filteredData?.map((item,i)=> (
    <tr key={i}>
      <td>{i+1}</td>
      <td>{item?.questionCustomer}</td>
      <td>{item?.questionVendor}</td>
      <td>{item?.category_id?.name}</td>
      <td>{item?.question_for}</td>
      <td>{item?.type}</td>
      <td>{item?.options?.map((o,i)=>(<>{o?.name}{i<item?.options?.length-1?", ":""}</>))}</td>
      <td>{item?.user_id?.name}</td>
      <td>
        <div className="d-flex align-items-center gap-2">
        {profile?.role=="admin"&&item?.approved==2&&(<div onClick={ () => Approve(item?._id)} className={`wf-action-icons l-blue-bg`}><ThumbsUp width="18" /></div>)}
            <div onClick={()=>navigate("/add-question", { state: { _id: item,},})} className={`wf-action-icons l-blue-bg`}><Pencil width="18" /></div>
            {profile?.systemconfig_access?.includes("delete")&&(<div onClick={() => viewModal(item?._id, "delete")} className={`wf-action-icons l-blue-bg`}><Trash2 width="18" /></div>)}
        </div>
      </td>
      </tr>))}
  </tbody>
    </table>
    </div>
    </div>
</div>

  
    </>
  )
}

export default Question