import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  storefrontadminapproval,
  getProfile,
  getAllStores,
  FeaturedVendor,
  deleteStore,
  getCities,
  getServices,
} from "../../store/slices/userSlice";
import moment from "moment";
import Modal from "react-modal";
import {Pencil, Search, Star, StarOff, ThumbsDown, ThumbsUp, Trash2, X } from "lucide-react";
const customStyles = {
  content: {
    display: "block",
  },
};
Modal.setAppElement("#root");

const Vendors = () => {
  const [vendorCount, setvendorCount] = useState(0);

  const profile = useSelector(getProfile);

  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [cities, setcities] = useState([]);

  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const [users, setusers] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [toggle, settoggle] = useState(false);
  const [uniqueCreators, setUniqueCreators] = useState([]);
  const [creators, setCreators] = useState({});
  const [load, setload] = useState(false);


  // const Approve = async (id) => {
  //   try {
  //     await dispatch(
  //       storefrontadminapproval({ event_id: id, approved: 1 })
  //     ).unwrap();

  //     try {
  //       Users();
  //     } catch (rejectedValueOrSerializedError) {
  //       console.log(rejectedValueOrSerializedError);
  //     }
  //   } catch (rejectedValueOrSerializedError) {
  //     console.log(rejectedValueOrSerializedError);
  //   }
  // };

  const [showApproveIcon, setShowApproveIcon] = useState(true);
  const Approve = async (e,id) => {
    try {

      // setShowApproveIcon(false);
      const items = filteredData.map((item) =>
        item._id === id ? { ...item, approved: e.target.checked ? 1 : 0 } : item
      );
      console.log('itemssss', items)
      setFilteredData(items);

      await dispatch(storefrontadminapproval({ event_id: id, approved: e.target.checked ? 1 : 0, type: "approve" })).unwrap();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
      // setShowApproveIcon(true);
    }
  };

  function viewModal(item, type) {
    setIsOpen(true);
    if (type == "userDetail") {
      setUserDetail(item);
      setId(item?._id);
    } else if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setId("");
  }

  const storeDelete = async (id) => {
    try {
      await dispatch(deleteStore(id)).unwrap();
      setIsOpen(false);

      try {
        Users();
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Services = async () => {
    try {
      setServices(null);
      const response = await dispatch(getServices()).unwrap();
      console.log('response43434', response)
      setServices(response?.data);
    } catch (rejectedValueOrSerializedError) {}
  };

  const getCity = async () => {
    try {
      const response = await dispatch(getCities()).unwrap();

      setcities(response?.data);

    } catch (rejectedValueOrSerializedError) {}
  };


  const Users = async () => {
    try {
      setload(true);
      setusers(null);
      setFilteredData(null);
      const response = await dispatch(getAllStores()).unwrap();
      console.log("responsessss", response?.data);
      setusers(response?.data);

      setFilteredData(response?.data);
      setload(false);

      const uniqueCreatorsSet = new Set();
      const creatorsData = [];

      response?.data.forEach((category) => {
        uniqueCreatorsSet.add(category?.added_by?.name);
        creatorsData.push(category?.added_by);
      });

      const uniqueCreatorsArray = Array.from(uniqueCreatorsSet);
      setUniqueCreators(uniqueCreatorsArray);

      const creatorsMap = {};
      creatorsData.forEach((creator) => {
        creatorsMap[creator._id] = creator;
      });
      setCreators(creatorsMap);

    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    let mount = true;
    if (mount) {
      Users();
    }
    return () => {
      mount = false;
    };
  }, []);

  const handleFeatureVendor = async (itemm,value) => {
    try {
      const items = filteredData.map((item) =>
        item._id === itemm._id ? { ...item, is_featured: value==1?0:1 } : item
      );
      console.log('itemssss', items)
      setFilteredData(items);
      await dispatch(FeaturedVendor({ id: itemm._id, is_featured: value==1?0:1 })).unwrap();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  

  const [selectcategory, setselectcategory] = useState('');
  const [selectcity, setselectcity] = useState('');
  const [dateFilter, setdateFilter] = useState('');
  const [selectedUsers, setselectedUsers] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const applyFilter = () => {
    const filteredResult = users?.filter(item => {
      console.log(item,"item");

      const category = item?.prefered_services[0]?.name.toLowerCase();
      const city = item?.cities[0]?.name?.toLowerCase();
      const creatorName = item?.added_by?.name

      const itemCreatedAt = new Date(item?.createdAt);

      // Date filter logic
      const dateFilterTimestamp = new Date(dateFilter).setUTCHours(0, 0, 0, 0);
      const itemCreatedAtTimestamp = new Date(itemCreatedAt.setUTCHours(0, 0, 0, 0)).getTime();


      return (
        (selectcategory === '' || category.includes(selectcategory.toLowerCase())) &&
        (selectcity === '' || city?.includes(selectcity.toLowerCase())) &&
        (!dateFilter || itemCreatedAtTimestamp === dateFilterTimestamp) && 
        (selectedUsers?.length === 0 || selectedUsers?.includes(creatorName))
      );
    });

    setFilteredData(filteredResult);
    settoggle(false);
  };
  console.log('filteredData', filteredData)


  const handleStatusChange = (status) => {
    if (selectedUsers.includes(status)) {
      setselectedUsers(prevStatuses => prevStatuses.filter(s => s !== status));
    } else {
      setselectedUsers(prevStatuses => [...prevStatuses, status]);
    }
  };

  const resetFilter = () => {
    setselectcategory('');
    setselectcity('');
    setdateFilter('');
    setselectedUsers([]);
    setFilteredData(users)
    settoggle(false);
  };

  console.log(uniqueCreators,"creators");
  console.log(selectcategory,"category selected");
  console.log(selectcity,"city selected");

  useEffect(() => {   
    Services();
    getCity();
  }, []);


  return (
    <>
      {profile?.stores_access?.includes("write") && (
        <Modal
          closeTimeoutMS={500}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Change Password"
          className={`modal fade show wf-modal wf-modal-30`}>

          <div
            class="modal-dialog modal-dialog-centered wf-modal-dialog"
          >
            {modalType == "userDetail" ? (
              <></>
            ) : modalType == "delete" ? (
              <>
              <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Delete Vendor</h1>
              <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body mt-0">
                <p class="font-size-14 color-black mb-0">Are you sure you want to delete this Vendor?</p>
              </div>
              <div class="modal-footer border-0 justify-content-center gap-2">
                <button onClick={closeModal} class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2">Cancel</button>
                <button onClick={() => storeDelete(id)} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">Delete</button>
              </div>
          </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </Modal>
      )}

<div className="wf-category-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb wf-breadcrumb">
        <li class="breadcrumb-item"><a>Menu</a></li>
        <li class="breadcrumb-item active" aria-current="page">Vendors</li>
      </ol>
    </nav>
    <div className="row gy-3 mb-4 align-items-center mb-2">
      <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
        <h2 className="font-size-28 pink-color dm-font mb-0">Vendors</h2>
      </div>
      <div className="col-md-6 order-md-2 order-3">
      <div class="dropdown wf-search-dropdown">
          <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
            <span>Filter and Search</span>
            <span>{toggle ? <X /> : <Search />}</span>
          </a>
          
          <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
          <div class="dropdown wf-select-dropdown mb-3">
              <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {selectcategory?.length > 0 ? selectcategory : "Category"}
              </a>
              <ul class="dropdown-menu">
                {services?.length > 0 && services?.map((c, i) => (
                  <li key={i}><a onClick={() => setselectcategory(c?.name)} className="dropdown-item">
                    {c?.name}
                  </a></li>
                ))}
              </ul>
            </div>

            <div class="dropdown wf-select-dropdown mb-3">
              <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {selectcity?.length > 0 ? selectcity  : "City"}
              </a>
              <ul class="dropdown-menu">
                {cities?.length > 0 && cities?.map((c, i) => (
                  <li key={i}><a onClick={() => setselectcity(c?.name)} className="dropdown-item">
                    {c?.name}
                  </a></li>
                ))}
              </ul>
            </div>
            <div class="form-floating wf-input mb-3">
                <input type="date" class="form-control" id="date-filter" placeholder="Date" onChange={(e) => setdateFilter(e.target.value)} value={dateFilter}/>
                <label for="date-filter">Date</label>
              </div>

            <div class="row mb-3 sp-input-filter">
              <label for="inputname" class="col-sm-2 col-form-label font-size-20 pink-color dm-font">Author</label>
              <div class="col-sm-10 d-flex gap-2 flex-wrap">
                {uniqueCreators.map((creatorId, i) => (
                  <>
                    <input type="checkbox" class="btn-check" checked={selectedUsers.includes(creatorId)} onChange={() => handleStatusChange(creatorId)} id={`created-by-${i + 1}`} value={creatorId} />
                    <label class="btn wf-select-btn" for={`created-by-${i + 1}`}>{creatorId}</label>
                  </>
                ))}

              </div>
            </div>
            <div className="d-flex gap-2 justify-content-end">
              <button class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2" onClick={applyFilter}>Search</button>
              <button class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2" onClick={resetFilter}>Reset</button>
            </div>
          </ul>
        </div>
      </div>
      <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
      {profile?.stores_access?.includes("write") && (
        <button class="btn wf-btn wf-purple-btn px-3" onClick={() => navigate("/quick-add-vendor", { state: { path: "new" } })}>Quick Add</button>)}
        {profile?.stores_access?.includes("write") && (
        <button class="btn wf-btn wf-purple-btn px-3" onClick={() => navigate("/add-vendor", { state: { path: "new" } })}>Add Vendor</button>)}
        
      </div>
    </div>
    <div className="wf-radius-20 white-bg wf-shadow p-3">
      <p className="font-size-20 pink-color">Vendors : {filteredData?.length}</p>
      <div class="table-responsive">
        <table class="table wf-table table-borderless table-striped">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Store Name</th>
              <th scope="col">Category</th>
              <th scope="col">City</th>
              <th scope="col">Date</th>
              <th scope="col">Author</th>
              <th style={{ width: '100px' }} scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {load ? new Array(20).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : filteredData?.map((item, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{item?.store_name}</td>
                <td>{item?.prefered_services[0]?.name}</td>
                <td>{item?.cities[0]?.name}</td>
                <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                <td>{item?.added_by?.name}</td>
                <td>
                  <div className="d-flex align-items-center gap-2">
                  {profile?.stores_access?.includes("write") ? (
                    <div onClick={() => handleFeatureVendor(item,item?.is_featured)} className={`wf-action-icons l-blue-bg`}>
                      {item?.is_featured == 1 ? (<Star fill="#804099" />) : (<Star  />)}
                    </div>) : (null)}
                    {/* {profile?.role == "admin" && item?.approved == 2 ? (<div onClick={() => Approve(item?._id)} className={`wf-action-icons l-blue-bg`}><ThumbsUp width="18" /></div>) : (<div className={`wf-action-icons l-blue-bg`}><ThumbsDown width="18" /></div>)} */}
                    <div onClick={() => navigate("/add-vendor", {state: { path: "notnew", _id: item._id },})} className={`wf-action-icons l-blue-bg`}><Pencil width="18" /></div>
                    {profile?.stores_access?.includes("delete") && (<div onClick={() => viewModal(item?._id, "delete")} className={`wf-action-icons l-blue-bg`}><Trash2 width="18" /></div>)}
                    {profile?.role == "admin"&&(<div class="form-check form-switch wf-switch">
                      <input checked={item?.approved == 1} onChange={(e) => Approve(e,item?._id)} class="form-check-input" type="checkbox" role="switch" id="approve-disapprove"/>
                    </div>)}
                  </div>
                </td>
              </tr>))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
    </>
  );
};

export default Vendors;