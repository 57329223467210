import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import Nav from "./Nav";
import SidebarAdmin from "./SidebarAdmin";
import Spinner from "../Spinner";
import RequireAuth from "../../pages/Authentication/RequireAuth"
import LoginAdmin from "../../pages/Authentication/LoginAdmin";
import AddBlog from "../../pages/AdminDashboard/AddBlog";
import BlogCategories from "../../pages/AdminDashboard/BlogCategories";
import AddQuestion from "../../pages/AdminDashboard/AddQuestion";
import AddVendor from "../../pages/AdminDashboard/AddVendor";
import Areas from "../../pages/AdminDashboard/Areas";
import Attributes from "../../pages/AdminDashboard/Attributes";
import Countries from "../../pages/AdminDashboard/Countries";
import Religions from "../../pages/AdminDashboard/Religions";
import Themes from "../../pages/AdminDashboard/Themes";
import Blog from "../../pages/AdminDashboard/Blog";
import Categories from "../../pages/AdminDashboard/Categories";
import Cities from "../../pages/AdminDashboard/Cities";
import Dashboard from "../../pages/AdminDashboard/Dashboard";
import Ethnics from "../../pages/AdminDashboard/Ethnics";
import ContactForm from "../../pages/AdminDashboard/ContactForm";
import Questions from "../../pages/AdminDashboard/Questions";
import Users from "../../pages/AdminDashboard/Users";
import VendorsList from "../../pages/AdminDashboard/Vendors";
import Admins from "../../pages/AdminDashboard/Admins";
import AddAdmin from "../../pages/AdminDashboard/AddAdmin";
import BlogPreview from "../../pages/AdminDashboard/BlogPreview";
import { useSelector } from "react-redux";
import { getUserStatus, getUsertoken } from "../../store/slices/userSlice";
import { context } from "../../context/context";
import AddTask from "../../pages/AdminDashboard/AddTask";
import Tasks from "../../pages/AdminDashboard/Tasks";
import QuickAddVendor from "../../pages/AdminDashboard/QuickAddVendor";
import ClaimListing from "../../pages/AdminDashboard/ClaimListing";
import ReportListing from "../../pages/AdminDashboard/ReportListing";
import CustomerInquiries from "../../pages/AdminDashboard/CustomerInquiries";



const Layout = () => {
  const { isLoading } = useContext(context);
  const status = useSelector(getUserStatus);
  const authtoken = useSelector(getUsertoken);


 


  return (
    <>
        <BrowserRouter>
          <Content authtoken={authtoken} />
        </BrowserRouter>
    </>
  );
};


const Content = ({ authtoken }) => {
  var dash = "";
const location = useLocation();
const p = location.pathname.split('/');
const p1 = p[p.length - 1];

for (var i = p1.length - 1; i >= 0; i--) {
  if (p1[i] == '_') {
    console.log("trueeeeeeeee");
    dash = i;
    break;
  }
}

const t = decodeURIComponent(p1.replace(/-/g, ' '));


function capitalizeEachWord(str) {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
}

useEffect(() => {
  document.title = location.pathname == "/" && !authtoken ? "Weddified" : location.pathname == "/" && authtoken ? "Dashboard" :dash !== "" ? capitalizeEachWord(t.substring(0, dash)) : capitalizeEachWord(t);

}, [dash, t, location.pathname]);

  console.log("location.pathname",location.pathname)

  return (
    <>
      {authtoken ? (
        <>
        <Nav />
        <SidebarAdmin />
            <section className={location.pathname == "/login" ? "" : "content-section"}>
            <>
              <Routes>
                
                <Route path="/" element={<RequireAuth allowedRoles={["admin","sub-admin"]}><Dashboard /></RequireAuth>} />
                <Route path="/add-blog" element={<RequireAuth allowedRoles={["admin","sub-admin"]}><AddBlog /></RequireAuth>} />
                <Route path="/add-question" element={<RequireAuth allowedRoles={["admin","sub-admin"]}><AddQuestion /></RequireAuth>} />
                <Route path="/add-vendor" element={<RequireAuth allowedRoles={["admin","sub-admin"]}><AddVendor /></RequireAuth>} />
                <Route path="/quick-add-vendor" element={<RequireAuth allowedRoles={["admin","sub-admin"]}><QuickAddVendor /></RequireAuth>} />
                <Route path="/areas" element={<RequireAuth allowedRoles={["admin","sub-admin"]}><Areas /></RequireAuth>} />
                <Route path="/attributes" element={<RequireAuth allowedRoles={["admin","sub-admin"]}><Attributes /></RequireAuth>} />
                <Route path="/blogs" element={<RequireAuth allowedRoles={["admin","sub-admin"]}><Blog /></RequireAuth>} />
                <Route path="/claim-listings" element={<RequireAuth allowedRoles={["admin","sub-admin"]}><ClaimListing /></RequireAuth>} />
                <Route path="/report-listings" element={<RequireAuth allowedRoles={["admin","sub-admin"]}><ReportListing /></RequireAuth>} />
                <Route path="/blog-categories" element={<RequireAuth allowedRoles={["admin","sub-admin"]}><BlogCategories /></RequireAuth>} />
                <Route path="/categories" element={<RequireAuth allowedRoles={["admin","sub-admin"]}><Categories /></RequireAuth>} />
                <Route path="/countries" element={<RequireAuth allowedRoles={["admin","sub-admin"]}><Countries /></RequireAuth>} />
                <Route path="/cities" element={<RequireAuth allowedRoles={["admin","sub-admin"]}><Cities /></RequireAuth>} />
                <Route path="/religions" element={<RequireAuth allowedRoles={["admin","sub-admin"]}><Religions /></RequireAuth>} />
                <Route path="/ethnics" element={<RequireAuth allowedRoles={["admin","sub-admin"]}><Ethnics /></RequireAuth>} />
                <Route path="/themes" element={<RequireAuth allowedRoles={["admin","sub-admin"]}><Themes /></RequireAuth>} />
                <Route path="/contact-form" element={<RequireAuth allowedRoles={["admin","sub-admin"]}><ContactForm /></RequireAuth>} />
                <Route path="/questions" element={<RequireAuth allowedRoles={["admin","sub-admin"]}><Questions /></RequireAuth>} />
                <Route path="/users" element={<RequireAuth allowedRoles={["admin","sub-admin"]}><Users /></RequireAuth>} />
                <Route path="/blog-preview/:name" element={<RequireAuth allowedRoles={["admin","sub-admin"]}><BlogPreview /></RequireAuth>} />
                <Route path="/vendors" element={<RequireAuth allowedRoles={["admin","sub-admin"]}><VendorsList /></RequireAuth>} />
                <Route path="/admins" element={<RequireAuth allowedRoles={["admin"]}><Admins /></RequireAuth>} />
                <Route path="/add-admin" element={<RequireAuth allowedRoles={["admin"]}><AddAdmin /></RequireAuth>} />
                <Route path="/tasks" element={<RequireAuth allowedRoles={["admin"]}><Tasks /></RequireAuth>} />
                <Route path="/add-task" element={<RequireAuth allowedRoles={["admin"]}><AddTask /></RequireAuth>} />
                <Route path="*" element={<RequireAuth allowedRoles={["admin","sub-admin"]}><Dashboard /></RequireAuth>} />
                <Route path="/customerinquiries" element={<RequireAuth allowedRoles={["admin","sub-admin"]}><CustomerInquiries /></RequireAuth>} />
              </Routes>
            </>
            </section>
        </>
      ) : (
        <>
          <Routes>
            <Route path="/" exact element={<LoginAdmin />} />
          </Routes>
        </>
      )}

      
    </>
  );
};

export default Layout;