
import React, { useEffect, useState, useRef } from "react";
// import Header from "./HeaderDark";
import Spinner from "../../components/Spinner"
import { Pin } from "lucide-react";
import { PinOff } from "lucide-react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from "react-redux";
// import {
//   PinUnPin,
// } from "../../store/slices/userSlice";
import moment from "moment";
import DOMPurify from 'dompurify';
import {
  getProfile
} from "../../store/slices/userSlice";
import axios from "axios";
import parse from "html-react-parser";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';




const BlogPreview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const profile = useSelector(getProfile);

  // const data= location?.state?.data

  const [load, setload] = useState(false);
  const [blogs, setblogs] = useState([]);
  const [data, setdata] = useState([]);

  const Details = async (blog_name) => {
    try {
      setblogs([]);
      setload(true);
      const response = await axios.get(`vendorpage/getlatestblogs`,{
        params:{
          name: blog_name
        }
      });
      console.log("abc", response);
      
      setblogs(response?.data?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  const Detail1 = async (blog_name) => {
    try {
      setdata([]);
      setload(true);
      const response = await axios.post(`vendorpage/getblogsdetails`,{name: blog_name, user_id: profile?._id});
      console.log('response?.data?.data', response?.data?.data)     
      setdata(response?.data?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  const abcd=()=>{
    
    const p= location.pathname.split('/')
    const p1= decodeURIComponent(p[p.length-1])

 console.log('p1', p1)


  const t= p1.replace(/-/g,' ')

  console.log("aa",t)
  Details(t);
  Detail1(t);

  console.log("t",t)
  }

  useEffect(() => {
    
    window.scrollTo({ top: 0, left: 0})
    // Details();
    // Detail1(location?.state?.data?._id);
    abcd()
  }, []);

  // const handlePin = async ( image, id, title ) => {
    
  //   console.log("id", id);
  //   console.log("image", image);

  //   const abc = await dispatch(
  //     PinUnPin({ blog_id: id, imageName: image, user_id: profile?._id })
  //   ).unwrap();
  //   if (abc) {
  //     Detail1(title);
  //   }
  // };

  console.log("data", data);
  console.log("location.pathname", location.pathname.substring(0,13));






  
  const sanitizedHtml = DOMPurify.sanitize(data?.description);
  
 
 
  const parsedHtml = parse(sanitizedHtml, {
    replace: (domNode) => {
      if (domNode.type === 'tag' && domNode.name === 'img') {
        const isPinned = domNode.attribs['data-ispinned'] =='false';
        console.log('isPinned', isPinned)
        const iconComponent = isPinned ? <Pin /> : <PinOff />;
        const imgSrc = domNode.attribs.src;

        return (
          <div className="blog-pin-image">
            <img src={imgSrc} alt="Image" />
            {profile?(<span
              className="pin-blog"
              // onClick={() => handlePin(imgSrc, data?._id, data?.title)}
            >
              {iconComponent}
            </span>):null}
          </div>
        );
      } else {
        return domNode;
      }
    },
  });


  const shareCurrentPage = async () => {
    try {
      if (navigator.share) {
        
        await navigator.share({
          title: 'Share this link',
          url: window.location.href,
        });
      } else {
        
        const fallbackUrl = `${process.env.REACT_APP_FrontURL}/share?url=${encodeURIComponent(window.location.href)}`;
        window.open(fallbackUrl, '_blank');
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };



  const shareOnFacebook = () => {
    const url = window.location.href;
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    window.open(facebookShareUrl, '_blank');
  };

  const shareOnWhatsApp = () => {
    const url = window.location.href;
    const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`;
    window.open(whatsappShareUrl, '_blank');
  };





  

  return (
    <>
    <Helmet>
        <title>{data?.title}</title>
        <meta
          name="description"
          content={data?.excerpt}
        />
        <meta
          name="keywords"
          content="Weddified, wedding planning"
        />
      </Helmet>
    {load&&<Spinner />}
    <div class="container">
       {/* <Header /> */}
       </div>
       
             <section className="single-blog-1">
               <div className="container">
               <div class="border-top  mb-3 mt-2"></div>
                 <div className="row">
                 <div className="col-6">
                 <div className="go-back-btn" onClick={()=>navigate("/ideas-inspiration")}><i class="fas fa-arrow-left"></i> &nbsp;Go Back</div>
                 </div>
                   <div className="col-6 d-flex justify-content-end gap-2" onClick={shareCurrentPage}>
                     <div className="options-vendor-storefront">
                       <div className="share-option">
                       <i class="fas fa-share-alt"></i>
                       </div>
                     </div>
                     <div className="options-vendor-storefront">
                       <div onClick={shareOnFacebook} className="share-option">
                       <i class="bi bi-facebook"></i>
                       </div>
                     </div>
                     <div className="options-vendor-storefront">
                       <div onClick={shareOnWhatsApp} className="share-option">
                       <i class="bi bi-whatsapp"></i>
                       </div>
                     </div>
                  
                    </div>         
                 </div>
              
                 <img
                 src={`${process.env.REACT_APP_IMGURL}${data?.image}`}
                   className="single-blog-image mt-3 mb-3"
                 />
                 
                 <h1 className="h2-title playfair-font color-black mb-0">{data?.title}</h1>
                 <p className="font-size-20 color-black mb-3"><b>
                   Post by <span className="pink-color">{data?.author}</span>{" "}
                   &nbsp;&nbsp; - &nbsp;&nbsp;
                   <a className="pink-color" href={`/suppliers/${data?.category_id?.name.replace(/\s+/g,'-').toLowerCase()}`}>{data?.category_id?.name}</a> &nbsp;&nbsp;
                   - &nbsp;&nbsp; Published on{" "}
                   <span className="pink-color">
                     {moment(data?.createdAt).format("DD-MM-YYYY")}
                   </span>
                   </b>
                 </p>
               </div>
             </section>
   
             <section className="single-blog-2">
               <div className="container">
                 <div className="row">
                     
                     <div className="col-md-8" >
                    
   
                     {parsedHtml}
                   
                     </div>
                     
                  
   
                   <div className="col-md-4 position-relative overflow-hidden">
                     <div className="download-app-front">
                     <div className="row">
                           <div className="col-3">
                           <img className="w-100" src="../assets/images/w-logo.svg" alt="" />
                           </div>
                           <div className="col-9">
                           <div className="download-app-text">
                           <p class="font-size-20 color-black m-0">
                             <b>Download the Weddified App</b>
                           </p>
                           <p class="font-size-16 color-black m-1">
                             Easily plan your wedding anytime, anywhere with the
                             Weddified App.
                           </p>
                           <div className="row">
                           <div className="col-6">
                           <img
                               class="apple-store-img w-100"
                               src="../assets/images/app-store-img.svg"
                               alt=""
                             />
                           </div>
                           <div className="col-6">
                           <img
                               class="google-store-img w-100"
                               src="../assets/images/play-store-img.svg"
                               alt=""
                             />
                           </div>
                           </div>
                         </div>
                           </div>
                           </div>
                       
                     </div>
                     
                     <div className="ad-box mt-3">
                       <h2 className="font-size-36 color-black playfair-font text-center">
                         Ad Box
                       </h2>
                     </div>
                   </div>
                 </div>
               </div>
             </section>
   
             <section className="single-blog-3 mt-5">
               <div className="container">
               <div class="border-top  mb-5 mt-3"></div>
                 <h3 className="font-size-36 playfair-font color-black mb-4">
                   Latest Ideas & Inspiration
                 </h3>
   
                 <div className="row gy-3">
                   {blogs &&
                     blogs?.map((b) => (
                      <div className="col-md-3 blog-card" >
                        <a href={`/ideas-inspiration/${b?.title.replace(/\s/g,'-').toLowerCase()}`}>
                          <img
                            src={`${process.env.REACT_APP_IMGURL}${b?.image}`}
                            className="blog-image"
                          />
                          </a>
                          <h3 className="font-size-18 mt-2">
                            <a href={`/ideas-inspiration/${b?.title.replace(/\s/g,'-').toLowerCase()}`} className="color-black"><b>{b.title}</b></a>
                          </h3>
                          <div className="blog-category">{b.category_id?.name}</div>
                          <p className="font-size-16 color-black">
                          {b.excerpt.substring(0,64)}...
                          </p>
                        </div>
                     ))}
                 </div>
               </div>
             </section>
   
         
         {/* <Footer /> */}
      </>
  );
};

export default BlogPreview;















