import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  getAllCities,
  getAllCountries,
  addCity,
  editCity,
  deleteCity
} from "../../store/slices/userSlice";
import Modal from "react-modal";
import { Pencil, ThumbsUp, Trash2, X } from "lucide-react";
import { Search } from "lucide-react";
const customStyles = {
  content: {
    display:"block",
    paddingRight:"0"
  },
};
Modal.setAppElement("#root");

const Cities = () => {

  const [cityCount, setCityCount] = useState(0);

  const profile=useSelector(getProfile)
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const [cities, setcities] = useState(null);
  const [countries, setcountries] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();

  const [city, setcity] = useState("");
  const [country, setcountry] = useState("");
  const [imageName, setimageName] = useState("");
  const [feature, setfeature] = useState("");
  const [uniqueCreators, setUniqueCreators] = useState([]);
  const [creators, setCreators] = useState({});
  const [toggle, settoggle] = useState(false);
  const [load, setload] = useState(false);

  const viewModal = async (item, type, feature) => {
    setIsOpen(true);
    console.log("feature", feature);
    console.log("type", type);

    if (type == "userDetail" && feature == "add") {
      setId();
      setcity();
      setcountry();
      setimageName();
      setfeature("add")
    }
    if (type == "userDetail" && feature == "edit") {

      setfeature("edit")
      setUserDetail(item);
      setId(item?._id);
    }
    if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  };

  const Approve= async (id) => {
    try {
      await dispatch(editCity({id: id, approved:1})).unwrap();
      
      try {
         Cities();
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const cityDelete = async (id) => {
    try {
      await dispatch(deleteCity(id)).unwrap();
      setIsOpen(false);
      try {
        Cities();
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  
  const Cities = async () => {
    try {
      setload(true);
      setcities(null);
      setFilteredData(null);
      const response = await dispatch(getAllCities()).unwrap();
      console.log('responsessss', response?.data)
      setcities(response?.data);
      setFilteredData(response?.data);
      setload(false);

      const uniqueCreatorsSet = new Set();
      const creatorsData = [];

      response?.data.forEach((cities) => {
        uniqueCreatorsSet.add(cities.user_id?._id);
        creatorsData.push(cities.user_id);
      });

      const uniqueCreatorsArray = Array.from(uniqueCreatorsSet);
      setUniqueCreators(uniqueCreatorsArray);

      const creatorsMap = {};
      creatorsData.forEach((creator) => {
        creatorsMap[creator._id] = creator;
      });
      setCreators(creatorsMap);
     
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Countries = async () => {
    try {
      setcountries(null);
      const response = await dispatch(getAllCountries()).unwrap();
      console.log('responsessss', response?.data)
      setcountries(response?.data);

      
     
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    let mount = true;
    if (mount) {
      Cities();
      Countries();
    }
    return () => {
      mount = false;
    };
  }, []);

  




  const addandEditCity = async (e) => {
    e.preventDefault();

    try {

      const data = new FormData();

      data.append("id", id);
      data.append("city", city);
      data.append("country", country);
      data.append("imageName", imageName);

    
      if (id) {
        await dispatch(editCity(data)).unwrap();
        setIsOpen(false);
      
      Cities();
      setId("")     
      } else { 
          await dispatch(addCity(data)).unwrap();
          setIsOpen(false);
          
          Cities();
           setcity("")
           setcountry("")
           setimageName("")

           
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };



  console.log('cities', country)

  const [parentFilter, setparentFilter] = useState('');
  const [selectedUsers, setselectedUsers] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const applyFilter = () => {
    const filteredResult = cities?.filter(item => {
      console.log(item); // Log the item to the console for inspection

      const userName = item?.country_id?.name?.toLowerCase();
      const creatorName = item?.user_id?.name

      console.log(creatorName, "creatorName");

      return (
        (parentFilter === '' || userName.includes(parentFilter?.toLowerCase())) &&
        (selectedUsers?.length === 0 || selectedUsers?.includes(creatorName))
      );
    });

    setFilteredData(filteredResult);
    settoggle(false);
  };
  console.log('selectedUsers', selectedUsers)

  const handleStatusChange = (status) => {
    if (selectedUsers.includes(status)) {
      setselectedUsers(prevStatuses => prevStatuses.filter(s => s !== status));
    } else {
      setselectedUsers(prevStatuses => [...prevStatuses, status]);
    }
  };

  const resetFilter = () => {
    setparentFilter('');
    setselectedUsers([]);
    setFilteredData(cities)
    settoggle(false);
  };

  useEffect(() => {
    setcountry(userDetail?.country_id?._id)
}, [userDetail])
  

return (
<>
{profile?.systemconfig_access?.includes("write")&&(
<Modal
closeTimeoutMS={500}
isOpen={modalIsOpen}
onRequestClose={closeModal}
style={customStyles}
contentLabel="Change Password"
className={`modal fade show wf-modal wf-modal-30`}
>
        <div class="modal-dialog modal-dialog-centered wf-modal-dialog">
  {modalType == "userDetail"  && feature == "add" ? (
    <>
    <div class="modal-content">
        <div class="modal-header border-0">
            <h1 class="font-size-22 dm-font purple-color">Add City</h1>
            <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <form>
                <div class="row mb-3">
                    <div class="col-sm-6">
                    <div class="form-floating wf-input">
                      <input type="text" onChange={(e)=>setcity(e.target.value)} class="form-control" id="att-name" placeholder="City Name" />
                      <label for="att-name">City Name</label>
                    </div>
                        
                    </div>
                    <div class="col-sm-6">
                    <div class="dropdown wf-select-dropdown">
                      <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {country ? countries.find(user => user?._id === country)?.name : "Country"}
                      </a>
                      <ul class="dropdown-menu">
                      {countries?.map((c,i)=>(
                        <li key={i}><a onClick={(e)=>setcountry(c?._id)} class="dropdown-item">{c?.name}</a></li>
                        ))}
                      </ul>
                    </div>
                    </div>
                </div>
                <div class="form-floating wf-input">
                      <input type="file" onChange={(e)=>setimageName(e.target.files[0])} class="form-control" id="att-file" placeholder="City Banner" />
                      <label for="att-file">City Banner</label>
                  </div>
            </form>
        </div>
        <div class="modal-footer border-0 justify-content-center">
          <button onClick={addandEditCity} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">Save</button>
          </div>
    </div>
    </> ) : modalType == "userDetail" && feature == "edit" ? ( 
      <>
      <div class="modal-content">
        <div class="modal-header border-0">
            <h1 class="font-size-22 dm-font purple-color">Edit City</h1>
            <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <form>
                <div class="row mb-3">
                    <div class="col-sm-6">
                    <div class="form-floating wf-input">
                      <input type="text" disabled={profile?.systemconfig_access?.includes("write")?false:true}  onChange={(e)=>setcity(e.target.value)} defaultValue={userDetail?.name} class="form-control" id="att-name" placeholder="City Name" />
                      <label for="att-name">City Name</label>
                    </div>
                        
                    </div>
                    <div class="col-sm-6">
                    <div class="dropdown wf-select-dropdown">
                      <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {country ? countries.find(user => user?._id === country)?.name : "Country"}
                      </a>
                      <ul class="dropdown-menu">
                      {countries?.map((c,i)=>(
                        <li key={i}><a onClick={(e)=>setcountry(c?._id)} class="dropdown-item">{c?.name}</a></li>
                        ))}
                      </ul>
                    </div>
                    </div>
                </div>
                <div class="form-floating wf-input">
                      <input type="file" onChange={(e)=>setimageName(e.target.files[0])} class="form-control" id="att-file" placeholder="City Banner" />
                      <label for="att-file">City Banner</label>
                  </div>
            </form>
        </div>
        <div class="modal-footer border-0 justify-content-center">
          {profile?.systemconfig_access?.includes("write")&&( <button onClick={addandEditCity} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">Update</button>)}
          </div>
    </div>
      </>
      ): modalType == "delete" ? ( 
        <>
            <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Delete City</h1>
              <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body mt-0">
                <p class="font-size-14 color-black mb-0">Are you sure you want to delete this City?</p>
              </div>
              <div class="modal-footer border-0 justify-content-center gap-2">
                <button onClick={closeModal} class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2">Cancel</button>
                <button onClick={() => cityDelete(id)} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">Delete</button>
              </div>
            </div>
        </>
        ) : (
        <></>
      )}
        </div>
 
</Modal>)}

<div className="wf-areas-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb wf-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a>System Config</a></li>
            <li class="breadcrumb-item active" aria-current="page">Cities</li>
        </ol>
    </nav>
    <div className="row gy-3 mb-4 align-items-center mb-2">
      <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
        <h2 className="font-size-28 pink-color dm-font mb-0">Cities</h2>
      </div>
      <div className="col-md-6 order-md-2 order-3">
        <div class="dropdown wf-search-dropdown">
          <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
            <span>Filter and Search</span>
            <span>
              <Search />
              <X />
            </span>
          </a>

          <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
            <div class="row mb-3 sp-input-filter">
              <label for="inputname" class="col-sm-2 col-form-label">Countries</label>
              <div class="col-sm-10">
                <input type="text" onChange={e => setparentFilter(e.target.value)} value={parentFilter} placeholder="Filter by Countries" class="form-control" id="inputname" />
              </div>
            </div>
            <div class="row mb-3 sp-input-filter">
              <label for="inputname" class="col-sm-2 col-form-label">Created By</label>
              <div class="col-sm-10 d-flex gap-2 flex-wrap">
                {uniqueCreators.map((creatorId, i) => (
                  <>
                    <input type="checkbox" class="btn-check" checked={selectedUsers.includes(creators[creatorId]?.name)} onChange={() => handleStatusChange(creators[creatorId]?.name)} id={`created-by-${i + 1}`} value={creators[creatorId]?.name} />
                    <label class="btn wf-btn wf-purple-btn" for={`created-by-${i + 1}`}>{creators[creatorId]?.name}</label>
                  </>
                ))}

              </div>
            </div>
            <div className="d-flex gap-2 justify-content-end">
              <button class="btn wf-btn wf-purple-btn px-3" onClick={applyFilter}>Search</button>
              <button class="btn wf-btn wf-purple-btn px-3" onClick={resetFilter}>Reset</button>
            </div>
          </ul>
        </div>
      </div>
      <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
        <button class="btn wf-btn wf-purple-btn px-3" onClick={() => viewModal(null, "userDetail", "add")}>Add City</button>
      </div>
    </div>
    <div className="wf-radius-20 white-bg wf-shadow p-3">
    <p className="font-size-20 pink-color">Cities : {cities?.length}</p>
    <div class="table-responsive">
    <table class="table wf-table table-borderless table-striped">
  <thead>
    <tr>
      <th scope="col">S.No</th>
      <th scope="col">City Name</th>
      <th scope="col">Country</th>
      <th scope="col">Created By</th>
      <th style={{width:'100px'}} scope="col">Action</th>
  </tr>
  </thead>
  <tbody>
  {load ? new Array(12).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : filteredData?.map((item,i)=> (
    <tr key={i}>
      <td>{i+1}</td>
      <td>{item?.name}</td>
      <td>{item?.country_id?.name}</td>
      <td>{item?.user_id?.name}</td>
      <td>
        <div className="d-flex align-items-center gap-2">
        {profile?.role=="admin"&&item?.approved==2&&(<div onClick={ () => Approve(item?._id)} className={`wf-action-icons l-blue-bg`}><ThumbsUp width="18" /></div>)}
            <div onClick={() => viewModal(item, "userDetail","edit")} className={`wf-action-icons l-blue-bg`}><Pencil width="18" /></div>
            {profile?.systemconfig_access?.includes("delete")&&(<div onClick={() => viewModal(item?._id, "delete")} className={`wf-action-icons l-blue-bg`}><Trash2 width="18" /></div>)}
        </div>
      </td>
      </tr>))}
  </tbody>
    </table>
    </div>
    </div>
</div>
    
    </>
  )
}

export default Cities