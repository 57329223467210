import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
import "datatables.net";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  getAllContactQueries,
  deleteContactQueries
} from "../../store/slices/userSlice";
import { Trash2 } from "lucide-react";
import moment from "moment"
import { X } from "lucide-react";
import { Eye } from 'lucide-react';
import { ArrowRight } from "lucide-react";
import { Pencil } from "lucide-react";
import { Plus } from "lucide-react";
import Modal from "react-modal";
const customStyles = {
  content: {
    display:"block"
  },
};
Modal.setAppElement("#root");



const FormQueries = () => {
  const [queriesCount, setqueriesCount] = useState(0);

  const profile=useSelector(getProfile)
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const [contact, setcontact] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [load, setload] = useState(false);

  // const [name, setname] = useState("");
  // const [imageName, setimageName] = useState("");


  function viewModal(item, type) {
    setIsOpen(true);
    if (type == "userDetail") {
      setUserDetail(item);
      setId(item?._id);
    } else if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const userDelete = async (id) => {
    try {
      await dispatch(deleteContactQueries({id: id})).unwrap();
      setIsOpen(false);
      $("#tableData").DataTable().destroy();
      try {
        Contacts();
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  
  const Contacts = async () => {
    try {
      setload(true);
      setcontact(null);
      const response = await dispatch(getAllContactQueries()).unwrap();
      console.log('responsessss', response?.data)
      setcontact(response?.data);
      setload(false);
     
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    let mount = true;
    if (mount) {
      Contacts();
    }
    return () => {
      mount = false;
    };
  }, []);




return (
<>
{profile?.systemconfig_access?.includes("write")&&(
<Modal
closeTimeoutMS={500}
isOpen={modalIsOpen}
onRequestClose={closeModal}
style={customStyles}
contentLabel="Change Password"
className={`modal fade show wf-modal wf-modal-30`}
>
        <div class="modal-dialog modal-dialog-centered wf-modal-dialog">
        {modalType == "userDetail" ? (
    <>
    <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Contact Query</h1>
              <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <p className="font-size-20 pink-color dm-font mb-0">Name</p>
                <p className="font-size-16 black-color">{userDetail?.name}</p>
                <p className="font-size-20 pink-color dm-font mb-0">Email</p>
                <p className="font-size-16 black-color">{userDetail?.email}</p>
                <p className="font-size-20 pink-color dm-font mb-0">Role</p>
                <p className="font-size-16 black-color">{userDetail?.role}</p>
                <p className="font-size-20 pink-color dm-font mb-0">Reason of Query</p>
                <p className="font-size-16 black-color">{userDetail?.reason}</p>
                <p className="font-size-20 pink-color dm-font mb-0">Query Details</p>
                <p className="font-size-16 black-color">{userDetail?.message}</p>
                </div>
            </div>
      </>
      ): modalType == "delete" ? ( 
        <>
        <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Delete Contact Query</h1>
              <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body mt-0">
                <p class="font-size-14 color-black mb-0">Are you sure you want to delete this Contact Query?</p>
              </div>
              <div class="modal-footer border-0 justify-content-center gap-2">
                <button onClick={closeModal} class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2">Cancel</button>
                <button onClick={() => userDelete(id)} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">Delete</button>
              </div>
            </div>
        </>
        ) : (
        <></>
      )}
        </div>
 
</Modal>)}

<div className="wf-category-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb wf-breadcrumb">
        <li class="breadcrumb-item"><a>Menu</a></li>
        <li class="breadcrumb-item active" aria-current="page">Contact Queries</li>
      </ol>
    </nav>
    <div className="row gy-3 mb-4 align-items-center mb-2">
      <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
        <h2 className="font-size-28 pink-color dm-font mb-0">Contact Queries</h2>
      </div>
      <div className="col-md-6 order-md-2 order-3">
      </div>
      <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
        
      </div>
    </div>
    <div className="wf-radius-20 white-bg wf-shadow p-3">
      <p className="font-size-20 pink-color">Contact Queries : {contact?.length}</p>
      <div class="table-responsive">
        <table class="table wf-table table-borderless table-striped">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Role</th>
              <th scope="col">Reason</th>
              <th scope="col">Date</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {load ? new Array(6).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : contact?.map((item, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{item?.name}</td>
                <td>{item?.email}</td>
                <td>{item?.role}</td>
                <td>{item?.reason}</td>
                <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                <td>
                  <div className="d-flex align-items-center gap-2">
                  <div onClick={() => viewModal(item, "userDetail")} className={`wf-action-icons l-blue-bg`}><Eye width="18" /></div>
                    {profile?.systemconfig_access?.includes("delete") && (<div onClick={() => viewModal(item?._id, "delete")} className={`wf-action-icons l-blue-bg`}><Trash2 width="18" /></div>)}
                  </div>
                </td>
              </tr>))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
   
    </>
  )
}

export default FormQueries