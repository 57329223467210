import React, { useEffect, useState, useRef } from "react";
import "datatables.net";
import { useDispatch, useSelector } from "react-redux";
import { Trash2, CheckCheck, FileVideo, Contact, FileAudio, FileText, Paperclip, Send, X, Image, Eye, PlusCircle, ArrowRight, Pencil, Plus } from "lucide-react";
import { getChats } from "../../store/slices/userSlice";
import Modal from "react-modal";
import ChatMessage from "./ChatMessage";
import moment from "moment";
const customStyles = {
    content: {
        display: "block"
    },
};
Modal.setAppElement("#root");

const CustomerInquiries = () => {

    const dispatch = useDispatch();

    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState();


    const [chat, setChat] = useState([]);
    const [currentChat, setcurrentChat] = useState([]);
    const [load, setload] = useState(true);

    function viewModal(type,c) {
        setIsOpen(true);
        setModalType(type);
        setcurrentChat(c)
    }


    function closeModal() {
        setIsOpen(false);
    }

    const getAllChats = async (here) => {
        try {
          setChat(null);
          const response = await dispatch(getChats()).unwrap();
          setChat(response?.data)
          setload(false)
        } catch (rejectedValueOrSerializedError) {
          console.log(rejectedValueOrSerializedError);
        }
      };

      useEffect(() => {
        let mount = true;
        if (mount) {
          getAllChats();     
        }
        return () => {
          mount = false;
        };
      }, []);

      console.log('chat', chat)
    

    return (
        <>
            <Modal
                closeTimeoutMS={500}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Change Password"
                className={`modal fade show wf-modal wf-modal wf-modal-75`}
            >
                <div className="modal-dialog modal-dialog-centered wf-modal-dialog wf-modal-75">
                    {modalType == "userDetail" ? (
                        <>
                            <div className="modal-content">
                                <div className="modal-header border-0 pb-0">
                                    <h1 className="font-size-22 dm-font purple-color" id="staticBackdropLabel">Customer Inquiry</h1>
                                    <button type="button" onClick={() => closeModal()} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">                                 
                                    <ChatMessage currentChat={currentChat}/>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>

            </Modal>

           
                <div className="wf-category-list-page px-md-5 pt-3 px-3 pb-5">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb wf-breadcrumb">
                        <li className="breadcrumb-item"><a>Menu</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Customer Inquiries</li>
                    </ol>
                </nav>
                <div className="row gy-3 mb-4 align-items-center mb-2">
                    <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
                        <h2 className="font-size-28 pink-color dm-font mb-0">Customer Inquiries</h2>
                    </div>
                    <div className="col-md-6 order-md-2 order-3">
                    </div>
                    <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">

                    </div>
                </div>
                <div className="wf-radius-20 white-bg wf-shadow p-3">
                    <p className="font-size-20 pink-color">Inquiries List</p>
                    <div className="table-responsive">
                        <table className="table wf-table table-borderless table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Vendor Name</th>
                                    <th scope="col">Customer Name</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {chat?.map((c,i)=>(<tr>
                                    <td>{i+1}</td>
                                    <td>{c?.vendor_store?.store_name}</td>
                                    <td>
                {`${c?.group_membersperson1?.name || ''} (${c?.group_membersperson1?.user_purpose || ''}) & ${ c?.group_membersperson2 ? `${c?.group_membersperson2?.name || ''}` : 'Partner' }`}</td>
                                            <td>{moment(c?.createdAt).format("DD MMM YYYY")}</td>
                                            <td>
                                        <div className="d-flex align-items-center gap-2">
                                            <div onClick={() => viewModal("userDetail", c)} className="wf-action-icons l-blue-bg"><Eye width="18" /></div>
                                            {/* <div className="wf-action-icons l-blue-bg"><Trash2 width="18" /></div> */}
                                        </div>
                                    </td>
                                </tr>))}


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CustomerInquiries;
