import React, { useState, useEffect } from 'react';
import { AllQuestions, getAllAdmins, getAllAreas, getAllAttributes, getAllBlogCat, getAllBlogs, getAllCategories, getAllCities, getAllContactQueries, getAllCountries, getAllEthnics, getAllReligions, getAllStores, getAllThemes, getAllUsers, getTask } from '../../store/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [categories, setcategories] = useState(null);
  const [attributes, setattributes] = useState(null);
  const [countries, setcountries] = useState(null);
  const [cities, setcities] = useState(null);
  const [areas, setareas] = useState(null);
  const [ethnics, setethnics] = useState(null);
  const [themes, setthemes] = useState(null);
  const [religions, setreligions] = useState(null);
  const [questions, setquestions] = useState(null);
  const [users, setusers] = useState(null);
  const [blogs, setblogs] = useState(null);
  const [blogCat, setblogCat] = useState(null);
  const [Vendor, setVendor] = useState(null);
  const [Task, setTask] = useState(null);
  const [admins, setadmins] = useState(null);
  const [contact, setcontact] = useState(null);
  const [load, setload] = useState(false);
  const [load1, setload1] = useState(false);
  const [load2, setload2] = useState(false);
  const [load3, setload3] = useState(false);
  const [load4, setload4] = useState(false);
  const [load5, setload5] = useState(false);
  const [load6, setload6] = useState(false);
  const [load7, setload7] = useState(false);
  const [load8, setload8] = useState(false);
  const [load9, setload9] = useState(false);
  const [load10, setload10] = useState(false);
  const [load11, setload11] = useState(false);
  const [load12, setload12] = useState(false);
  const [load13, setload13] = useState(false);
  const [load14, setload14] = useState(false);
  const [load15, setload15] = useState(false);

    
  const Categories = async () => {
    try {
      setload(true);
      setcategories(null);
      const response = await dispatch(getAllCategories()).unwrap();
      setcategories(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  const Attributes = async () => {
    try {
      setload1(true);
      setattributes(null);
      const response = await dispatch(getAllAttributes()).unwrap();
      console.log('responsessss', response?.data)
      setattributes(response?.data);
      setload1(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  const Countries = async () => {
    try {
      setload2(true);
      setcountries(null);
      const response = await dispatch(getAllCountries()).unwrap();
      console.log('responsessss', response?.data)
      setcountries(response?.data);
      setload2(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  const Cities = async () => {
    try {
      setload3(true);
      setcities(null);
      const response = await dispatch(getAllCities()).unwrap();
      console.log('responsessss', response?.data)
      setcities(response?.data);
      setload3(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  const Areas = async () => {
    try {
      setload4(true);
      setareas(null);
      const response = await dispatch(getAllAreas()).unwrap();
      console.log('responsessss', response?.data)
      setareas(response?.data);
      setload4(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  const Ethnics = async () => {
    try {
      setload5(true);
      setethnics(null);
      const response = await dispatch(getAllEthnics()).unwrap();
      console.log('responsessss', response?.data)
      setethnics(response?.data);
      setload5(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  const Themes = async () => {
    try {
      setload6(true);
      setthemes(null);
      const response = await dispatch(getAllThemes()).unwrap();
      console.log('responsessss', response?.data)
      setthemes(response?.data);
      setload6(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  const Religions = async () => {
    try {
      setload7(true);
      setreligions(null);
      const response = await dispatch(getAllReligions()).unwrap();
      console.log('responsessss', response?.data)
      setreligions(response?.data);
      setload7(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  const Questions = async () => {
    try {
      setload8(true);
      setquestions(null);
      const response = await dispatch(AllQuestions()).unwrap();
      console.log('responsessss', response?.data)
      setquestions(response?.data);  
      setload8(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  const Users = async () => {
    try {
      setload9(true);
      setusers(null);
      const response = await dispatch(getAllUsers()).unwrap();
      console.log('responsessss', response?.data)
      setusers(response?.data);
      setload9(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  const Blogs = async () => {
    try {
      setload10(true);
      setblogs(null);
      const response = await dispatch(getAllBlogs()).unwrap();
      setblogs(response?.data);
      setload10(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const BlogCategories = async () => {
    try {
      setload11(true);
      setblogCat(null);
      const response = await dispatch(getAllBlogCat()).unwrap();
      setblogCat(response?.data);
      setload11(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Vendors = async () => {
    try {
      setload12(true);
      setVendor(null);
      const response = await dispatch(getAllStores()).unwrap();
      console.log("responsessss", response?.data);
      setVendor(response?.data);
      setload12(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Tasks = async () => {
    try {
      setload13(true);
      setTask(null);
      const response = await dispatch(getTask()).unwrap();
      console.log("responsessss", response?.data);
      setTask(response?.data);
      setload13(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  const Admins = async () => {
    try {
      setload14(true);
      setadmins(null);
      const response = await dispatch(getAllAdmins()).unwrap();
      setadmins(response?.data);
      setload14(false);
     
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  const Contacts = async () => {
    try {
      setload15(true);
      setcontact(null);
      const response = await dispatch(getAllContactQueries()).unwrap();
      console.log('responsessss', response?.data)
      setcontact(response?.data);
      setload15(false);
     
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    let mount = true;
    if (mount) {
      Categories();
      Attributes();
      Countries();
      Cities();
      Areas();
      Ethnics();
      Themes();
      Religions();
      Questions();
      Blogs();
      BlogCategories();
      Vendors();
      Users();
      Tasks();
      Admins();
      Contacts();
    }
    return () => {
      mount = false;
    };
  }, []);

  return (
    <>
    <div className="wf-category-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb wf-breadcrumb">
        <li class="breadcrumb-item"><a>Menu</a></li>
        <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
      </ol>
    </nav>
    <h2 className="font-size-28 pink-color dm-font">Dashboard</h2>
    <div className="row gy-3">
      <div className="col-md-3">
        <div className="wf-radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Categories</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
            {load ? <div style={{height:'36px', width:'50px'}} className="shimmer rounded"></div> :<h3 className="font-size-36 pink-color fw-bold mb-0">{categories?.filter((ci) => ci?.parent_id==null)?.length}</h3>}
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/categories")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="wf-radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Attributes</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
            {load1 ? <div style={{height:'36px', width:'50px'}} className="shimmer rounded"></div> :<h3 className="font-size-36 pink-color fw-bold mb-0">{attributes?.length}</h3>}
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/attributes")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="wf-radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Countries</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
            {load2 ? <div style={{height:'36px', width:'50px'}} className="shimmer rounded"></div> :<h3 className="font-size-36 pink-color fw-bold mb-0">{countries?.length}</h3>}
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/countries")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="wf-radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Cities</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
            {load3 ? <div style={{height:'36px', width:'50px'}} className="shimmer rounded"></div> :<h3 className="font-size-36 pink-color fw-bold mb-0">{cities?.length}</h3>}
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/cities")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="wf-radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Areas</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
           {load4 ? <div style={{height:'36px', width:'50px'}} className="shimmer rounded"></div> : <h3 className="font-size-36 pink-color fw-bold mb-0">{areas?.length}</h3>}
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/areas")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="wf-radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Ethnicity</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
            {load5 ? <div style={{height:'36px', width:'50px'}} className="shimmer rounded"></div> :<h3 className="font-size-36 pink-color fw-bold mb-0">{ethnics?.length}</h3>}
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/ethnics")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="wf-radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Themes</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
           {load6 ? <div style={{height:'36px', width:'50px'}} className="shimmer rounded"></div> : <h3 className="font-size-36 pink-color fw-bold mb-0">{themes?.length}</h3>}
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/themes")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="wf-radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Religions</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
            {load7 ? <div style={{height:'36px', width:'50px'}} className="shimmer rounded"></div> :<h3 className="font-size-36 pink-color fw-bold mb-0">{religions?.length}</h3>}
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/religions")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="wf-radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Questions</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
            {load8 ? <div style={{height:'36px', width:'50px'}} className="shimmer rounded"></div> :<h3 className="font-size-36 pink-color fw-bold mb-0">{questions?.length}</h3>}
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/questions")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="wf-radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Blogs</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
            {load10 ? <div style={{height:'36px', width:'50px'}} className="shimmer rounded"></div> :<h3 className="font-size-36 pink-color fw-bold mb-0">{blogs?.length}</h3>}
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/blogs")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="wf-radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Blog Categories</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
            {load11 ? <div style={{height:'36px', width:'50px'}} className="shimmer rounded"></div> :<h3 className="font-size-36 pink-color fw-bold mb-0">{blogCat?.length}</h3>}
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/blog-categories")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="wf-radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Tasks</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
            {load13 ? <div style={{height:'36px', width:'50px'}} className="shimmer rounded"></div> :<h3 className="font-size-36 pink-color fw-bold mb-0">{Task?.length}</h3>}
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/tasks")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="wf-radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Admin Users</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
            {load14 ? <div style={{height:'36px', width:'50px'}} className="shimmer rounded"></div> :<h3 className="font-size-36 pink-color fw-bold mb-0">{admins?.length}</h3>}
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/admins")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="wf-radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Vendors</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
            {load12 ? <div style={{height:'36px', width:'50px'}} className="shimmer rounded"></div> :<h3 className="font-size-36 pink-color fw-bold mb-0">{Vendor?.length}</h3>}
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/vendors")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="wf-radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Users</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
            {load9 ? <div style={{height:'36px', width:'50px'}} className="shimmer rounded"></div> :<h3 className="font-size-36 pink-color fw-bold mb-0">{users?.length}</h3>}
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/users")}><u>View All</u></p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="wf-radius-20 white-bg wf-shadow p-4">
          <p className="font-size-16 purple-color fw-semibold mb-4">Contact Queries</p>
          <div className="d-flex justify-content-between align-items-center pt-2">
            {load15 ? <div style={{height:'36px', width:'50px'}} className="shimmer rounded"></div> :<h3 className="font-size-36 pink-color fw-bold mb-0">{contact?.length}</h3>}
            <p className="font-size-10 grey-color poppins-font mb-0 cursor-pointer" onClick={()=>navigate("/contact-form")}><u>View All</u></p>
          </div>
        </div>
      </div>
    </div>
  </div>
    </>
  )
}

export default Dashboard