import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import { Camera, Check, ChevronsDown, Trash2, X } from "lucide-react";
import {
  getServices,
  editstorefrontadmin,
  getvendorquestionsadmin,
  getAllStores,
  getAllAreas,
  getServiceTypes,
  getAllCountries,
  getEthnics,
  getAttribute,
  getSubAttribute,
  getCities,
  getStoreDetails,
  addArea
} from "../../store/slices/userSlice";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import Spinner from "../../components/Spinner";

const QuickAddVendor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const l = useLocation();
  const path = l?.state?.path;
  const [load, setload] = useState(false);

 //static data from database
  // const [countries, setcountries] = useState(false);
  const [countries, setcountries] = useState([]);
  const [cities, setcities] = useState([]);
  const [areas, setareas] = useState([]); 
  const [questions, setquestions] = useState([]);
  const [value, setvalue] = useState(null);
  const [services, setServices] = useState(null);
  const [servicetypes, setservicetypes] = useState(null);
  const [ethnics, setEthnics] = useState(null);
  const [initattributes, setinitattributes] = useState(null);
  const [initsubattributes, setinitsubattributes] = useState(null);

  const [prevCountry, setPrevCountry] = useState(null);
  const [pricing, setpricing] = useState("");

  

  const [store_name, setstore_name] = useState("");
  const [description, setdescription] = useState("");
  const [overview, setoverview] = useState("");  //new
  const [weddings_hosted, setweddings_hosted] = useState("");  //new
  const [team_members, setteam_members] = useState("");  //new
  const [min_price, setmin_price] = useState("");
  const [max_price, setmax_price] = useState("");
  const [prefered_services, setPreferedServices] = useState([]);
  const [prefered_typeofservices, setprefered_typeofservices] = useState([]);
  const [prefered_sub_services, setprefered_sub_services] = useState([]);
  const [attributes, setattributes] = useState([]);
  const [sub_attributes, setsub_attributes] = useState([]);
  const [insurance, setinsurance] = useState("");  //new
  const [business_attributes, setbusiness_attributes] = useState([]);  //new
  const [business_ethnics, setbusiness_ethnics] = useState([]);  //new
  const [contact_person, setcontact_person] = useState("");  //new
  const [phone, setphone] = useState("");  //new
  const [email, setemail] = useState("");  //new
  const [mobile, setmobile] = useState("");  //new
  const [whatsapp, setwhatsapp] = useState("");  //new
  const [website, setwebsite] = useState("");
  const [pobox_no, setpobox_no] = useState("");  //new
  const [address, setaddress] = useState("");  //new
  const [country, setcountry] = useState([]);  //new
  const [mycities, setmycities] = useState([]);
  const [myareas, setmyareas] = useState([]);  //new
  const [location, setlocation] = useState("");
  const [lat, setlat] = useState(0);
  const [long, setlong] = useState(0);
  const [business_startdate, setbusiness_startdate] = useState("");
 
  
  const [facebook, setfacebook] = useState("");
  const [twitter, settwitter] = useState("");
  const [linkedin, setlinkedin] = useState("");
  const [instagram, setinstagram] = useState("");
  const [youtube, setyoutube] = useState("");
  const [pinterest, setpinterest] = useState("")
  const [video, setvideo] = useState([])
  const [newVideoLink, setnewVideoLink] = useState("")


  // Suggested Vendors
  const [allvendors, setallvendors] = useState([])
  const [suggested_vendors, setsuggested_vendors] = useState([])
  const [searchValue, setSearchValue] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);


// FAQS
  const [faq, setFAQ] = useState([{ question: "", answer: "" }]);
  const [percentage,setpercentage] = useState("none")


  //pricing states
  const [lessthan50,setlessthan50]= useState("")
  const [from50to100,setfrom50to100]= useState("")
  const [from100to150,setfrom100to150]= useState("")
  const [from150to250,setfrom150to250]= useState("")
  const [from250to350,setfrom250to350]= useState("")
  const [from350to500,setfrom350to500]= useState("")
  const [from500to750,setfrom500to750]= useState("")
  const [from750to1000,setfrom750to1000]= useState("")
  const [morethan1000,setmorethan1000]= useState("")

  //deal states
  const [store_deal,setstore_deal]= useState([])
  const [service_name,setservice_name]= useState("")
  const [price,setprice]= useState("")
  

//Images
  const [dp,setdp]=useState("")

  const [images, setImages] = useState([]);
  const [prev_images, setprev_images] = useState([]);




  const [toggle, settoggle] = useState([]);


  const [geocountry,setgeocountry]= useState("");
  const [geocity,setgeocity]= useState("");
  const [geoarea,setgeoarea]= useState("");

 
  
  function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

  function handleDateChange(e) {
    const inputDate = e.target.value;
    const currentDate = getCurrentDate();

    if (inputDate > currentDate) {
        e.target.value = currentDate; 
    }
  }


  // if (value) {
  //   console.log('valueee', value)
  //   geocodeByPlaceId(value?.value?.place_id)
  //     .then((results) => getLatLng(results[0]))
  //     .then(({ lat, lng }) => {
  //       console.log('lat', lat)
  //       setlat(lat);
  //       setlong(lng);
  //       setlocation(value?.label);
  //     })
  //     .catch((error) => console.error(error));
  // }

  if (value) {
    console.log('valueee', value);
  
    geocodeByPlaceId(value?.value?.place_id)
      .then((results) => {
        // Extract address components from the first result
        const addressComponents = results[0]?.address_components || [];
  
        // Extract country, city, and area from address components
        const country = addressComponents.find(component => component.types.includes('country'))?.long_name;
        const city = addressComponents.find(component => component.types.includes('locality'))?.long_name;
        // const area = addressComponents.find(component => component.types.includes('neighborhood'))?.long_name;

        const area = addressComponents.find(component => component.types.includes('neighborhood'))?.long_name
        || addressComponents.find(component => component.types.includes('route'))?.long_name
        || addressComponents.find(component => component.types.includes('street_address'))?.long_name;
  
        // Save values in state variables
        setgeocountry(country || "");
        setgeocity(city || "");
        setgeoarea(area || "");
  
        console.log('Country:', country);
        console.log('City:', city);
        console.log('Area:', area);
  
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        console.log('lat', lat);
        setlat(lat);
        setlong(lng);
        setlocation(value?.label);
      })
      .catch((error) => console.error(error));
  }


  const removeVendorById = (_id) => {
    setsuggested_vendors((prevVendors) =>
      prevVendors.filter((vendor) => vendor._id !== _id)
    );
  };


  const Services = async () => {
    try {
      setServices(null);
      const response = await dispatch(getServices()).unwrap();
      console.log('response43434', response)
      setServices(response?.data);
    } catch (rejectedValueOrSerializedError) {}
  };

  const Countries = async () => {
    try {
      setcountries(null);
      const response = await dispatch(getAllCountries()).unwrap();
      setcountries(response?.data);
     
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Ethnics = async () => {
    try {
      setEthnics(null);
      const response = await dispatch(getEthnics()).unwrap();
      console.log("abc", response?.data);
      setEthnics(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };






  const Details = async () => {
    try {
      
      if(path=="notnew"){

        const data= await dispatch(getStoreDetails({id: l?.state?._id})).unwrap();
      
        console.log('datass', data)

      
        setstore_name(data?.data?.store_name);
        setdescription(data?.data?.description);
        setoverview(data?.data?.overview);
        setweddings_hosted(data?.data?.weddings_hosted);
        setteam_members(data?.data?.team_members);
        setlocation(data?.data?.location?.location);
        setlat(data?.data?.location?.coordinates[1]);
        setlong( data?.data?.location?.coordinates[0]);
        setpobox_no( data?.data?.pobox_no);
        setcountry( data?.data?.country?.map((m)=>m?._id));
        setmyareas( data?.data?.areas?.map((m)=>m?._id));
        setaddress( data?.data?.address);
        setmin_price( data?.data?.min_price);
        setmax_price( data?.data?.max_price);
        setbusiness_ethnics( data?.data?.business_ethnics?.map((m)=>m?._id));
        setcontact_person( data?.data?.contact_person);
        setphone( data?.data?.phone);
        setFAQ(data?.data?.faqs?.data?.length>0?data?.data?.faqs?.data:[{ question: "", answer: "" }]);
        setbusiness_attributes( data?.data?.business_attributes);
        setinsurance( data?.data?.insurance);
        setPreferedServices(data?.data?.prefered_services?.map((m)=>m?._id));
        setpricing(data?.data?.prefered_services[0]?.pricing_type);
        setprefered_typeofservices(data?.data?.prefered_typeofservices?.map((m)=>m?._id));     
        setprefered_sub_services(data?.data?.sub_services);
        setattributes( data?.data?.attributes?.map((m)=>m?._id));
        setvideo( data?.data?.video);
        setsub_attributes( data?.data?.sub_attributes?.map((m)=>m?._id));
        setmycities( data?.data?.cities?.map((m)=>m?._id));    
        setbusiness_startdate(data?.data?.business_startdate);     
        setwhatsapp( data?.data?.whatsapp);
        setemail( data?.data?.email);
        setmobile( data?.data?.mobile);
        setpinterest( data?.data?.pinterest);
        setyoutube( data?.data?.youtube);
        setwebsite( data?.data?.website);
        setfacebook( data?.data?.facebook);
        setpercentage( data?.data?.discount);
        setinstagram( data?.data?.instagram);
        settwitter( data?.data?.twitter);
        setvideo( data?.data?.video);
        setlinkedin( data?.data?.linkedin);
        setdp( data?.data?.image);
        setlessthan50( data?.data?.store_pricing?.lessthan50);
        setfrom50to100( data?.data?.store_pricing?.from50to100);
        setfrom100to150( data?.data?.store_pricing?.from100to150);
        setfrom150to250( data?.data?.store_pricing?.from150to250);
        setfrom250to350( data?.data?.store_pricing?.from250to350);
        setfrom350to500( data?.data?.store_pricing?.from350to500);
        setfrom500to750( data?.data?.store_pricing?.from500to750);
        setmorethan1000( data?.data?.store_pricing?.morethan1000);
        setstore_deal( data?.data?.store_deal?.data);
        setprev_images(data?.data?.imageName?.length>0?data?.data?.imageName:[])
        setsuggested_vendors(data?.data?.suggested_vendors)

    
      }
    } catch (rejectedValueOrSerializedError) {}
  };


  console.log('location', location)
  console.log('lat', lat)
  console.log('long', long)
  console.log('faqaaaaaaaa', faq)


  const ServiceTypes = async (id) => {
    try {
      console.log('idzaaazazazazaz', id)
      setservicetypes(null);
      const response = await dispatch(getServiceTypes(id)).unwrap();

      setservicetypes(response?.data);
    } catch (rejectedValueOrSerializedError) {}
  };

  const getAttributes = async (id) => {
    try {
      console.log('idssss', id)
      setinitattributes(null);
      const response = await dispatch(getAttribute({id: id})).unwrap();
      console.log('response21212', response)
      setinitattributes(response?.data);
    } catch (rejectedValueOrSerializedError) {}
  };

  const getSubAttributes = async (id) => {
    try {
      
      setinitsubattributes(null);
      const response = await dispatch(getSubAttribute({id: id})).unwrap();

      console.log("asdassasa", response);
      setinitsubattributes(response?.data);
    } catch (rejectedValueOrSerializedError) {}
  };

  const getQuestions = async (id) => {
    try {
      
      setquestions(null);
      const response = await dispatch(getvendorquestionsadmin({id:id})).unwrap();

      console.log("asdassasa", response);
      setquestions(response?.data);
    } catch (rejectedValueOrSerializedError) {}
  };

  // const handleCountryChange = (e) => {
  //   console.log('e.target.value', e.target.value);
    
  //   if (prevCountry !== e.target.value) {
  //     setPrevCountry(e.target.value); 
  //     setcountry([e.target.value]);
  //     setmycities(['']);
  //   }
  // };

  const handleCountryChange = (countryId) => {
    console.log('Selected Country ID:', countryId);
  
    if (prevCountry !== countryId) {
          setPrevCountry(countryId); 
          setcountry(countryId);
          setmycities([]);
        }
  }; 

  


  const handlePricingChange = (e,i) => {
    console.log('e.target.valuee', e.target.value,i);
    
    if(i===0){
      setlessthan50(e.target.value)
    }
    if(i===1){
      setfrom50to100(e.target.value)
    }
    if(i===2){
      setfrom100to150(e.target.value)
    }
    if(i===3){
      setfrom150to250(e.target.value)
    }
    if(i===4){
      setfrom250to350(e.target.value)
    }
    if(i===5){
      setfrom350to500(e.target.value)
    }
    if(i===6){
      setfrom500to750(e.target.value)
    }
    if(i===7){
      setfrom750to1000(e.target.value)
    }
    if(i===8){
      setmorethan1000(e.target.value)
    }
  };


  const handleToggleChange = (id) => {
    console.log('idddddddddddddddd', id);
    if (!toggle.includes(id)) {
      settoggle([...toggle, id]);
    } else {
      const updatedEventIds = toggle.filter((itemId) => itemId !== id);
      settoggle(updatedEventIds);
    }
  };


  const getCity = async () => {
    try {
      const response = await dispatch(getCities()).unwrap();

      setcities(response?.data);

    } catch (rejectedValueOrSerializedError) {}
  };

  const getSuggestedVendors = async () => {
    try {
      setallvendors(null);
      const response = await dispatch(getAllStores()).unwrap();
      console.log("responsessss", response?.data);
      if (l?.state?._id?._id) {
        const filteredData = response?.data.filter((vendor) => vendor._id.toString() !== l?.state?._id?._id.toString());
        setallvendors(filteredData);
      }
      
      else{
      setallvendors(response?.data);
}
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Areas = async () => {
    try {
      setareas(null);
      const response = await dispatch(getAllAreas()).unwrap();
      console.log('responsessss', response?.data)
      setareas(response?.data);
      
      if (response?.data) {
        const count = response?.data.length;
        
        setareas(response?.data);
      }
     
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  const handleImageUpload = (event) => {
    
    const selectedImages = event.target.files;
    const imagesArray = [];
   
    for (let i = 0; i < selectedImages.length; i++) {
      imagesArray.push(selectedImages[i]);
    }
    setImages(images.concat(imagesArray));
};

  const handleImageRemovePrev = (e, index) => {
    e.preventDefault();
    const updatedImages = [...prev_images];
    updatedImages.splice(index, 1);
    setprev_images(updatedImages);
  };

  const handleImageRemove = (e, index) => {
    e.preventDefault();
    const updatedImages = [...images];
    updatedImages.splice(index, 1);

    setImages(updatedImages);
  };

  

  useEffect(() => {
   
  if (path == "notnew" ) {
      

      Details();
      Services();
      getCity();
     
     
    }
  }, [l?.state]);


  console.log('l?.state', l?.state)


  useEffect(() => {
   
 
 
     
    Services();
    Countries();
    getCity();
    Ethnics();
    Areas();
    getSuggestedVendors();
     
     
    
  }, []);




  useEffect(() => {
   
  

      getAttributes(prefered_services[0])
      getSubAttributes(prefered_services[0])
      getQuestions(prefered_services)
      ServiceTypes(prefered_services);
     
    
  }, [prefered_services]);




  
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value.length > 0) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  const handleOptionClick = (option) => {
    setSearchValue("");
    setsuggested_vendors([...suggested_vendors,{_id: option?._id, store_name: option?.store_name}])
    setShowDropdown(false);
  };
  

  

  // const handleSkillChange1 = (eventid) => {
  //   console.log('eventid', eventid)
  //   if (!prefered_typeofservices?.includes(eventid)) {
  //     setprefered_typeofservices([...prefered_typeofservices, eventid]); 
  //   } else {
  //     const updatedEventIds = prefered_typeofservices?.filter((id) => id !== eventid);
  //     setprefered_typeofservices(updatedEventIds);
  //   }
  // };

  console.log('prefered_typeofservices', prefered_typeofservices)

  const handleEthnicChange = (eventid) => {
    console.log('eventid', eventid)
    if (!business_ethnics?.includes(eventid)) {
      setbusiness_ethnics([...business_ethnics, eventid]); 
    } else {
      const updatedEventIds = business_ethnics?.filter((id) => id !== eventid);
      setbusiness_ethnics(updatedEventIds);
    }
  };



  const handleSkillChange4 = (answer, question) => {
    console.log('answer, question', answer, question);
  
    // Clone the prefered_sub_services array to avoid modifying the original directly
    const updatedSubServices = [...prefered_sub_services];
  
    // Check if there's an existing object with the same question ID
    const existingObjectIndex = updatedSubServices.findIndex((obj) => obj._id === question);
  
    if (existingObjectIndex !== -1) {
      // If the object with the same question exists, check if the answer should be added or removed
      const existingObject = updatedSubServices[existingObjectIndex];
      const answerIndex = existingObject.item.indexOf(answer);
  
      if (answerIndex !== -1) {
        // Answer exists in the item array, remove it
        existingObject.item.splice(answerIndex, 1);
  
        // If the item array is empty, remove the object
        if (existingObject.item.length === 0) {
          updatedSubServices.splice(existingObjectIndex, 1);
        }
      } else {
        // Answer doesn't exist, add it to the item array
        existingObject.item.push(answer);
      }
    } else {
      // If no object with the same question exists, create a new one
      updatedSubServices.push({ _id: question, item: [answer] });
    }
  
    // Update the state with the modified array
    setprefered_sub_services(updatedSubServices);
  };

  console.log('sub_services', prefered_sub_services)

  
 

  const handleSkillChange2 = async (eventid) => {
  

    console.log('eventid', eventid)
    if (!attributes?.includes(eventid)) {
      setattributes([...attributes, eventid]); 
    } else {
      const updatedEventIds = attributes?.filter((id) => id !== eventid);
      setattributes(updatedEventIds);
    }

  };
 

  const handleSkillChange3 = async (eventid) => {
   

    console.log('eventid', eventid)
    if (!sub_attributes?.includes(eventid)) {
      setsub_attributes([...sub_attributes, eventid]); 
    } else {
      const updatedEventIds = sub_attributes?.filter((id) => id !== eventid);
      setsub_attributes(updatedEventIds);
    }
  };

  console.log('prefered_services', prefered_services)
  console.log('prefered_typeofservices', prefered_typeofservices)


  const saveBranch = async (e) => {
    try {
      setload(true);
    e.preventDefault();
      const data = new FormData();
        data.append("event_id", l?.state?._id?l?.state?._id:"");
        data.append("store_name", store_name);
        data.append("description", description);
        data.append("overview", overview);
        data.append("weddings_hosted", weddings_hosted);
        data.append("team_members", team_members);
        data.append("min_price", min_price);
        data.append("max_price", max_price);
        data.append("prefered_services", JSON.stringify(prefered_services));
        data.append("prefered_typeofservices", JSON.stringify(prefered_typeofservices));
        data.append("sub_services", JSON.stringify(prefered_sub_services));
        data.append("attributes", JSON.stringify(attributes));
        data.append("sub_attributes", JSON.stringify(sub_attributes));
        data.append("location", location);
        data.append("lat", lat);
        data.append("long", long);
        data.append("insurance", insurance);
        data.append("business_attributes", JSON.stringify(business_attributes));
        data.append("business_ethnics", JSON.stringify(business_ethnics));
        data.append("contact_person", contact_person);
        data.append("phone", phone);
        data.append("email", email);
        data.append("mobile", mobile);
        data.append("whatsapp", whatsapp);
        data.append("website", website);
        data.append("pobox_no", pobox_no);
        data.append("address", address);
        data.append("country", JSON.stringify(country));
        data.append("cities", JSON.stringify(mycities));
        data.append("areas", JSON.stringify(myareas));
        // data.append('areas', Array.isArray(areas) ? JSON.stringify(areas) : '[]');
        data.append("business_startdate", business_startdate);
        data.append("facebook", facebook);
        data.append("twitter", twitter);
        data.append("linkedin", linkedin);  
        data.append("instagram", instagram);
        data.append("youtube", youtube);
        data.append("pinterest", pinterest);
        data.append("video", JSON.stringify(video));
        data.append("faqs", faq&&faq[0]?.question!==""&&faq[0]?.answer!==""?JSON.stringify(faq):JSON.stringify([]));
        data.append("percentage", percentage);
        if(prefered_services[0]?.pricing_type=="pricing")
        {data.append("lessthan50", lessthan50);
        data.append("from50to100", from50to100);
        data.append("from100to150", from100to150);
        data.append("from150to250", from150to250);
        data.append("from250to350", from250to350);
        data.append("from350to500", from350to500);
        data.append("from500to750", from500to750);
        data.append("from750to1000", from750to1000);
        data.append("morethan1000", morethan1000);}
        if(prefered_services[0]?.pricing_type=="deal"&&store_deal!=="undefined"&&store_deal!==undefined)
        {data.append("store_deal", JSON.stringify(store_deal))}
        data.append("pricing", pricing);
        data.append("image", dp);
        data.append("suggested_vendors", JSON.stringify(suggested_vendors));
        data.append("prev_picture", JSON.stringify(prev_images));
        images.forEach((item) => {
        data.append("imageName", item);
        });


        console.log('Form Data:', formDataToObject(data));
        
        await dispatch(
          editstorefrontadmin(data)
          ).unwrap();
          setload(false);
          navigate('/vendors')

        
      
      
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  function formDataToObject(formData) {
    const object = {};
    formData.forEach((value, key) => {
      object[key] = value;
    });
    return object;
  }
  

  const addMoreFAQ = () => {
    setFAQ([...faq, { question: "", answer: "" }]);
  };

  const addMoreDeal = () => {
    setstore_deal([...store_deal, { service_name: service_name, price: price }]);
    setservice_name("")
    setprice("")
  };


const numberofWeddings = [
  {
    id: 1,
    text: '<10',
  },
  {
    id: 2,
    text: '10-25',
  },
  {
    id: 3,
    text: '25-30',
  },
  {
    id: 4,
    text: '50+',
  },
];
const numberofTeam = [
  {
    id: 5,
    text: '1-2',
  },
  {
    id: 6,
    text: '3-10',
  },
  {
    id: 7,
    text: '11-25',
  },
  {
    id: 8,
    text: '25+',
  },
];

console.log('dddddddddddddddddp', dp)
const BusinessAttributes = [
  {
    id: 9,
    text: 'Emarati-Owned',
  },
  {
    id: 10,
    text: 'Arab-Owned',
  },
  {
    id: 11,
    text: 'Woman-Owned',
  },
  {
    id: 12,
    text: 'Asian-Owned',
  },
];

const pricingArray=["Less than 50", "From 50 to 100", "From 100 to 150", "From 150 to 250", "From 250 to 350", "From 350 to 500", "From 500 to 750", "From 750 to 1000", "More than 1000"]


console.log('video', video)
console.log('areas', areas)
console.log('allvendors', allvendors)
console.log('countries', countries)

const [searchValue2, setSearchValue2] = useState('');
const [showDropdown2, setShowDropdown2] = useState(false);

const handleInputChange2 = (e) => {
  setSearchValue2(e.target.value);
  setShowDropdown2(true);
};

const handleInputBlur2 = () => {
  if (!searchValue2) {
    setShowDropdown2(false);
  }
};


useEffect(() => {
  // Fetch areas and set the state
  const fetchAreas = async () => {
    try {
      const response = await dispatch(getAllAreas()).unwrap();
      console.log('responsessss', response?.data);
      setareas(response?.data);
    } catch (error) {
      console.error('Error fetching areas:', error);
    }
  };

  fetchAreas();
}, []);


const handleOptionClick2 = async (optionId) => {
  try {
    if (optionId) {
      setmyareas(optionId);
      const selectedArea = areas.find((user) => user?._id === optionId);
      if (selectedArea) {
        setSearchValue2(selectedArea.name);
      }
      // setSearchValue2(areas?.find(user => user?._id === myareas)?.name);
      setShowDropdown2(false);
      console.log('Selected Area ID:', optionId);
    } else {
      const response = await dispatch(addArea({ name: searchValue2, city_id: mycities })).unwrap();
      console.log('Add Area Response:', response);
      await Areas();

      if (response?.status === 1 && response?.data) {
        await Areas();
        setmyareas(response?.data?._id);
        setSearchValue2(response?.data?.name);
        console.log('Added Area ID:', response?.data?._id);
      } else {
        console.error('Failed to add area:', response?.message);
      }
      setShowDropdown2(false);
    }
  } catch (rejectedValueOrSerializedError) {
    console.error(rejectedValueOrSerializedError);
  }
};

console.log(myareas,"Areas Selected");

console.log(contact_person,"Contact Person");
console.log(phone,"Phone");

const [users, setusers] = useState(null);
const [businessNameExists, setBusinessNameExists] = useState(false);

const Users = async () => {
  try {
    const response = await dispatch(getAllStores()).unwrap();
    console.log("responsessss", response?.data);
    setusers(response?.data);
  } catch (rejectedValueOrSerializedError) {
    console.log(rejectedValueOrSerializedError);
  }
};

// const checkBusinessName = (enteredName) => {
//   const doesExist = users?.some(user => user.store_name === enteredName);
//   setBusinessNameExists(doesExist);
// };

const checkBusinessName = (enteredName) => {
  const doesExist = users?.some(user =>
    user.store_name.toLowerCase().includes(enteredName.toLowerCase())
  );
  setBusinessNameExists(doesExist);
};

useEffect(() => {
  Users();
}, []); 

useEffect(() => {
  setcontact_person(store_name);
  setphone(mobile);
  setaddress(location);
}, [store_name, mobile, location]);



useEffect(() => {
  const selectedArea = areas?.find(area => area.name === geoarea);
  if (selectedArea) {
    setmyareas(selectedArea._id);
  } else {
    setSearchValue2(geoarea);
  }
  setcountry(countries?.find(user => user.name === geocountry)?._id)
  setmycities(cities?.find(user => user.name === geocity)?._id)
  // setmyareas(areas?.find(user => user.name === geoarea)?._id)
  // setSearchValue2(geoarea);
}, [geocountry, geocity, geoarea, countries, cities, areas]); 

console.log(country, "My country");
console.log(searchValue2, "Search Value");


console.log(myareas, "My areas");


  return (<>
  {load && <Spinner />}
    <div className="wf-attributes-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb wf-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a>Vendors</a></li>
            <li class="breadcrumb-item active" aria-current="page">Quick Add Vendor</li>
        </ol>
    </nav>
    <h2 className="font-size-28 pink-color dm-font mb-3">Quick Add Vendor</h2>
    <div className="wf-radius-20 white-bg wf-shadow p-3 mb-4">
    <p class="font-size-26 black-color dm-font mb-2">Business Details</p>
      <div className="row gy-3 mb-3">
      <div className="col-md-6 d-flex gap-1 align-items-center">
      <div class="form-floating wf-input w-100">
      <input type="text" onChange={(e) => { setstore_name(e.target.value);  checkBusinessName(e.target.value);}} 
      defaultValue={ path !== "new" ? store_name && store_name : ""} class="form-control" id="business-name" placeholder="Business Name" required/>
      <label for="business-name">Business Name</label>
      </div>
      <span className="pink-color">{businessNameExists ? <X /> : <Check />}</span>
      </div>
      <div className="col-md-6">
      <div class="form-floating wf-input">
      <input type="date" onChange={(e) => setbusiness_startdate(e.target.value)} onBlur={handleDateChange} max={getCurrentDate()} defaultValue={business_startdate && business_startdate} class="form-control" id="Operating-Since" placeholder="12/june/2023"  required/>
      <label for="Operating-Since">Operating Since</label>
      </div>
      </div>
      <div className="col-md-6">
      <p class="font-size-20 pink-color dm-font mb-2">Type of Service</p>
        <div className="d-flex flex-wrap gap-2 mb-3">
        {services? services.map((s) => (
          <>
          <input class="btn-check" name="types-of-service" type="radio" id={s._id} value={s._id} checked={prefered_services?.includes(s._id)} onChange={() => {setPreferedServices([s._id]); setpricing(s.pricing_type)}} />
          <label class="btn wf-select-btn" for={s._id}>{s.name}</label>
          </>
        )): null}
        </div>

        {servicetypes?.length > 0 ? (<>
          <p class="font-size-20 pink-color dm-font mb-2">Types of Sub Service</p>
          <div className="d-flex flex-wrap gap-2 mb-3">
          {servicetypes? servicetypes[0]?.subcategories?.map((s) => (
            <>
            <input class="btn-check" type="radio" name="sub-cat" id={s._id} value={s._id} checked={prefered_typeofservices?.includes(s._id)?true:false} onChange={() => setprefered_typeofservices([s._id])} />
            <label class="btn wf-select-btn" for={s._id}>
              {s.name}
            </label>
            </>
          )): null}
          </div>
          </>
          ): null}

          {questions?.length > 0 &&questions?.map((q)=>(
          <> 
          <p class="font-size-20 pink-color dm-font mb-2">{q.question}</p>
          <div className="d-flex flex-wrap gap-2 mb-3">
          {q?.options?.map((s) => (
            <>
            <input class="btn-check" type="checkbox" name="questions" checked={prefered_sub_services?.some((obj) => obj._id == q._id && obj.item?.includes(s._id))} onChange={() => handleSkillChange4(s?._id, q?._id)} />
            <label class="btn wf-select-btn" >{s.name}</label>
            </>
          ))}
          </div>
          </>
            ))}
              
          {initattributes && initattributes[0]?.subcategories?.length > 0 && (
              <>
                <p class="font-size-20 pink-color dm-font mb-2">Which Attributes best suits you</p>
                <div className="d-flex flex-wrap gap-2 mb-3">
                {initattributes[0]?.subcategories?.map((s) => (
                    <>
                      <input className="btn-check" type="checkbox" name="attributes" id={s._id} value={s._id} checked={attributes?.includes(s._id)} onChange={() => handleSkillChange2(s?._id)}/>
                      <label className="btn wf-select-btn" htmlFor={s._id}>{s.name}</label>
                    </>
                ))}
                </div>
              </>
            )}

            {initsubattributes && initsubattributes[0]?.subcategories?.length > 0 && (
            <>
              <p class="font-size-20 pink-color dm-font mb-2">What are the extra features (sub attributes) you provide</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
              {initsubattributes[0]?.subcategories?.map((s) => (
                  <>
                    <input className="btn-check" type="checkbox" name="attributes" id={s._id} value={s._id} checked={sub_attributes?.includes(s._id)}  onChange={() => handleSkillChange3( s?._id )}/>
                    <label className="btn wf-select-btn" htmlFor={s._id}>{s.name}</label>
                  </>
              ))}
              </div>
            </>
          )}

      </div>
      <div className="col-md-6">
      <div class="form-floating mb-3 wf-textarea">
        <textarea class="form-control" placeholder="Brief Overview" id="Brief-Overview" onChange={(e) => setoverview(e.target.value)} defaultValue={ path !== "new" ? overview && overview : "" } required  
        style={{height:'150px'}}></textarea>
        <label for="Brief-Overview">Brief Overview</label>
      </div>
      </div>
      <div className="col-md-6">
      <p class="font-size-20 pink-color dm-font mb-2">Number of Wedding Hosted</p>
      <div className="d-flex flex-wrap gap-2 mb-3">
      {numberofWeddings?.map((s) => (
        <>
        <input checked={weddings_hosted==s.text?true:false} class="btn-check" type="radio" name="wedding-hosted" id={s.text} onChange={() => setweddings_hosted(s.text)} value={s.id} />
        <label class="btn wf-select-btn" for={s.text}>{s.text}</label>
        </>
      ))}
      </div>

      <p class="font-size-20 pink-color dm-font mb-2">Number of Team Members</p>
      <div className="d-flex flex-wrap gap-2 mb-3">
      {numberofTeam?.map((s) => (
        <>
        <input checked={team_members==s.text?true:false} class="btn-check" type="radio" name="team_members" id={s.text} onChange={() => setteam_members(s.text)} value={s.id} />
        <label class="btn wf-select-btn" for={s.text}>{s.text}</label>
        </>
      ))}
      </div>


      <p class="font-size-20 pink-color dm-font mb-2">Business Attributes</p>
      <div className="d-flex flex-wrap gap-2 mb-3">
      {BusinessAttributes?.map((s) => (
        <>
        <input checked={business_attributes==s.text?true:false} onChange={(e) => setbusiness_attributes([e.target.value])} class="btn-check" type="radio" name="team-members" id={s.text} value={s.text} />
        <label class="btn wf-select-btn" for={s.text}>{s.text}</label>
        </>
      ))}
      </div>


      </div>
      <div className="col-md-6">
      <p class="font-size-20 pink-color dm-font mb-2">Business Services Price Range</p>
      <div className="row mb-3">
      <div className="col-md-6">
      <div class="form-floating wf-input">
      <input type="number" onChange={(e) => setmin_price(e.target.value)} placeholder="1,000" defaultValue={min_price && min_price} class="form-control" id="Min-Rates" required/>
      <label for="Min-Rates">Min Rates</label>
      </div>
      </div>
      <div className="col-md-6">
      <div class="form-floating wf-input">
      <input type="number" onChange={(e) => setmax_price(e.target.value)} placeholder="10,000" defaultValue={max_price && max_price} class="form-control" id="Max-Rates" required/>
      <label for="Max-Rates">Max Rates</label>
      </div>
      </div>
      </div>

      <p class="font-size-20 pink-color dm-font mb-2">Would You Consider Your Business Specialist for Any of the Following Wedding Types?</p>
      <div className="d-flex flex-wrap gap-2 mb-3">
        {ethnics?.map((s) => (
          <>
          <input checked={business_ethnics?.includes(s._id)?true:false} onChange={() => handleEthnicChange(s._id)}  class="btn-check" type="checkbox" name="team-members" id={s._id} value={s._id} />
          <label class="btn wf-select-btn" for={s._id}>{s.name}</label>
          </>
        ))}
        </div>
      </div>
      </div>
      
      {/* <input type="text" hidden onChange={(e) => setcontact_person(e.target.value)} value={store_name} />
      <input type="tel" hidden onChange={(e) => setphone(e.target.value)} value={mobile} /> */}



    <p class="font-size-26 black-color dm-font mb-2">Contact Details</p>
    <div className="row gy-3 mb-3">
    <div className="col-md-4">
    <div class="form-floating wf-input">
      <input type="url" class="form-control" id="Website" placeholder="Website" defaultValue={website} onChange={(e) => setwebsite(e.target.value)} />
      <label for="Website">Website</label>
    </div>
    </div> 
    <div className="col-md-4">
    <div class="form-floating wf-input">
      <input type="email" class="form-control" id="Email" placeholder="Email" onChange={(e) => setemail(e.target.value)} defaultValue={email && email} />
      <label for="Email">Email</label>
    </div>
    </div>  
    <div className="col-md-4 d-flex gap-2 align-items-center">
    <div class="form-floating wf-input w-100">
      <input type="tel" class="form-control" id="Mobile" placeholder="Mobile" defaultValue={mobile && mobile} onChange={(e) => setmobile(e.target.value)}/>
      <label for="Mobile">Mobile</label>
    </div>
    <div class="form-check form-check-inline">
      <input checked={whatsapp?true:false} onChange={(e) => setwhatsapp(e.target.checked ? mobile : "")} defaultValue={whatsapp} class="form-check-input" type="checkbox" name="hold-insurance" id="whatsapp" value="whatsapp" />
      <label class="form-check-label" for="whatsapp">
      Whatsapp
      </label>
    </div>
    </div>  
    
    </div>  
    <p class="font-size-26 black-color dm-font mb-2">Location Details</p>
    <div className="row mb-3">
    <div className="col-md-6">
    <div className="wf-shadow rounded  mb-3">
    <GooglePlacesAutocomplete apiKey={process.env.REACT_GOOGLE_MAPS_API_KEY}
     selectProps={{ value, onChange: setvalue, placeholder: location && path !== "new" ? location : "Enter your location", }} />
    </div>    
    <div class="form-floating mb-3 wf-textarea">
      <textarea class="form-control" placeholder="Address" id="Address" onChange={(e) => setaddress(e.target.value)} value={address}  
      style={{height:'150px'}}></textarea>
      <label for="Address">Address</label>
    </div>
    </div>
    <div className="col-md-6">
    <div class="dropdown wf-select-dropdown mb-3">
      <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        {country?.length > 0 ? countries?.find(user => user?._id === country)?.name : "Country"}
      </a>
      <ul class="dropdown-menu">
        {countries?.length > 0 && countries?.map((c, i) => (
          <li key={i}><a onClick={() => handleCountryChange(c?._id)} className="dropdown-item">
            {c?.name}
          </a></li>
        ))}
      </ul>
    </div>
    {country?.length > 0 && (
    <div class="dropdown wf-select-dropdown mb-3">
      <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        {mycities?.length > 0 ? cities?.find(user => user?._id === mycities)?.name : "City"}
      </a>
      <ul class="dropdown-menu">
        {country && country[0] !== '' && country?.length > 0 && cities?.filter((ci) => country?.includes(ci.country_id))?.map((c, i) => (
          <li key={i}><a onClick={() => setmycities(c?._id)} className="dropdown-item">
            {c?.name}
          </a></li>
        ))}
      </ul>
    </div>
    )}
    {/* {mycities?.length > 0 && (

    <div class="dropdown wf-select-dropdown mb-3">
        <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          {myareas?.length > 0 ? areas?.find(user => user?._id === myareas)?.name : "Area"}
        </a>
        <ul class="dropdown-menu">
        {mycities && mycities[0] !== '' && country[0] !== '' && areas?.filter((ci) => mycities?.includes(ci?.city_id?._id))?.map((c, i) => (
          <li key={i}>
            <a onClick={() => setmyareas(c?._id)} className="dropdown-item">
              {c?.name}
            </a>
          </li>
        ))}
        </ul>
      </div>
    )} */}
      {mycities?.length > 0 && (<>
      <div className="d-flex">
      <div class="form-floating wf-input w-100">
      <input type="text" value={searchValue2} onChange={handleInputChange2} onBlur={handleInputBlur2} class="form-control" id="search-value" placeholder="Area" />
      <label for="search-value">Area</label>
    </div>
      {areas?.length > 0 && areas ?.filter((ci) => ci?.name?.toLowerCase().includes(searchValue2?.toLowerCase()))?.length === 0 && (
      <a onClick={() => handleOptionClick2()} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2 d-flex align-items-center">add</a>)}
      </div>

      <div className="dropdown wf-select-dropdown mb-3">
      {showDropdown2 && <ul className={`dropdown-menu show`}>
        <div className="list-autocomplete">
        {mycities?.length > 0 && country?.length > 0 &&areas?.filter((ci) => mycities.includes(ci?.city_id?._id) && ci.name.toLowerCase().includes(searchValue2?.toLowerCase()))
            .map((c, i) => (
              <li key={i}>
                <a onClick={() => handleOptionClick2(c?._id)} className="dropdown-item">
                  {c?.name}
                </a>
              </li>
            ))}

              {/* {mycities && mycities[0] !== '' && country[0] !== '' && areas?.filter((ci) => ci?.name?.toLowerCase()?.includes(searchValue2?.toLowerCase())).map((c, i) => (
            <li key={i}>
            <a onClick={() => handleOptionClick2(c?._id)} className="dropdown-item">
              {c?.name}
            </a>
          </li>
              ))} */}
          {/* {areas?.length > 0 && areas ?.filter((ci) => ci.name.toLowerCase().includes(searchValue2.toLowerCase())).length === 0 && (
                <li>
                <a className="dropdown-item">
                <i className="hasNoResults">No matching results</i>
                </a>
              </li>
            )} */}
        </div>
      </ul>}
      
    </div>
    </>)}
    </div>
    
    </div>
    <p class="font-size-26 black-color dm-font mb-2">Social Network</p>
    <div className="row gy-3 mb-3">
    <div className="col-md-4">
    <div class="form-floating wf-input">
      <input type="text" class="form-control" id="Facebook" placeholder="Facebook" defaultValue={facebook && facebook} onChange={(e) => setfacebook(e.target.value)}/>
      <label for="Facebook">Facebook</label>
    </div>
    </div>
    <div className="col-md-4">
    <div class="form-floating wf-input">
      <input type="text" class="form-control" id="X" placeholder="X" defaultValue={twitter && twitter} onChange={(e) => settwitter(e.target.value)}/>
      <label for="X">X</label>
    </div>
    </div>
    <div className="col-md-4">
    <div class="form-floating wf-input">
      <input type="text" class="form-control" id="LinkedIn" placeholder="LinkedIn" defaultValue={linkedin && linkedin} onChange={(e) => setlinkedin(e.target.value)}/>
      <label for="LinkedIn">LinkedIn</label>
    </div>
    </div>
    <div className="col-md-4">
    <div class="form-floating wf-input">
      <input type="text" class="form-control" id="Instagram" placeholder="Instagram" defaultValue={instagram && instagram} onChange={(e) => setinstagram(e.target.value)}/>
      <label for="Instagram">Instagram</label>
    </div>
    </div>
    <div className="col-md-4">
    <div class="form-floating wf-input">
      <input type="text" class="form-control" id="Youtube" placeholder="Youtube" defaultValue={youtube && youtube} onChange={(e) => setyoutube(e.target.value)}/>
      <label for="Youtube">Youtube</label>
    </div>
    </div>
    <div className="col-md-4">
    <div class="form-floating wf-input">
      <input type="text" class="form-control" id="Pinterest" placeholder="Pinterest" defaultValue={pinterest && pinterest} onChange={(e) => setpinterest(e.target.value)}/>
      <label for="Pinterest">Pinterest</label>
    </div>
    </div>
    </div>



        <p class="font-size-26 black-color dm-font mb-2">Photos & Videos</p>

        <div className="upload__img-wrap mb-3">
           {prev_images?.length>0&&prev_images.map((image, index) => (
                <div className="upload__img-box" key={index}>
                  <div className="img-bg">
                    <img src={`${process.env.REACT_APP_IMGURL}${image}`} alt={`Store Image`} className="img-bg-size" />
                    <div className="upload__img-close" onClick={(e) => handleImageRemovePrev(e, index)}></div>
                    <div className="upload__img-profile">
                    <div class="dropdown">
                      <a class=""  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <ChevronsDown />
                      </a>
                      <ul class="dropdown-menu">
                      <div class="form-check form-check-inline">
                    <input checked={dp==image?true:false} onChange={()=>setdp(image)} class="form-check-input" type="radio" name="dp" id="dp" />
                    <label class="form-check-label" for="dp">Featured Image</label>
                    </div>
                      </ul>
                    </div>
                </div>
                  </div>
                </div>
              ))}

            {images?.length>0&&images.map((image, index) => (
                <div className="upload__img-box" key={index}>
                  <div className="img-bg">
                    <img src={URL.createObjectURL(image)} alt={`Store Image`} className="img-bg-size" />
                    <div className="upload__img-close" onClick={(e) => handleImageRemove(e, index)}></div>
                    <div className="upload__img-profile">
                    <div class="dropdown">
                      <a class=""  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <ChevronsDown />
                      </a>
                      <ul class="dropdown-menu">
                      <div class="form-check form-check-inline">
                      <input checked={dp==image.name?true:false} onChange={()=>setdp(image.name)} class="form-check-input" type="radio" name="dp" id="dp" />
                    <label class="form-check-label" for="dp">Featured Image</label>
                    </div>
                      </ul>
                    </div>
                    </div>
                  </div>
                </div>
              ))}
              <input type="file" class="btn-check" id="btn-check" multiple data-max_length="20" onChange={handleImageUpload} />
              <label class="upload__btn" for="btn-check"><Camera width={18} strokeWidth={1} /> Add Photos</label>
            </div>


    </div>

    <div class="d-flex justify-content-center gap-2">
      <button onClick={()=>navigate("/vendors")} class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2">Cancel</button>
      <button onClick={saveBranch} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2" disabled={load}>{path == "new" ? "Submit" : "Update Vendor"}</button>
    </div>
    </div>
    </>
  )
}

export default QuickAddVendor
