import React from 'react';
import { useLottie, Lottie, options } from "lottie-react"
import animation from './weddified-logo.json';

// import { CircularProgress } from '@mui/material';


const Spinner = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animation,
        speed:4,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
          },
        };
      
        // extract from view by using useLottie hook and passing options as args
        const { View: lottie } = useLottie(defaultOptions);
    return (


        <div className="wf-spinner">
            {/* <CircularProgress className="progress" sx={{ color: "#FF90AA", backgroundColor: "transparent" }} /> */}
            {/* <div className="fa-3x">
                <i style={{color: "#00002E"}} className="fas fa-solid fa-spinner fa-spin-pulse"></i>
            </div>  */}
            {/* <Lottie options={options}
              height={400}
              width={400}
              speed={2}
        /> */}
        {lottie}
        </div>
        
    );
}

export default Spinner;