import React, { useMemo, useRef, useState, useEffect } from "react";
import { ArrowLeft, ArrowRight, Plus } from "lucide-react";
import JoditEditor from "jodit-react";
import {  useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment"
import {
  getAllCategories,
  getAllTags,
  getAllBlogCat,
  addBlog,
  editBlog,
  getBlogDetails
} from "../../store/slices/userSlice";
import sanitizeHtml from 'sanitize-html';
import Spinner from "../../components/Spinner";

const AddBlog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [load, setload] = useState(false);

    const editor = useRef(null);
    // const config = useMemo(
    //   () => ({
    //     askBeforePasteHTML: false,
    //     askBeforePasteFromWord: false,
    //     placeholder: "Blog Content Here...",
    //     uploader:{insertImageAsBase64URI: true}}),
    //   []
    // );

    const config = useMemo(
      () => ({
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        placeholder: "Blog Content Here...",
        uploader: { insertImageAsBase64URI: true },
        beforeProcessHtml: (html) => {
          const sanitizedHtml = sanitizeHtml(html, {
            allowedTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'b', 'i', 'strong', 'em', 'img'],
            allowedAttributes: {},
          });
  
          return sanitizedHtml;
        },
      }),
      [] 
    );
  
  
    const [id, setId] = useState("");
    const [categories, setcategories] = useState(null);
    const [blogcategories, setblogcategories] = useState(null);
    const [publishdate, setpublishdate] = useState("");
    const [title, settitle] = useState("");
    const [excerpt, setexcerpt] = useState("");
    const [category, setcategory] = useState("");
    const [blogcategory, setblogcategory] = useState("");
    const [author, setauthor] = useState("Weddified");
    const [image, setimage] = useState("");
    const [alltags, setalltags] = useState([]);
    const [mytags, setmytags] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [description, setdescription] = useState("");
    const [abc, setabc] = useState(false);



    function getCurrentDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
  }
  
  
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value.length > 0) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };


    const Categories = async () => {
      try {
        setcategories(null);
        const response = await dispatch(getAllCategories()).unwrap();
        setcategories(response?.data);
       
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    };

    const Tags = async () => {
      try {
        setalltags(null);
        const response = await dispatch(getAllTags()).unwrap();
        setalltags(response?.data);
       
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    };

    const BlogCategories = async () => {
      try {
        setblogcategories(null);
        const response = await dispatch(getAllBlogCat()).unwrap();
        setblogcategories(response?.data);
       
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    };

    const setData=async()=>{
      if(location?.state?._id){

        console.log('location.state._id', location.state._id)

        const data= await dispatch(getBlogDetails({id: location?.state?._id})).unwrap();


        setId(data?.data?._id)
        settitle(data?.data?.title)
        setpublishdate(data?.data?.publishdate)
        setcategory(data?.data?.category_id?._id)
        setblogcategory(data?.data?.blog_category_id?._id)
        setexcerpt(data?.data?.excerpt)
        setauthor(data?.data?.author)
        setdescription(data?.data?.description)
        setmytags(data?.data?.tags)
        setimage(data?.data?.image)
      }
      else{
        setId("")
        settitle("")
        setcategory("")
        setpublishdate("")
        setblogcategory("")
        setexcerpt("")
        setauthor("")
        setdescription("")
        setmytags([])
        setimage("")
      }
    }

    useEffect(() => {
      let mount = true;
      if (mount) {
        Categories();
        Tags();
        BlogCategories();
      }
      setData()
      return () => {
        mount = false;
      };
    }, []);

    const removeTagById = (_id) => {
      setmytags((prevVendors) =>
        prevVendors.filter((vendor) => vendor.tag !== _id)
      );
    };


console.log('blogcategories', blogcategories)
console.log('blogcategory', blogcategory)
console.log('category', category)
console.log('location.state', location.state)

    const addandEditBlog = async (e) => {
      e.preventDefault();
  
      try {
        setload(true);
        const formData= new FormData();
          formData.append("id", id)
          formData.append("title", title)
          formData.append("excerpt", excerpt)
          formData.append("category", category)
          formData.append("blogcategory", blogcategory)
          formData.append("tags", JSON.stringify(mytags))
          formData.append("publishdate", publishdate)
          formData.append("author", author?author:"Weddified")
          formData.append("image", image)
          formData.append("description", description)
      
        if (id) {
          await dispatch(editBlog(formData)).unwrap();
          setload(false);
          navigate('/blogs')
             
        } else { 
          await dispatch(addBlog(formData)).unwrap();    
          setload(false);  
          navigate('/blogs') 
        }
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    };

    const handleOptionClick = (option) => {
      setmytags([...mytags,{_id: option?._id?option?._id:"", tag: option}])
      setShowDropdown(false);
      setSearchValue("");
    };
        
console.log('mytags', mytags)


  return (
    <>
    {load && <Spinner />}
    <div className="wf-attributes-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb wf-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a>Blogs</a></li>
            <li class="breadcrumb-item active" aria-current="page">Add Blog</li>
        </ol>
    </nav>
    <h2 className="font-size-28 pink-color dm-font mb-3">Add Blog</h2>
    <div className="wf-radius-20 white-bg wf-shadow p-3 mb-4">
    <div class="form-floating wf-input mb-3">
      <input type="text" defaultValue={title} onChange={(e)=>settitle(e.target.value)} class="form-control" id="BLOG-NAME" placeholder="Blog Title" />
      <label for="BLOG-NAME">Blog Title</label>
    </div>
    <div class="form-floating mb-3 wf-textarea">
    <textarea class="form-control" placeholder="Excerpt" id="Excerpt" defaultValue={excerpt} onChange={(e)=>setexcerpt(e.target.value)} style={{height:'100px'}}></textarea>
    <label for="Excerpt">Excerpt</label>
    </div>
    <div className="row gy-3 mb-3">
    <div className="col-md-6">
    <div class="dropdown wf-select-dropdown">
      <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
      {blogcategory ? blogcategories?.find(user => user?._id === blogcategory)?.name : "Blog Category"}
      </a>
      <ul class="dropdown-menu">
      {blogcategories?.map((c,i)=>(
        <li key={i}><a onClick={(e)=>setblogcategory(c?._id)} class="dropdown-item">{c?.name}</a></li>
        ))}
      </ul>
    </div>
    </div>
    <div className="col-md-6">
    <div class="dropdown wf-select-dropdown">
      <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
      {category ? categories?.filter((ci) => ci?.parent_id==null)?.find(user => user?._id === category)?.name : "Category"}
      </a>
      <ul class="dropdown-menu">
      {categories?.length>0&&categories?.filter((ci) => ci?.parent_id==null)?.map((c, i)=>(
        <li key={i}><a onClick={(e)=>setcategory(c?._id)} class="dropdown-item">{c?.name}</a></li>
        ))}
      </ul>
    </div>
    </div>
    <div className="col-md-6">
    <div class="form-floating wf-input">
      <input type="file" onChange={(e)=>{setimage(e.target.files[0]);setabc(true);}} class="form-control" id="BLOG-image" placeholder="Featured Image" />
      <label for="BLOG-image">Featured Image</label>
    </div>
    </div>
    <div className="col-md-6">
    <div class="form-floating wf-input">
      <input type="date" min={getCurrentDate()} value={publishdate} onChange={(e)=>setpublishdate(e.target.value)} class="form-control" id="published-date" placeholder="Publish Date" />
      <label for="published-date">Publish Date</label>
    </div>
    </div>
    </div>
    <div className="d-flex mb-3">
    <div class="form-floating wf-input w-100">
      <input type="text" value={searchValue} onChange={handleInputChange} class="form-control" id="search-value" placeholder="Add Tags" />
      <label for="search-value">Add Tags</label>
    </div>
    <a onClick={() => handleOptionClick(searchValue)} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2 d-flex align-items-center">Add</a>
    </div>
    {showDropdown && (
            <div className="dropdown">
            <div className="dropdown-menu show" style={{ width: '100%' }}>
              <div className="list-autocomplete">
                {alltags
                  ?.filter((option) =>
                    option.tag.toLowerCase().includes(searchValue.toLowerCase())
                  )
                  .map((option, index) => (
                    <button
                      key={index}
                      type="button"
                      className="dropdown-item"
                      onClick={() => handleOptionClick(option.tag)}
                    >
                      {option.tag}
                    </button>
                  ))}
                {alltags?.length > 0 &&
                  alltags
                    ?.filter((option) =>
                      option.tag.toLowerCase().includes(searchValue.toLowerCase())
                    ).length === 0 && (
                  <i className="hasNoResults">No matching results</i>
                )}
              </div>
            </div>
            </div>
          )}
{mytags?.map((s)=>(<p className="fw-semibold mb-3"><i onClick={() => removeTagById(s.tag)} className="bi bi-x-circle cursor-pointer"></i> {s.tag}</p>))}

  <div class="mb-3"> 
    <p class="font-size-20 pink-color dm-font mb-2">Blog Content</p> 
    <JoditEditor
      ref={editor}
      value={description}
      config={config}
      tabIndex={1} 
      onBlur={(newContent) => setdescription(newContent)}
      onChange={(newContent) => setdescription(newContent)}
    />
    </div>
    {id&&(<p className="font-size-20 pink-color dm-font mb-0">Last Updated: {moment(location.state._id.updatedAt).format("DD-MM-YYYY")}</p>)}
    </div>

    <div class="d-flex justify-content-center gap-2">
      <button onClick={()=>navigate("/blogs")} class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2">Cancel</button>
      <button onClick={addandEditBlog} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2" disabled={load}>Submit</button>
    </div>
    </div>
    </>
  )
}

export default AddBlog