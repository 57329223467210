import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getProfile,
  AllQuestions,
  deleteQuestion,
  getTask,
  deleteTask,
  editTask,
} from "../../store/slices/userSlice";
import Modal from "react-modal";
import { ThumbsUp } from "lucide-react";
import { Pencil } from "lucide-react";
import { Trash2 } from "lucide-react";
const customStyles = {
  content: {
    display:"block"
  },
};
Modal.setAppElement("#root");
function Tasks() {
  const [taskCount, settaskCount] = useState(0);
  const profile = useSelector(getProfile);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [id, setId] = useState("");
  const [questions, setquestions] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();

  async function viewModal(item, type) {
    setIsOpen(true);
    console.log("=========================",item,type,"=================================")
    if (type == "userDetail") {
      setUserDetail(item);
      setId(item);
    } else if (type == "delete") {
        setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setId("");
  }


  const Approve= async (id) => {
    try {
      await dispatch(editTask({id: id, approved:1})).unwrap();
      
      try {
        Questions();
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const questionDelete = async () => {
    try {
        console.log("IDDSS ARE =>",id)
        await dispatch(deleteTask(id));
      setIsOpen(false);
    
      try {
        Questions();
      } catch (rejectedValueOrSerializedError) {
        console.log("error from here =>",rejectedValueOrSerializedError);
      }
    } catch (rejectedValueOrSerializedError) {
      console.log("error from here 2=>",rejectedValueOrSerializedError);
    }
  };

  const Questions = async () => {
    try {
      setquestions(null);
      const response = await dispatch(getTask()).unwrap();
      console.log("responsessss", response?.data);
      setquestions(response?.data);

      if (response?.data) {
        const count = response?.data.length;
        settaskCount(count);
        setquestions(response?.data);
      }

    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    let mount = true;
    if (mount) {
      Questions();
    }
    return () => {
      mount = false;
    };
  }, []);

  useEffect(() => {
    if (questions?.length > 0) {
   
    }
  }, [questions]);

  console.log("id", id);
  return (
    <>
    {profile?.systemconfig_access?.includes("write")&&(
<Modal
closeTimeoutMS={500}
isOpen={modalIsOpen}
onRequestClose={closeModal}
style={customStyles}
contentLabel="Change Password"
className={`modal custom-modal fade ${modalIsOpen&&"show"} `}
>
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        {modalType == "userDetail" ? (
    <>
      </>
      ): modalType == "delete" ? ( 
        <>
        <div class="modal-content">
                <div class="modal-body">
                    <div class="form-header">
                        <h3>Delete Task</h3>
                        <p>Are you sure you want to delete this Task?</p>
                    </div>
                    <div class="modal-btn delete-action">
                        <div class="row">
                        <div class="col-6 mb-0">
                                <a onClick={closeModal} class="btn btn-primary cancel-btn">Cancel</a>
                            </div>
                            <div class="col-6 mb-0">
                                <a  onClick={() => questionDelete(id)} class="btn btn-primary continue-btn">Delete</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        ) : (
        <></>
      )}
        </div>
 
</Modal>)}

<div className="wf-attributes-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb wf-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a>Tasks</a></li>
            <li class="breadcrumb-item active" aria-current="page">All Tasks</li>
        </ol>
    </nav>
    <div className="row gy-3 mb-4 align-items-center mb-2">
      <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
        <h2 className="font-size-28 pink-color dm-font mb-0">Tasks</h2>
      </div>
      <div className="col-md-6 order-md-2 order-3">
      </div>
      <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
        <button class="btn wf-btn wf-purple-btn px-3" onClick={()=>navigate("/add-task")}>Add Task</button>
      </div>
    </div>
    <div className="wf-radius-20 white-bg wf-shadow p-3">
    <p className="font-size-20 pink-color">Tasks : {questions?.length}</p>
    <div class="table-responsive">
    <table class="table wf-table table-borderless table-striped">
  <thead>
    <tr>
      <th scope="col">S.No</th>
      <th scope="col">Task Name</th>
      <th scope="col">Category</th>
      <th scope="col">Details</th>
      <th scope="col">SubTasks</th>
      <th scope="col">Author</th>
      <th style={{width:'100px'}} scope="col">Action</th>
  </tr>
  </thead>
  <tbody>
  {questions?.map((item,i)=> (
    <tr key={i}>
      <td>{i+1}</td>
      <td>{item?.title}</td>
      <td>{item?.category_id?.name}</td>
      <td>{item?.description}</td>
      <td>{item?.subtasks?.map((o, i) => ( <> {o?.name} </> ))}</td>
      <td>{item?.user_id?.name}</td>
      <td>
        <div className="d-flex align-items-center gap-2">
        {profile?.role=="admin"&&item?.approved==2&&(<div onClick={ () => Approve(item?._id)} className={`wf-action-icons l-blue-bg`}><ThumbsUp width="18" /></div>)}
            <div onClick={() => navigate("/add-task", { state: { _id: item,},})} className={`wf-action-icons l-blue-bg`}><Pencil width="18" /></div>
            {profile?.systemconfig_access?.includes("delete")&&(<div onClick={() => viewModal(item?._id, "delete")} className={`wf-action-icons l-blue-bg`}><Trash2 width="18" /></div>)}
        </div>
      </td>
      </tr>))}
  </tbody>
    </table>
    </div>
    </div>
</div>
    
    </>
  );
}

export default Tasks;
