import React, { useEffect, useState,useContext } from 'react'
import { context } from '../../context/context';
import {  useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminLogout, getProfile } from "../../store/slices/userSlice"
import ChangePassword from '../ChangePassword';
import Modal from 'react-modal';
import { ChevronDown } from 'lucide-react';

const customStyles = {
  content: {
    display: "block",
    '--bs-modal-width': '30%',
  },
};
Modal.setAppElement('#root');


const Nav = () => {
  const currentPath = window.location.pathname;

  // Function to extract the page name from the pathname
  // const getPageName = (pathname) => {
  //   // Remove any leading or trailing slashes
  //   const trimmedPathname = pathname.trim('/');
    
  //   // Split the pathname into parts using slashes as separators
  //   const pathParts = trimmedPathname.split('/');

  //   // Get the last part of the pathname as the page name
  //   const pageName = pathParts[pathParts.length - 1];

  //   return pageName;
  // };
  const getPageName = (pathname) => {
    if (pathname === '/') {
      return 'Dashboard';
    } else {
      const trimmedPathname = pathname.trim('/');
      const pathParts = trimmedPathname.split('/');
      const pageName = pathParts[pathParts.length - 1];
      return pageName;
    }
  };

  // Get the page name
  const pageName = getPageName(currentPath);

  const { SetToggleButton } = useContext(context);
  const [modalIsOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [togglemenu, settogglemenu] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };
  
    if (isSidebarOpen) {
      document.body.classList.remove('active');
    } else {
      document.body.classList.add('active');
    }
    

    const profile=useSelector(getProfile)  


    

    function openModal() {
      setIsOpen(true);
    }
  
    function closeModal() {
      setIsOpen(false);
    }



   
const handleLogout=async()=>{
    try {
    

        await dispatch(adminLogout()).unwrap()
        navigate("/")
      
      
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError)
      }
}


console.log('profileeeeeeeeeeeeeee', profile)


const [isScrolled, setIsScrolled] = useState(false);
useEffect(() => {
  const handleScroll = () => {
    const scrolled = window.scrollY > 10;
    setIsScrolled(scrolled);
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);




  return (
    <>
    <header className={`${isScrolled ? 'wf-navbar white-bg' : 'wf-navbar'}`}>
        <div className="row px-2 gy-4">
          <div className="col-md-6 d-md-flex align-items-center gap-5">
          <img onClick={() => navigate("/dashboard")} className="wf-logo cursor-pointer" width="230" src={`../assets/images/logo.svg`} />
            <p className="font-size-24 pink-color mb-0 fw-medium dm-font">Greetings, <span className="wf-text-highlight-navbar ">{profile?.name}</span></p>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-md-end justify-content-center" style={{ "gap": "25px" }}>
            <img class="" alt="messages" src="./assets/images/notification-icon.svg" />
            <div class="d-flex align-items-center gap-3 wf-profile-img">
              <img class="rounded-circle wf-shadow" alt="avatar" src={profile?.imageName?`${process.env.REACT_APP_APIURL}${profile?.imageName}`:"https://images.weddified.com/images/w-logo-placeholder.png"} />
              <p className="font-size-16 pink-color mb-0 fw-semibold">{profile?.name}</p>
            </div>
            <div class="dropdown wf-nav-dropdown-link">
              <a class="pink-color" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <ChevronDown />
              </a>

              <ul class="dropdown-menu">
                <li><a onClick={openModal} class="dropdown-item">Change Password</a></li>
                <li><a onClick={handleLogout} class="dropdown-item">Logout</a></li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    {/* <div class="main-header">
        <div class="d-flex">
            <div onClick={toggleSidebar} class="mobile-toggle" id="mobile-toggle">
                <i class='bx bx-menu'></i>
            </div>
            <div class="main-title">
            {pageName}
            </div>
        </div>

        <div class="d-flex align-items-center">


            <form class="app-search d-none d-lg-block">
                <div class="position-relative">
                    <input type="text" class="form-control" placeholder="Search"/>
                    <span class="bx bx-search-alt"></span>
                </div>
            </form>
            <div class="dropdown d-inline-block d-lg-none ms-2">
                <button type="button" class="btn header-item noti-icon waves-effect" id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class='bx bx-search-alt' ></i>
                    </button>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-search-dropdown">

                    <form class="p-3">
                        <div class="form-group m-0">
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username"/>
                                <div class="input-group-append">
                                    <button class="btn btn-primary h-100" type="submit"><i class="bx bx-search-alt"></i></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

             
            <div class="dropdown d-inline-block mt-12">
                <button onClick={()=>settogglemenu(!togglemenu)} type="button" class="btn header-item waves-effect" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img class="rounded-circle header-profile-user"  height="60" src={profile?.imageName?`${process.env.REACT_APP_APIURL}${profile?.imageName}`:"https://images.weddified.com/images/w-logo-placeholder.png"}
                            alt="Header Avatar"/>
                        
                        <span class="info d-xl-inline-block  color-span">
                            {profile.role!=="admin"&&profile.role!=="sub-admin"?(<><span class="d-block fs-20 font-w600">{profile?.role=="customer" && profile?.user_purpose=="groom"? profile?.name1 : profile?.role=="customer" && profile?.user_purpose=="bride"? profile?.name2: profile?.role=="customer" && profile?.user_purpose=="planner"?profile?.name:profile?.role=="vendor"?profile?.name: ""}</span>
                            <span class="d-block fs-20 font-w600" >{profile?.user_purpose=="groom"?"Groom":profile?.user_purpose=="bride"?"Bride":profile?.user_purpose=="planner"?"Event Planner": profile?.role=="vendor"?"Vendor":""}</span>
              </>):(<><span class="d-block fs-20 font-w600" >{profile?.name} <i class='bx bx-chevron-down'></i></span><span class="d-block mt-0 text-uppercase" >Super {profile?.role.toUpperCase().replace(/-/g, ' ')}</span></>)}
                        </span>
                            
                        
                    </button>
                <div class={`dropdown-menu ${togglemenu&&"show"} dropdown-menu-end`}>
                    <a onClick={openModal} class="dropdown-item cursor-pointer" ><i class="bi bi-wallet font-size-16 align-middle me-1"></i> <span>Change Password</span></a>
                    <div class="dropdown-divider"></div>
                    <a onClick={handleLogout} class="dropdown-item text-danger cursor-pointer"><i class="bi bi-power-off font-size-16 align-middle me-1 text-danger"></i> <span>Logout</span></a>
                </div>
            </div>
        </div>
    </div> */}
    {/* <div className="navbar-dasboard">
    


        <div className="dropdown-menu" id="dropdown-menu3" role="menu">
          <div className="dropdown-content">
            {profile.role!=="admin"&&profile.role!=="sub-admin"&&(<a onClick={()=>navigate(profile?.role=="customer"?"/settings":"/vendor/settings")} className="dropdown-item">
              Profile Setting
            </a>)}
            <a onClick={openModal} className="dropdown-item">
              Change Password
            </a>
            <a onClick={handleLogout} className="dropdown-item">
              Log Out
            </a>
        </div>
      </div>
  </div> */}


  <Modal
        closeTimeoutMS={500}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Change Password"
        className="modal fade show wf-modal"
      >
        <ChangePassword closeModal={closeModal} />
      </Modal>
     
      </>
  )
}

export default Nav