import React, { useState, useEffect, useRef } from "react";
import { CheckCheck, Contact, FileAudio, FileText, FileVideo, Link, PanelRight, Paperclip, Search, Send, SmilePlus, Star, X, Image, Info, UserPlus, ArrowDown } from 'lucide-react'
import { Settings, Settings2, PlusCircle, DownloadCloud } from 'lucide-react'
import Lightbox from 'react-image-lightbox';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useDispatch, useSelector } from "react-redux";
import {
  getChatMembers,
  getSocket,
  getProfile,
  saveAttachment,
  // getEventList,
  // addChatMember,
  addManualBookings,
  rejectOffer,
  rejectRequest,
  allRead
} from "../../store/slices/userSlice";
import { toast } from "react-toastify";
import moment from "moment";
import { io } from "socket.io-client";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
const customStyles = {
  content: {
    display: "block",
    "--bs-modal-width": "30%"
  },
};
Modal.setAppElement("#root");
const socket = io(process.env.REACT_APP_APIURL);

const ChatMessage = ({ currentChat, getAllChats, loadChats }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const myEvents = useSelector(getEventList);

  const [showAllImages, setShowAllImages] = useState(false);
  const [showArrowDown, setShowArrowDown] = useState(false);
  const [message, setMessage] = useState([]);
  const [chatmembers, setChatMembers] = useState([]);
  const [selectedEvent, setselectedEvent] = useState(null);
  const [requestId, setrequestId] = useState(null);
  const [price, setprice] = useState(null);
  const [initialdeposit, setinitialdeposit] = useState(null);
  const [initialdepositdate, setinitialdepositdate] = useState(null);
  const [finaldepositdate, setfinaldepositdate] = useState(null);
  const [remainingAmount, setremainingAmount] = useState(0);
  const [toggle, settoggle] = useState(false);
  const [searchText, setsearchText] = useState(false);
  const [searchClick, setsearchClick] = useState(false);
  const [load, setload] = useState(false);
  const [load1, setload1] = useState(false);


  const [toggle1, settoggle1] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const profile = useSelector(getProfile);
  // const socket = useSelector(getSocket);
  const lastMessageRef = useRef(null);
  const [messageText, setmessageText] = useState({ text: "", images: [] });
  // const [messageText, setMessageText] = useState({ text: '', emojis: [] });

  const [attachedImages, setAttachedImages] = useState([]);

  const [comments, setcomments] = useState("");
  
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  
  
  //data for acceptoffer to save temporary
  const [idd, setidd] = useState("");
  const [group_idd, setgroup_idd] = useState("");
  const [event_idd, setevent_idd] = useState("");
  const [offerpricee, setofferpricee] = useState("");
  const [initdep, setinitdep] = useState("");
  const [finaldep, setfinaldep] = useState("");
  const [initdepdate, setinitdepdate] = useState("");
  const [finaldepdate, setfinaldepdate] = useState("");
  const [store_idd, setstore_idd] = useState("");
  const [store_category_idd, setstore_category_idd] = useState("");
  const [store_namee, setstore_namee] = useState("");

  // let membersToMap=profile?.role=="customer"?myEvents:myEvents?.filter((f)=> f?.Event?._id==currentChat?.group_event?._id)


  const handleTextChange = (e) => {
    const text = e.target.value;
    setmessageText({ ...messageText, text });
  };

  function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

  const handleImageChange = (e) => {
    e.preventDefault()
    const files = e.target.files;
    const images = [...messageText.images];

    for (let i = 0; i < files.length; i++) {
      const image = files[i];
      images.push(image);
    }

    setmessageText({ ...messageText, images });
    setAttachedImages([...attachedImages, ...images]);
  };

  const handleRemoveImage = (e,index) => {
    e.preventDefault()
    const images = [...messageText.images];
    images.splice(index, 1);

    const newAttachedImages = [...attachedImages];
    newAttachedImages.splice(index, 1);

    setmessageText({ ...messageText, images });
    setAttachedImages(newAttachedImages);
  };

  function viewModal(type) {
    setIsOpen(true);
    
    setModalType(type);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleAcceptOffer = async (id, group_id, event_id, initialDepo, initialDepoDate, finalDepo, finalDepoDate, price, store_id, store_category_id, store_name, route) => {
    
    console.log('group_idddd', group_id)

    if(route!=="modal"){
      
    setidd(id)
    setgroup_idd(group_id)
    setevent_idd(event_id)
    setofferpricee(price)
    setinitdep(initialDepo)
    setinitdepdate(initialDepoDate)
    setfinaldep(price-initdep)
    setfinaldepdate(finalDepoDate)
    setstore_idd(store_id)
    setstore_category_idd(store_category_id)
    setstore_namee(store_name)

  }

  setload(true)
      const response = await dispatch(
        addManualBookings({ id: route=="modal"?idd:id, group_id: route=="modal"?group_idd:group_id, event_id: route=="modal"? event_idd:event_id, price: route=="modal"?offerpricee:price, store_id: route=="modal"?store_idd:store_id, store_category_id: route=="modal"?store_category_idd:store_category_id, store_name: route=="modal"?store_namee:store_name, remainingAmount: remainingAmount>0?remainingAmount:null, force: route=="modal"?true:false, 
        paymentStatus: route == "modal" && initdep > 0 ? "partiallypaid" : initialDepo > 0 ? "partiallypaid" : "fullypaid", 
        finalDeposit: route == "modal"? offerpricee - initdep:price-initialDepo, 
        finalDepositDate: route == "modal"?finaldepdate:finalDepoDate, 
        initialDeposit: route == "modal"?initdep:initialDepo,
        initDepositDate: route == "modal"?initdepdate:initialDepoDate})
      ).unwrap();

setload(false)
      console.log('response', response.data.remainingAmount)
      // return
      if (response.data.remainingAmount>0&&response.status==0) {
        setremainingAmount(response.data.remainingAmount)
        viewModal("add")
      }
      else{
        callMessage();
        loadChats()
        closeModal()
      }

  
  };


  const handleRejectOffer = async (id, group_id, type) => {
 
    setload1(true)
      const abc = await dispatch(
        rejectOffer({ id: id, group_id: group_id })
      ).unwrap();
      if (abc) {
        setload1(false)
        callMessage();
      }
    
  };

  const handleRejectRequest = async (id, group_id) => {

    console.log('firstdddddddd', id)
    console.log('first', group_id)
    
      const abc = await dispatch(
        rejectRequest({ id: id, groupid: group_id })
      ).unwrap();
      if (abc) {
        callMessage();
      }
    
  };

  const handleAcceptRequest = async (e, id) => {
  
    //set event details from request
    setselectedEvent(e);
    setrequestId(id);
    settoggle1(true)

  };


  let lastFoundIndex = -1; // Initialize the index of the last found message

function highlightAndScrollToMessage() {
  
  if(searchText){
    setsearchClick(false)
    const searchText = document.getElementById('search').value.toLowerCase(); 
    const messages = document.querySelectorAll('.wf-message-text');

    let messageFound = false;

   
    let startIndex = lastFoundIndex !== -1 ? lastFoundIndex - 1 : messages.length - 1;

 
    for (let i = startIndex; i >= 0; i--) {
        const messageText = messages[i].textContent.toLowerCase(); 

        if (messageText.includes(searchText)) {

            messages[i].style.backgroundColor = '#804099'; 
            messages[i].style.color = '#ffffff';
            messages[i].style.fontWeight = 'bold';
            console.log("Message highlighted:", messages[i]);
            messages[i].scrollIntoView({ behavior: 'smooth', block: 'center' }); 
            lastFoundIndex = i; 
            messageFound = true;
            break; 
        }
    }

    if (!messageFound) {
        toast.error("No occurrences found");
        lastFoundIndex = -1;
    }
  }
  else{
    setsearchClick(true)
  }
}



  const sendMessage = async (e, type) => {
    e.preventDefault();
    let response = [];

    if (type == "message") {
      if (messageText.text == "" && messageText.images.length < 1) {
        toast.error("Message is empty");
      } else {
        if (messageText?.images?.length > 0) {
          const data = new FormData();

          messageText.images.forEach((item) => {
            data.append("imageName", item);
          });

          response = await dispatch(saveAttachment(data)).unwrap();

          console.log("response33", response);
        }

        socket?.emit("send_message", {
          sender_id: currentChat?.group_membersvendor?._id,
          byAdmin: true,
          group_id: currentChat?.group_id,
          receiver_id: currentChat?.title?._id,
          message: messageText.text,
          images: attachedImages?.length>0?response?.data:null,
          messageType: "message",
          chatmembers: chatmembers,
          connection: true
        });

        // toast.success("Message sent successfully");

        setmessageText({ text: "", images: [] });
        setAttachedImages([]);
      }
    } else if (type == "send_req") {
      if (selectedEvent == null) {
        toast.error("Please select an event");
      } else {
        socket?.emit("send_message", {
          sender_id: currentChat?.group_membersvendor?._id,
          group_id: currentChat?.group_id,
          receiver_id: currentChat?.title?._id,
          event_id: selectedEvent?._id,
          byAdmin: true,
          comments: comments,
          messageType: "request",
          chatmembers: chatmembers,
          connection: true
        });

        toast.success("Request sent successfully");

        setselectedEvent({});
        settoggle1(false);
        setmessageText("");
      }
    } else if (type == "send_offer") {

      console.log('priceee', price,initialdeposit)
      if (selectedEvent == null) {  
        toast.error("Please select an event");
      }
      else if(parseInt(initialdeposit)>parseInt(price)){
        toast.error("Initial deposit should be less than the total price");
      }

      else if(parseInt(price - initialdeposit)+parseInt(initialdeposit)>parseInt(price)){
        toast.error("Price cannot be greater then total price")
      }

      else {
        socket?.emit("send_message", {
          id: requestId, //for change request status to accept i.e "1"
          sender_id: currentChat?.group_membersvendor?._id,
          group_id: currentChat?.group_id,
          byAdmin: true,
          receiver_id: currentChat?.title?._id,
          request_id: requestId,
          event_id: selectedEvent?._id,
          messageType: "offer",
          connection: true,
          price: {
            totalPrice: parseInt(price),
            initialDeposit: parseInt(initialdeposit),
            initialDepositDate: initialdepositdate,
            finalDeposit: price - initialdeposit,
            finalDepositDate: finaldepositdate,
            paymentMode: "cash"
          },
          comments: comments
        });

        toast.success("Offer sent successfully");
        setselectedEvent({});
        settoggle1(false);
        setmessageText("");
      }
    } else {
    }
  };

  // const handleFileAttachment = async (e) => {

  //   const data = new FormData();

  //     data.append("imageName", e.target.files);

  //   const response = await dispatch(
  //     saveAttachment(data)
  //   ).unwrap();

  //   console.log('response2222222', response)

  // }


// console.log('membersToMap', membersToMap)

  const getChatMember = async (groupid) => {
    try {
      setChatMembers(null);
      const response = await dispatch(
        getChatMembers({ groupid: groupid })
      ).unwrap();
      console.log("response111", response);
      setChatMembers(response?.data);

      // console.log("myEvents", myEvents);
      console.log("response.data", chatmembers);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

 

  const callMessage = () => {
    socket?.emit("get_messages", {
      group_id: currentChat?.group_id,
      
    });

    socket?.on("response", async (data) => {
      console.log("ajeebsa", data);
      if (data?.object_type == "get_messages") {
        console.log("dataaaaaaaaaaaa", data);
        setMessage(data?.data);
      } else if (data?.object_type == "get_message") {
        console.log("dataaaaaaaaaaaassss", data);
        if (!message?.find((arr) => arr._id === data?.data[0]?._id)) { //&&data?.data[0].group_id==currentChat?.group_id
          // getAllChats(data?.data[0]);
          if(data?.data[0]?.group_id==currentChat?.group_id){
            setMessage((prevMessages) => [...prevMessages, data?.data[0]]);
            await dispatch(allRead({groupid: currentChat?.group_id})).unwrap();
            setCurrentMessage("");
          }
        } else {
          setMessage(message);
        }
      }
    });
  };

  useEffect(() => {
    getChatMember(currentChat?.group_id);
    callMessage();
    return () => socket?.off("response");
  }, [socket, currentChat?.group_id]);

  // useEffect(() => {
  //   if (currentChat) {
  //     lastMessageRef.current.scrollTop = lastMessageRef?.current?.scrollHeight;
  //   }
  // }, [message, currentChat?.group_id, toggle1]);


  function scrollToBottom(element, duration) {
    const start = element.scrollTop;
    const end = element.scrollHeight - element.clientHeight;
    const distance = end - start;
    const startTime = performance.now();

    function scroll(timestamp) {
        const elapsed = timestamp - startTime;
        const progress = Math.min(elapsed / duration, 1);
        element.scrollTop = start + distance * progress;

        if (progress < 1) {
            requestAnimationFrame(scroll);
        }
    }

    requestAnimationFrame(scroll);
  }

  

  useEffect(() => {
    if (searchClick) {
      scrollToBottom(lastMessageRef.current, 500);
    }
  }, [searchClick]);

  console.log('socket', socket)

  console.log("currentChat", currentChat);
  // console.log("myEvents", myEvents);
  console.log("selectedEvent", selectedEvent);
  console.log("toggle1", toggle1);
  console.log("message", message);
  console.log("chatmembers", chatmembers);
  console.log("messageTExt", messageText);


  const [sidepanel, setsidepanel] = useState(false);
  const [searchtoggle, setsearchtoggle] = useState(false);
  const [toggle2, settoggle2] = useState(false);
  const [RequestOffer, setRequestOffer] = useState(false);
  const [AcceptOffer, setAcceptOffer] = useState(false);
  const [photoIndex, setphotoIndex] = useState(0)
  const [videoIndex, setvideoIndex]=useState(0)
  const [isOpen, setisOpen]=useState()
  const [photoFiles, setPhotoFiles] = useState([]);
  const [otherFile, setOtherFile] = useState(null);
  
  const handleFileChange = (event, fileType) => {
    const selectedFile = event.target.files[0];
  
    if (fileType === 'image') {
      setPhotoFiles((prevFiles) => [...prevFiles, ...event.target.files]);
    } else {
      setOtherFile(selectedFile);
    }
  };
  
  // Map file extensions to corresponding icons
  const fileIcons = {
    image: <Image width={22} height={22} stroke={"#3A5CD6"} />,
    document: <FileText width={22} height={22} stroke={"#3A5CD6"} />,
    audio: <FileAudio width={22} height={22} stroke={"#3A5CD6"} />,
    video: <FileVideo width={22} height={22} stroke={"#3A5CD6"} />,
  };
  
  const getFileType = (file) => {
    const extension = file.name.split('.').pop().toLowerCase();
  
    if (extension.match(/(jpg|jpeg|png|gif|bmp)$/)) {
      return 'image';
    } else if (extension.match(/(doc|docx|ppt|pptx|txt|pdf|xls|xlsx)$/)) {
      return 'document';
    } else if (extension.match(/(mp3)$/)) {
      return 'audio';
    } else if (extension.match(/(mp4|mov|avi|mkv)$/)) {
      return 'video';
    } else {
      return 'other';
    }
  };
  
  console.log(toggle1,"toggle1");
  
  // const [selectedEmojis, setSelectedEmojis] = useState([]);
  //     const handleEmojiSelect = (emoji) => {
  //       setSelectedEmojis((prevEmojis) => [...prevEmojis, emoji.native]);
  // };

  // const handleEmojiSelect = (emoji) => {
  //   setmessageText((prevMessageText) => {
  //     // Check if the emoji is already present
  //     if (!prevMessageText.emojis.includes(emoji.native)) {
  //       // Add the emoji only if it's not present
  //       return {
  //         ...prevMessageText,
  //         emojis: [...prevMessageText.emojis, emoji.native],
  //       };
  //     }
  //     // If the emoji is already present, return the previous state
  //     return prevMessageText;
  //   });
  // };
  
  const fileUrl = 'https://images.weddified.com/images/accessories_banner.jpg';
  
  const handleDownload = async () => {
      try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
  
        // Create a link and set its href to the Blob
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'file.jpg'; // Specify the desired file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading the file:', error);
      }
    };
  
    


    useEffect(() => {
      const handleScroll = () => {
        if (lastMessageRef.current) {
          const { scrollTop, scrollHeight, clientHeight } = lastMessageRef.current;
          setShowArrowDown(scrollTop < scrollHeight - clientHeight); 
        }
      };
  
      if (lastMessageRef.current) {
        lastMessageRef.current.addEventListener("scroll", handleScroll);
      }
  
      return () => {
        // Cleanup the event listener when the component unmounts
        if (lastMessageRef.current) {
          lastMessageRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }, []);
  
    const scrollToLastMessage = () => {
      if (lastMessageRef.current) {
        lastMessageRef.current.scrollTop = lastMessageRef.current.scrollHeight;
      }
    };
  
    const handleArrowDownClick = () => {
      scrollToLastMessage();
    };

  return (
    <>

      <div className="position-relative">

        <div className="row mb-4">
          <div className="col-12">
            <div className="d-flex flex-sm-row flex-column flex-wrap justify-content-between gap-3 gap-md-5">

            {chatmembers?.planner && profile?.role!=="planner" ? (<>
                        <div class="flex-grow-1 d-flex gap-2 align-items-center">
                      <img src={ chatmembers?.planner?.imageName ? `${process.env.REACT_APP_IMGURL}${chatmembers?.planner?.imageName}`
                        : "https://via.placeholder.com/50x50"} class="rounded-circle wf-user-image" alt={chatmembers?.planner?.name} width="40" height="40"/>
                      <div className="flex-grow-1">
                      <p className="font-size-16 purple-color mb-0 fw-bold">{chatmembers?.planner?.name}</p>
                      <p className="font-size-14 pink-color mb-0 fw-semibold">{t('WeddingPlanner')}</p>
                      </div>
                      </div>
                        </>
                      ) : null}

              <div className="wf-up-avatar d-flex mb-2"><div className="avatar-couple-first">
                <img width="57" height="57" alt=""  src={currentChat?.group_membersperson1?.imageName?`${process.env.REACT_APP_IMGURL}${currentChat?.group_membersperson1?.imageName}`:"https://via.placeholder.com/50x50"} className="rounded-circle avatar" style={{ border: '3px solid #804099', objectFit: 'cover' }} />
              </div>
                <div className="avatar-couple-sec" style={{ marginLeft: "-30px" }}>
                  <img width="57" height="57" alt="" src={currentChat?.group_membersperson2?.imageName?`${process.env.REACT_APP_IMGURL}${currentChat?.group_membersperson2?.imageName}`:"https://via.placeholder.com/50x50"} className="rounded-circle avatar" style={{ border: '3px solid #804099', objectFit: 'cover' }} />
                </div>
                <div className="ms-2">
                  <p className="font-size-16 black-color mb-0">Couple</p>
                  <p className="font-size-12 purple-color mb-0 fw-medium">{currentChat?.group_membersperson1?.name||"partner"} & {currentChat?.group_membersperson2?.name||"partner"}</p>
                </div>
              </div>

              <div className="d-flex flex-md-row flex-column gap-2 gap-md-4">
                <div className="wf-up-avatar d-flex">
                  <div className="avatar-couple-first">
                    <img width="57" height="57" alt="" src={ currentChat?.vendor_store?.image ? `${process.env.REACT_APP_IMGURL}${currentChat?.vendor_store?.image}` : "https://via.placeholder.com/50x50"} className="rounded-circle avatar" style={{ border: '3px solid #804099', objectFit: 'cover' }} />
                  </div>
                </div>
                <div>
                  <h3 className="font-size-16 fw-bold purple-color mb-0">{currentChat?.vendor_store?.store_name}</h3>
                  <p className="font-size-12 fw-normal color-grey mb-0 text-capitalize">
                    <span>{currentChat?.group_membersvendor?.user_purpose}</span>
                  </p>
                 
                </div>
              </div>

            </div>
          </div>
        </div>


        {message?.map((c, i) =>
                c?.messageType == "message" ? (
                  <>
                  
                    <div className={ c?.sender_id?.createdByAdmin == true ? `wf-chat-message-right` : `wf-chat-message-left`}>
                    <img src={c?.sender_id?.imageName?`${process.env.REACT_APP_IMGURL}${c?.sender_id?.imageName}`:"https://via.placeholder.com/50x50"} class="rounded-circle wf-message-user-image" alt={profile?.name}/>
                    <div class="flex-shrink-1">
                    {c?.sender_id?.createdByAdmin == true ? (
                      <p className="font-size-14 mb-0 wf-message-user-name">
    <b>{c?.sender_id?.name}</b>
    {c?.sender_id?.role !== "vendor" && (
      <>&nbsp;<span className="color-black text-capitalize">{c?.sender_id?.user_purpose}</span></>
    )}
  </p>
                    ) : (
                      <p className="font-size-14 mb-0 wf-message-user-name"><b>{c?.sender_id?.name}</b> {c?.sender_id?.role !== "vendor" && (
                        <>&nbsp; <span className="color-black text-capitalize">{c?.sender_id?.user_purpose}</span></>
                        )}</p>
                    )}
                      <div className="wf-message-text ">
  
                      {/* {c?.images?.length > 0 &&<div className="d-flex flex-wrap gap-2 wf-message-images">
                      {c?.images?.map((image) => (
                      <div className="wf-message-image">
                      <img src={`${process.env.REACT_APP_IMGURL}${image}`} alt="media"/>
                      </div>
                      ))}
                      <div onClick={() => setisOpen(true)} className="wf-message-image wf-image-count"><span>+5</span></div>
                      {isOpen && (
                          <Lightbox
                          mainSrc="https://images.unsplash.com/photo-1580489944761-15a19d654956"
                          nextSrc="https://images.unsplash.com/photo-1580489944761-15a19d654956"
                          prevSrc="https://images.unsplash.com/photo-1580489944761-15a19d654956"
                          onCloseRequest={() => setisOpen(false)}
                          // onMovePrevRequest=""
                          // onMoveNextRequest=""
                          />
                      )}
                      </div>} */}
                      {c?.images?.length > 0 && (
                      <div className="d-flex flex-wrap gap-2 wf-message-images">
                        {c?.images?.slice(0, 4)?.map((image, index) => (
                          <div key={index} className="wf-message-image">
                            <img src={`${process.env.REACT_APP_IMGURL}${image}`} alt={`media-${index}`} />
                          </div>
                        ))}
                        {c?.images.length > 4 && (
                          <div onClick={() => setisOpen(true)} className="wf-message-image wf-image-count">
                            <span>+{c?.images.length - 4}</span>
                          </div>
                        )}
                        {/* {isOpen && (
                          <Lightbox
                            mainSrc={`${process.env.REACT_APP_IMGURL}${c?.images[0]}`}
                            nextSrc={`${process.env.REACT_APP_IMGURL}${c?.images[0]}`}
                            prevSrc={`${process.env.REACT_APP_IMGURL}${c?.images[0]}`}
                            onCloseRequest={() => setisOpen(false)}
                          />
                        )} */}
  
                      {isOpen && (
                        <Lightbox
                          mainSrc={`${process.env.REACT_APP_IMGURL}${c?.images[photoIndex]}`}
                          nextSrc={
                            c?.images[
                              (photoIndex + 1) % c?.images?.length
                            ]
                          }
                          prevSrc={
                            c?.images[
                              (photoIndex + c?.images?.length - 1) %
                                c?.images?.length
                            ]
                          }
                          onCloseRequest={() => setisOpen(false)}
                          onMovePrevRequest={() =>
                            setphotoIndex(
                              (photoIndex + c?.images?.length - 1) %
                                c?.images?.length
                            )
                          }
                          onMoveNextRequest={() =>
                            setphotoIndex(
                              (photoIndex + 1) % c?.images?.length
                            )
                          }
                        />
                      )}
  
                      </div>
                    )}
                      {/* {c?.images?.length > 0 ? c?.images?.map((image) => (
                        <div className="img-bg-size">
                          <img
                          
                            src={`${process.env.REACT_APP_IMGURL}${image}`}
                            
                          />
                        </div>
                        ))
                      : null} */}
                      {c?.message}
                      </div>
                      <div className="d-flex gap-3 align-items-center wf-text-bottom">
                      <p className="font-size-10 mb-0 color-black">{moment(c?.createdAt).format("dddd, MMMM D, YYYY [at] HH:mm")}</p>
                      {c?.sender_id?._id === profile?._id&&(<p className="font-size-10 mb-0 wf-seen-tick"><CheckCheck width="10" height="10" /></p>)}
                      </div>
                  </div>
                    </div>
                  </>
                ) : c?.messageType == "request" ? (
                  <>
                  <div id={c?.sender_id?.createdByAdmin == true ? `wf-chat-message-right` : `wf-chat-message-left`}>
              <img src={c?.sender_id?.imageName?`${process.env.REACT_APP_IMGURL}${c?.sender_id?.imageName}`:"https://via.placeholder.com/50x50"} class="rounded-circle wf-message-user-image" alt={profile?.name}/>
                  <div class="flex-shrink-1">
                  {c?.sender_id?._id === profile?._id ? (
                      <p className="font-size-14 mb-0 wf-message-user-name"><b>{profile?.name}</b> {c?.sender_id?.role !== "vendor" && (
                        <>&nbsp; <span className="color-black text-capitalize">{profile?.user_purpose}</span></>
                        )}</p>
                    ) : (
                      <p className="font-size-14 mb-0 wf-message-user-name"><b>{c?.sender_id?.name}</b> {c?.sender_id?.role !== "vendor" && (
                        <>&nbsp; <span className="color-black text-capitalize">{c?.sender_id?.user_purpose}</span></>
                        )}</p>
                    )}
                      <div className="wf-message-text">
                      <div className="d-flex flex-wrap mb-2">
                          <div className="col-md-4">
                          <p className="font-size-16 purple-color mb-0">{t('Event')} {t('Name')}: <span className="color-black">{c?.request?.event_id?.event_name}</span></p>
                          </div>
                          <div className="col-md-4">
                          <p className="font-size-16 purple-color mb-0">{t('Guests')}: <span className="color-black">{c?.request?.event_id?.no_of_guests}</span></p>
                          </div>
                          <div className="col-md-4">
                          <p className="font-size-16 purple-color mb-0">{t('Date')}: <span className="color-black">{moment(c?.request?.event_id?.event_date).format("DD-MM-YYYY")}</span></p>
                          </div>
                      </div>
                      <p className="font-size-16 purple-color mb-1">{t('Request')} {t('Details')}:</p>
                      <p className="font-size-16 color-black mb-2">{c?.request?.comments}</p>
                      {c?.request?.request_status==2&&profile?.role=="vendor"||profile?.role=="planner"&&profile?._id==currentChat?.group_membersvendor?._id?(
                      <div className="d-flex justify-content-end gap-3">
                      <p onClick={() => handleAcceptRequest(c?.request?.event_id, c?.request?._id,)} className="font-size-16 purple-color mb-0 cursor-pointer"><u>Accept</u></p>
                      <p onClick={() => handleRejectRequest( c?.request?._id, c?.group_id?._id,)} className="font-size-16 pink-color mb-0 cursor-pointer"><u>Decline</u></p>
                      </div>):c?.request?.request_status==1?(<p className="font-size-16 purple-color mb-2 mt-1 text-end">{`Offer Accepted On ${moment(c?.updatedAt).format("dddd, MMMM D, YYYY [at] h:mm A")}`}</p>)
                      :c?.request?.request_status==0?(<p className="font-size-16 purple-color mb-2 mt-1 text-end">{`Offer Rejected On ${moment(c?.updatedAt).format("dddd, MMMM D, YYYY [at] h:mm A")}`}</p>):null
                    }
                      </div>
                      <div className="d-flex gap-3 align-items-center wf-text-bottom">
                      <p className="font-size-10 mb-0 color-black">{moment(c?.createdAt).format("dddd, MMMM D, YYYY [at] HH:mm")}</p>
                      {c?.sender_id?._id === profile?._id&&(<p className="font-size-10 mb-0 wf-seen-tick"><CheckCheck width="10" height="10" /></p>)}
                      </div>
                  </div>
              </div>
                  </>
                ) : c?.messageType == "offer" ? (
                  <>
                  <div id={profile?.role == "customer" ||profile?.role=="planner"&&profile?._id==currentChat?.group_membersplanner?._id? "offer-received": "offer-sent"} className={ c?.sender_id?._id == profile?._id ? `wf-chat-message-right` : `wf-chat-message-left`}>
              <img src={c?.sender_id?.imageName?`${process.env.REACT_APP_IMGURL}${c?.sender_id?.imageName}`:"https://via.placeholder.com/50x50"} class="rounded-circle wf-message-user-image" alt={profile?.name}/>
                  <div class="flex-shrink-1">
                  {c?.sender_id?._id === profile?._id ? (
                      <p className="font-size-14 mb-0 wf-message-user-name"><b>{profile?.name}</b> {c?.sender_id?.role !== "vendor" && (
                        <>&nbsp; <span className="color-black text-capitalize">{profile?.user_purpose}</span></>
                        )}</p>
                    ) : (
                      <p className="font-size-14 mb-0 wf-message-user-name"><b>{c?.sender_id?.name}</b> {c?.sender_id?.role !== "vendor" && (
                        <>&nbsp; <span className="color-black text-capitalize">{c?.sender_id?.user_purpose}</span></>
                        )}</p>
                    )}
                      <div className="wf-message-text">
                      <div className="d-flex flex-wrap mb-2">
                          <div className="col-md-6">
                          <p className="font-size-16 purple-color mb-0">{t('TotalPrice')}: <span className="color-black">SAR {c?.offer?.price?.totalPrice}</span></p>
                          </div>
                          <div className="col-md-6">
                          <p className="font-size-16 purple-color mb-0">{t('Initial Deposit')}: <span className="color-black">SAR {c?.offer?.price?.initialDeposit}</span></p>
                          </div>
                          <div className="col-md-6">
                          <p className="font-size-16 purple-color mb-0">{t('Deposit')} {t('Date')}: <span className="color-black">{c?.offer?.price?.initialDepositDate?moment(c?.offer?.price?.initialDepositDate).format("DD-MM-YYYY"):""}</span></p>
                          </div>
                          <div className="col-md-6">
                          <p className="font-size-16 purple-color mb-0">{t('Final Deposit')}: <span className="color-black">SAR {c?.offer?.price?.finalDeposit}</span></p>
                          </div>
                          <div className="col-md-6">
                          <p className="font-size-16 purple-color mb-0">{t('Final Deposit')} {t('Date')}: <span className="color-black">{c?.offer?.price?.finalDepositDate?moment(c?.offer?.price?.finalDepositDate).format("DD-MM-YYYY"):""}</span></p>
                          </div>
                      </div>
                      <p className="font-size-16 purple-color mb-1">{t('Offer')} {t('Details')}:</p>
                      <p className="font-size-16 color-black mb-2">{c?.offer?.comments}</p>
                      {profile?.role == "customer" ||profile?.role=="planner"&&profile?._id==currentChat?.group_membersplanner?._id ? (
                        <div className="d-flex justify-content-end gap-3">
                          {c?.offer?.request_status == 2 ? (
                            <>
                              <p onClick={() => handleAcceptOffer( c?.offer?._id, c?.group_id?._id, c?.offer?.event_id?._id, c?.offer?.price?.initialDeposit , c?.offer?.price?.initialDepositDate , c?.offer?.price?.finalDeposit, c?.offer?.price?.finalDepositDate,  c?.offer?.price?.totalPrice, currentChat?.vendor_store?._id, currentChat?.vendor_store?.prefered_services[0], currentChat?.vendor_store?.store_name, "")}
                           className="font-size-16 purple-color mb-0 cursor-pointer">{load ? (
                            <div className="spinner-border wf-spinner-border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          ) : (<u>{t('Accept')}</u>)}</p>
                           <p onClick={() => handleRejectOffer( c?.offer?._id, c?.group_id )} className="font-size-16 pink-color mb-0 cursor-pointer">{load1 ? (
            <div className="spinner-border wf-spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (<u>{t('Decline')}</u>)}</p>
                            </>
                          ) : c?.offer?.request_status == 3 ? (
                            <>
                              <p className="font-size-16 purple-color mb-0">{t('Another offer has been accepted')}</p>
                            </>
                          ) : c?.offer?.request_status == 1 ? (
                            <>
                            <p className="font-size-16 purple-color mb-0">{t('This offer has been accepted')}</p>
                            </>
                          ) : null}
                        </div>
                      ) : c?.offer?.request_status==1?(<p className="font-size-16 purple-color mb-0 text-end">{`Offer Accepted On ${moment(c?.updatedAt).format("dddd, MMMM D, YYYY [at] h:mm A")}`}</p>)
                      :c?.offer?.request_status==0?(<p className="font-size-16 purple-color mb-0 text-end">{`Offer Rejected On ${moment(c?.updatedAt).format("dddd, MMMM D, YYYY [at] h:mm A")}`}</p>):null}
                      </div>
                      <div className="d-flex gap-3 align-items-center wf-text-bottom">
                      <p className="font-size-10 mb-0 color-black">{moment(c?.createdAt).format("dddd, MMMM D, YYYY [at] HH:mm")}</p>
                      {c?.sender_id?._id === profile?._id&&(<p className="font-size-10 mb-0 wf-seen-tick"><CheckCheck width="10" height="10" /></p>)}
                      </div>
                  </div>
              </div>
                  </>
                ) : null
              )}

          {/* <div className="wf-chat-message-left">
            <div className="flex-shrink-1">
              <p className="font-size-14 mb-0 wf-message-user-name d-flex">
                <span className="d-inline-block text-truncate" style={{ width: "120px" }}>Test-Name-Mav</span> (By Admin)</p>
              <div className="wf-message-text ">hi</div>
              <div className="d-flex gap-3 align-items-center wf-text-bottom">
                <p className="font-size-10 mb-0 black-color">Wednesday, August 14, 2024 at 11:06</p>
                <p className="font-size-10 mb-0 wf-seen-tick">
                  <CheckCheck width="10" height="10" />
                </p>
              </div>
            </div>
          </div> */}

          {/* <div id="sent-request" className="wf-chat-message-right">
            <div className="flex-shrink-1">
              <div className="wf-message-text">
                <div className="d-flex flex-wrap mb-2">
                  <div className="col-md-4">
                    <p className="font-size-16 purple-color mb-0">Event Name: <span className="black-color">Test Event 04</span></p>
                  </div>
                  <div className="col-md-4">
                    <p className="font-size-16 purple-color mb-0">Guests: <span className="black-color">Less than 50</span></p>
                  </div>
                  <div className="col-md-4">
                    <p className="font-size-16 purple-color mb-0">Date: <span className="black-color">01-01-2001</span></p>
                  </div>
                </div>
                <p className="font-size-16 purple-color mb-1">Request Details:</p>
                <p className="font-size-16 black-color mb-2">Test 01</p>
              </div>
              <div className="d-flex gap-3 align-items-center wf-text-bottom">
                <p className="font-size-10 mb-0 black-color">Monday, August 12, 2024 at 15:15</p>
                <p className="font-size-10 mb-0 wf-seen-tick">
                  <CheckCheck width="10" height="10" />
                </p>
              </div>
            </div>
          </div> */}


          {/* <div id="offer-received" className="wf-chat-message-left">
            <div className="flex-shrink-1">
              <p className="font-size-14 mb-0 wf-message-user-name d-flex">
                <span className="d-inline-block text-truncate" style={{ width: "120px" }}>Test-Name-Mav</span> (By Admin)</p>
              <div className="wf-message-text">
                <div className="d-flex flex-wrap mb-2">
                  <div className="col-md-6">
                    <p className="font-size-16 purple-color mb-0">Total Price: <span className="black-color">SAR 1500</span></p>
                  </div>
                  <div className="col-md-6">
                    <p className="font-size-16 purple-color mb-0">Initial Deposit: <span className="black-color">SAR 700</span></p>
                  </div>
                  <div className="col-md-6">
                    <p className="font-size-16 purple-color mb-0">Deposit Date: <span className="black-color">15-08-2024</span></p>
                  </div>
                  <div className="col-md-6">
                    <p className="font-size-16 purple-color mb-0">Final Deposit: <span className="black-color">SAR 800</span>
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p className="font-size-16 purple-color mb-0">Final Deposit Date: <span className="black-color">16-08-2024</span></p>
                  </div>
                </div>
                <p className="font-size-16 purple-color mb-1">Offer Details:</p>
                <p className="font-size-16 black-color mb-2">Test</p>
                <div className="d-flex justify-content-end gap-3">
                  <p className="font-size-16 purple-color mb-0 cursor-pointer"><u>Accept</u></p>
                  <p className="font-size-16 pink-color mb-0 cursor-pointer"><u>Decline</u></p>

                  <p className="font-size-16 purple-color mb-0">This offer has been accepted</p>
                </div>
              </div>
              <div className="d-flex gap-3 align-items-center wf-text-bottom">
                <p className="font-size-10 mb-0 black-color">Wednesday, August 14, 2024 at 10:45</p>
                <p className="font-size-10 mb-0 wf-seen-tick">
                  <CheckCheck width="10" height="10" />
                </p>
              </div>
            </div>
          </div> */}


        {/* </div> */}

        <div className="py-2 px-md-4 wf-shadow-2 rounded wf-write-message mb-1 position-relative">
          {/* <div className="wf-upload-box grey-bg rounded">
            <div key={""} className="wf-uploaded-image-size">
              <img src={""} alt={""} />
              <div className="crox-icon">
                <X width="18" height="18" />
              </div>
            </div>
          </div> */}

          <form action="#" onSubmit={(e) => {e.preventDefault(); sendMessage(e, "message"); }} method="get" accept-charset="utf-8" className="d-flex">
            <textarea value={messageText.text} onChange={(e) => handleTextChange(e)}  onKeyDown={(e) => {if (e.key === 'Enter') { e.preventDefault();  sendMessage(e, "message");} }} className="form-control" placeholder="Type your message here" style={{ boxShadow: 'none', border: 0 }}></textarea>
            <div className="d-flex justify-content-between">

              {/* <div className="d-flex align-items-center gap-3">
                <div className="dropdown wf-chat-dropdown">
                  <div className="purple-color cursor-pointer" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <PlusCircle width="22" height="22" />
                  </div>

                  <div className="dropdown-menu">
                    <div className="d-flex align-items-center gap-2 mb-3">
                      <div className="pink-color">
                        <Paperclip width="22" height="22" />
                      </div>
                      <p className="font-size-14 pink-color mb-0"><u>Attachment</u></p>
                    </div>
                    <div className="d-flex align-items-center gap-3 px-2">
                      <label for="doc-attachment" className="d-flex flex-column align-items-center gap-2">
                        <FileText width={22} height={22} stroke={"#B1B1B1"} />
                        <p style={{ color: '#B1B1B1' }} className="font-size-12 mb-0">Doc</p>
                      </label>

                      <label for="gallery-attachment" className="d-flex flex-column align-items-center gap-2 cursor-pointer">
                        <Image width={22} height={22} stroke={"#804099"} />
                        <p className="font-size-12 purple-color mb-0">Gallery</p>
                      </label>
                      <input multiple type="file" className="btn-check" id="gallery-attachment" accept="image/*" />

                      <label for="audio-attachment" className="d-flex flex-column align-items-center gap-2 ">
                        <FileAudio width={22} height={22} stroke={"#B1B1B1"} />
                        <p style={{ color: '#B1B1B1' }} className="font-size-12 mb-0">Audio</p>
                      </label>

                      <label for="video-attachment" className="d-flex flex-column align-items-center gap-2 ">
                        <FileVideo width={22} height={22} stroke={"#B1B1B1"} />
                        <p style={{ color: '#B1B1B1' }} className="font-size-12 mb-0">Video</p>
                      </label>

                      <div className="d-flex flex-column align-items-center gap-2">
                        <Contact width={22} height={22} stroke={"#B1B1B1"} />
                        <p style={{ color: '#B1B1B1' }} className="font-size-12 mb-0">Contact</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <button type="submit" className="purple-color cursor-pointer wf-message-send-btn">
                <Send  width="22" height="22" />
              </button>
            </div>
          </form>
        </div>

      </div >

    </>
  )
};

export default ChatMessage;