import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { extraReducers } from "../reducer/userReducer";

axios.defaults.baseURL = process.env.REACT_APP_APIURL;
const user = JSON.parse(localStorage.getItem("user"))
  ? JSON.parse(localStorage.getItem("user"))
  : null;
console.log("The USER IS ========================>>>>>>>>>>>>>>>>>>>>>>>>>>",user);
axios.defaults.headers.common["Authorization"] = `Bearer ${user?.token}`;
// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NGUyZWViMWRiNjUyNTM5YzkzYjAxOGQiLCJpYXQiOjE2OTQ1ODQ2NjV9.4az4u7jbSPJm6-N4zF_3THTZocpVhKqtPHmY8cjAFSA
const initialState = {
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  user: user,
  terms: null,
  privacy: null,
  dashboard: null,  
  allTasks:null
};



export const signinAdmin = createAsyncThunk(
  "admin/signin",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/signin`, bodyData);
      console.log("response :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getChats = createAsyncThunk(
  "admin/chatlist",
  async (bodyData, { rejectWithValue }) => {
    console.log("rsasa ");

    try {
      const response = await axios.get(`admin/chatlist`);
      console.log("response :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getChatMembers = createAsyncThunk(
  "user/getchatmembers",
  async (data, { rejectWithValue }) => {
    try {
      console.log("datasassaas", data);
      const response = await axios.post(`user/getchatmembers`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveAttachment = createAsyncThunk(
  "userdashboard/saveattachment",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log('bodyData', bodyData)
      const response = await axios.post(`userdashboard/saveattachment`, bodyData);
      console.log("response of ADD EVENT :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const allRead = createAsyncThunk(
  "admin/allread",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/allread`, bodyData);
      console.log("response of ADD EVENT :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addManualBookings = createAsyncThunk(
  "userdashboard/addBooking",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`userdashboard/addBooking`, bodyData);
      console.log("response of ADD EVENT :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const rejectOffer = createAsyncThunk(
  "userdashboard/rejectoffer",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`userdashboard/rejectoffer`, bodyData);
      console.log("response of ADD EVENT :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const rejectRequest = createAsyncThunk(
  "userdashboard/rejectrequest",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log("sasasasasasasasasasa");
      const response = await axios.post(
        `userdashboard/rejectrequest`,bodyData
      );
      console.log("sasasasasa", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const updatePasswordAdmin = createAsyncThunk(
  "admin/changepassword",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.post(`admin/changepassword`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getAllCategories = createAsyncThunk(
  "admin/getallcategories",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.get(`admin/getallcategories`);
      console.log("sasasasasa",response);     
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllTags = createAsyncThunk(
  "admin/getalltags",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.get(`admin/getalltags`);
      console.log("sasasasasa",response);     
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllEthnics = createAsyncThunk(
  "admin/getallethnics",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.get(`admin/getallethnics`);
      console.log("sasasasasa",response);     
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllUsers = createAsyncThunk(
  "admin/allusers",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.get(`admin/allusers`);
      console.log("sasasasasa",response);     
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllStores = createAsyncThunk(
  "admin/getallstores",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.get(`admin/getallstores`);
      console.log("sasasasasa",response);     
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStoreDetails = createAsyncThunk(
  "admin/getstoredetails",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.post(`admin/getstoredetails`,bodyData);
      console.log("sasasasasa",response);     
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllBlogCat = createAsyncThunk(
  "admin/getallblogcat",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.get(`admin/getallblogcat`);
      console.log("sasasasasa",response);     
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const AllQuestions= createAsyncThunk(
  "admin/getallquestions",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.get(`admin/getallquestions`);
      console.log("sasasasasa",response);     
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllCities = createAsyncThunk(
  "admin/getallcities",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.get(`admin/getallcities`);
      console.log("sasasasasa",response);     
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllCountries = createAsyncThunk(
  "admin/getallcountries",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.get(`admin/getallcountries`);
      console.log("sasasasasa",response);     
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllAreas = createAsyncThunk(
  "admin/getallareas",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.get(`admin/getallareas`);
      console.log("sasasasasa",response);     
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllAttributes = createAsyncThunk(
  "admin/getallattributes",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.get(`admin/getallattributes`);
      console.log("sasasasasa",response);     
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllContactQueries = createAsyncThunk(
  "admin/getallcontactqueries",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.get(`admin/getallcontactqueries`);
      console.log("sasasasasa",response);     
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




export const deleteStore = createAsyncThunk(
  "admin/deletestore",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/deletestore/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const addCategory = createAsyncThunk(
  "admin/addcategory",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaasssss :>> ", data);
      const response = await axios.post(`admin/addcategory`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addBlogCat = createAsyncThunk(
  "admin/addblogcat",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaasssss :>> ", data);
      const response = await axios.post(`admin/addblogcat`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editCategory = createAsyncThunk(
  "admin/editcategory",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaa :>> ", data);
      const response = await axios.post(`admin/editcategory`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const storefrontadminapproval = createAsyncThunk(
  "admin/storefrontadminapproval",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/storefrontadminapproval`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const editBlogCat = createAsyncThunk(
  "admin/editblogcat",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaa :>> ", data);
      const response = await axios.post(`admin/editblogcat`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "admin/deletecategory",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/deletecategory/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteBlogCat = createAsyncThunk(
  "admin/deleteblogcat",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/deleteblogcat/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addEthnic = createAsyncThunk(
  "admin/addethnic",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaasssss :>> ", data);
      const response = await axios.post(`admin/addethnic`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editEthnic = createAsyncThunk(
  "admin/editethnic",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaa :>> ", data);
      const response = await axios.post(`admin/editethnic`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteEthnic = createAsyncThunk(
  "admin/deleteethnic",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/deleteethnic/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const addTask = createAsyncThunk(
  "admin/addtask",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaasssss :>> ", data);
      const response = await axios.post(`admin/addtask`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editTask = createAsyncThunk(
  "admin/edittask",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaa :============>> ", data.id,data);
      const response = await axios.patch(`admin/edittask/${data.id}`,data);
      console.log("response.data =========================:>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteTask = createAsyncThunk(
  "admin/deletetask",
  async (id, { rejectWithValue }) => {
    try {
      console.log("IDS ARE =>",id)
      const response = await axios.post(`admin/deletetask/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getTask = createAsyncThunk(
  "admin/gettask",
  async (data, { rejectWithValue }) => {
    try {
      // console.log("ddddddddddddddata :>> ", data);
      const response = await axios.get(`admin/gettasks`);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addCountry = createAsyncThunk(
  "admin/addcountry",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaasssss :>> ", data);
      const response = await axios.post(`admin/addcountry`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editCountry = createAsyncThunk(
  "admin/editcountry",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaa :>> ", data);
      const response = await axios.post(`admin/editcountry`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteCountry = createAsyncThunk(
  "admin/deletecountry",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/deletecountry/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const addReligion = createAsyncThunk(
  "admin/addreligion",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaasssss :>> ", data);
      const response = await axios.post(`admin/addreligion`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editReligion = createAsyncThunk(
  "admin/editreligion",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaa :>> ", data);
      const response = await axios.post(`admin/editreligion`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteReligion = createAsyncThunk(
  "admin/deletereligion",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/deletereligion/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addTheme = createAsyncThunk(
  "admin/addtheme",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaasssss :>> ", data);
      const response = await axios.post(`admin/addtheme`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editTheme = createAsyncThunk(
  "admin/edittheme",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaa :>> ", data);
      const response = await axios.post(`admin/edittheme`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteTheme = createAsyncThunk(
  "admin/deletetheme",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/deletetheme/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addArea = createAsyncThunk(
  "admin/addarea",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaasssss :>> ", data);
      const response = await axios.post(`admin/addarea`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editArea= createAsyncThunk(
  "admin/editarea",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaa :>> ", data);
      const response = await axios.post(`admin/editarea`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteArea = createAsyncThunk(
  "admin/deletearea",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/deletearea/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addAdmin = createAsyncThunk(
  "admin/addadmin",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaasssss :>> ", data);
      const response = await axios.post(`admin/addadmin`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editAdmin= createAsyncThunk(
  "admin/editadmin",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaa :>> ", data);
      const response = await axios.post(`admin/editadmin`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteAdmin = createAsyncThunk(
  "admin/deleteadmin",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/deleteadmin/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addCity = createAsyncThunk(
  "admin/addcity",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaasssss :>> ", data);
      const response = await axios.post(`admin/addcity`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editCity = createAsyncThunk(
  "admin/editcity",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaa :>> ", data);
      const response = await axios.post(`admin/editcity`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteCity = createAsyncThunk(
  "admin/deletecity",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/deletecity/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addAttribute = createAsyncThunk(
  "admin/addattribute",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaasssss :>> ", data);
      const response = await axios.post(`admin/addattribute`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editAttribute = createAsyncThunk(
  "admin/editattribute",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaa :>> ", data);
      const response = await axios.post(`admin/editattribute`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteAttribute = createAsyncThunk(
  "admin/deleteattribute",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/deleteattribute/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addSubService = createAsyncThunk(
  "admin/addsubcategory",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaasssss :>> ", data);
      const response = await axios.post(`admin/addsubcategory`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editSubService = createAsyncThunk(
  "admin/editsubcategory",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaa :>> ", data);
      const response = await axios.post(`admin/editsubcategory`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteSubService = createAsyncThunk(
  "admin/deletesubcategory",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/deletesubcategory/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addQuestion = createAsyncThunk(
  "admin/addquestion",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/addquestion`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const editQuestion = createAsyncThunk(
  "admin/editquestion",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/editquestion`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getAllBlogs = createAsyncThunk(
  "admin/getallblogs",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.get(`admin/getallblogs`); 
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBlogDetails = createAsyncThunk(
  "admin/getblogdetails",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/getblogdetails`,bodyData); 
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllThemes = createAsyncThunk(
  "admin/getallthemes",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.get(`admin/getallthemes`); 
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllReligions = createAsyncThunk(
  "admin/getallreligions",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.get(`admin/getallreligions`); 
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllAdmins = createAsyncThunk(
  "admin/getAlladmins",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.get(`admin/getAlladmins`); 
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




export const addBlog = createAsyncThunk(
  "admin/addblog",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaasssss :>> ", data);
      const response = await axios.post(`admin/addblog`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editBlog = createAsyncThunk(
  "admin/editblog",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaa :>> ", data);
      const response = await axios.post(`admin/editblog`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteBlog = createAsyncThunk(
  "admin/deleteblog",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/deleteblog/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteQuestion = createAsyncThunk(
  "admin/deletequestion",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/deletequestion/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSubAttribute = createAsyncThunk(
  "user/getsubattributes",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log("bodyData",bodyData);
      const response = await axios.post(`user/getsubattributes`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getVendorList = createAsyncThunk(
  "vendordashboard/vendorlist",
  async (bodyData, { rejectWithValue }) => {
    console.log("rsasa ");

    try {

      const response = await axios.get(`vendordashboard/vendorlist`,{params:{id: bodyData}});
      console.log("response :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getServiceTypes = createAsyncThunk(
  "user/getsubcategories",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log("bodyData",bodyData);
      const response = await axios.post(`user/getsubcategories`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAttribute = createAsyncThunk(
  "user/getattributes",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log("bodyData",bodyData);
      const response = await axios.post(`user/getattributes`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




export const deleteEvent = createAsyncThunk(
  "admin/deleteevent",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/deleteevent/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCities = createAsyncThunk(
  "user/getcities",
  async (bodyData, { rejectWithValue }) => {
    try {
     
      const response = await axios.get('user/getcities');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editstorefrontadmin = createAsyncThunk(
  "admin/editstorefront",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/editstorefront`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const FeaturedVendor = createAsyncThunk(
  "admin/featuredvendor",
  async (data, { rejectWithValue }) => {
    try {
      console.log("sasaassasasasa")
      const response = await axios.post(`admin/featuredvendor`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStore = createAsyncThunk(
  "vendordashboard/getstore",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaaaaaaasssss :>> ", data);
      const response = await axios.post(`vendordashboard/getstore`, data);
      console.log("response.dataxyz :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getReportStores = createAsyncThunk(
  "admin/getreportstores",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaaaaaaasssss :>> ", data);
      const response = await axios.get(`admin/getreportstores`, data);
      console.log("response.dataxyz :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getClaimStores = createAsyncThunk(
  "admin/getclaimstores",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaaaaaaasssss :>> ", data);
      const response = await axios.get(`admin/getclaimstores`, data);
      console.log("response.dataxyz :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




export const getfaq = createAsyncThunk(
  "vendordashboard/getfaq",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`vendordashboard/getfaq`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getServices = createAsyncThunk(
  "user/getservices",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.get(`user/getservices`, { ques: bodyData });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const getvendorquestionsadmin = createAsyncThunk(
  "admin/getvendorquestionsadmin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/getvendorquestionsadmin`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getEthnics = createAsyncThunk(
  "user/getethnics",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.get(`user/getethnics`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const deleteAccount = createAsyncThunk(
  "admin/deleteaccount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/deleteaccount`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteContactQueries = createAsyncThunk(
  "admin/deletecontactqueries",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/deletecontactqueries`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




export const getmydealsadmin = createAsyncThunk(
  "admin/getmydeals",
  async (data, { rejectWithValue }) => {
    try {
      console.log("datasassaas",data)      
      const response = await axios.post(`admin/getmydeals`,data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const updateTerms = createAsyncThunk(
  "terms_and_conditions",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`tcandpp/content/getTcPp`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updatePrivacy = createAsyncThunk(
  "privacy_policy",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`tcandpp/content/getTcPp`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const adminLogout = createAsyncThunk(
  "admin/signout",
  async (bodyData = null, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/signout`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const terms = createAsyncThunk(
  "tcandpp/content/getTcPp",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`tcandpp/content/TcPp`);
      console.log(response.data);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const privacy = createAsyncThunk(
  "tcandpp/content/getTcPp1",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`tcandpp/content/TcPp`);
      console.log(response.data);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    token: (state) => {
      var user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        state.user = user;
      }
    },
   
  },
  extraReducers,
});
export const getUserStatus = (state) => state?.users?.status;
export const getUserError = (state) => state?.users?.error;
export const getUsertoken = (state) => state?.users?.user?.token;
export const getProfile = (state) => state?.users?.user;
export const getTerms = (state) => state?.users?.terms;
export const getPrivacy = (state) => state?.users?.privacy;
export const getDashboard = (state) => state?.users?.dashboard;





export const { token } = userSlice.actions;

export default userSlice.reducer;
